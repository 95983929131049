const zielonaGoraBounds = {
  _northEast: {
    lat: 52.138124073141235,
    lng: 15.755081176757814,
  },
  _southWest: {
    lat: 51.73255690407605,
    lng: 15.255889892578127,
  },
};

const lubuskieBounds = {
  _northEast: {
    lat: 53.049939832331184,
    lng: 16.657773256301883,
  },
  _southWest: {
    lat: 51.4390889098188,
    lng: 14.411069154739382,
  },
};

export { zielonaGoraBounds, lubuskieBounds };
