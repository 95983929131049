// @flow
import L from 'leaflet';
import { MapControl } from 'react-leaflet';
import 'leaflet.locatecontrol';
import breakpoints from '../../lib/constants/breakpoints';

type PropsType = {
  position: string,
};

// Converts leaflet.locatecontrol to a React Component
export default class LocateControl extends MapControl {
  createLeafletElement(props: PropsType): any {
    const { map } = this.context;
    const { position } = props;

    const options = {
      position,
      strings: {
        title: 'Geolokalizacja',
      },
      showPopup: false,
      flyTo: true,
      icon: 'leaflet-control-locate-marker',
      iconLoading: 'leaflet-control-locate-marker loading',
      keepCurrentZoomLevel: true,
      locateOptions: {
        enableHighAccuracy: true,
      },
    };

    const lc = L.control.locate(options).addTo(map);

    if (window.innerWidth <= +breakpoints.mobile.slice(0, 3)) {
      setTimeout(() => {
        lc.start();
      }, 1000);
    }

    return lc;
  }
}
