// @flow
import * as React from 'react';
import { injectIntl } from 'react-intl';
import type { DictionaryEntryType } from '../../lib/types';

type PropsType = {
  intl: any,
  value: DictionaryEntryType,
};

const TranslatedValue = (props: PropsType): React.Node => {
  const {
    value,
    intl: { locale },
  } = props;

  return <React.Fragment>{value[`value_${locale}`]}</React.Fragment>;
};

export default (injectIntl(TranslatedValue): any);
