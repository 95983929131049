import gql from 'graphql-tag';

export default gql`
  mutation submitSiteToReview($id: ID!) {
    submitSiteToReview(id: $id) {
      id
      status
      reject_reason
    }
  }
`;
