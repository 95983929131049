// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'antd';

import STATUS from '../../lib/constants/siteStatus';

type PropsType = {
  status: String,
};

const StatusFormatter = (props: PropsType): React.Node => {
  const { status } = props;

  let translation;
  switch (status) {
    case STATUS.draft:
      translation = (
        <Tag color="magenta">
          <FormattedMessage id="general.status.draft" defaultMessage="Szkic" />
        </Tag>
      );
      break;
    case STATUS.ready:
      translation = (
        <Tag color="orange">
          <FormattedMessage id="general.status.ready" defaultMessage="Do akceptacji" />
        </Tag>
      );
      break;
    case STATUS.published:
      translation = (
        <Tag color="blue">
          <FormattedMessage id="general.status.published" defaultMessage="Opublikowany" />
        </Tag>
      );
      break;
    case STATUS.rejected:
      translation = (
        <Tag color="red">
          <FormattedMessage id="general.status.rejected" defaultMessage="Odrzucony" />
        </Tag>
      );
      break;
    default:
      translation = <span>{status}</span>;
      break;
  }

  return translation;
};

export default StatusFormatter;
