// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import difference from 'lodash/difference';

import SitesMap from './SitesMap';
import { makeGeojsonFilterFromBounds, areaInArea, makeBoundsBigger } from './SitesMapUtils';

import ALL_SITES_FOR_MAP_QUERY from '../../../lib/queries/allSitesForMap';
import { handleNestedFilter } from '../SitesList/SitesFilters/SitesFiltersUtils';

import type { SiteType, SiteFilterUrlType, LeafletBoundsType } from '../../../lib/types';

export type UpdateDataType = {
  // eslint-disable-next-line
  bounds: LeafletBoundsType,
  // eslint-disable-next-line
  zoom: number,
};

export type UpdateDataReturnType = Promise<void>;

type PropsType = {
  client: any,
  filter: SiteFilterUrlType,
};

type StateType = {
  currentArea: ?LeafletBoundsType,
  sites: ?(SiteType[]),
};

interface SitesMapContainerInterface {
  updateDataMethod: UpdateDataType => UpdateDataReturnType,
}

class SitesMapContainer extends React.PureComponent<PropsType, StateType> implements SitesMapContainerInterface {
  state = {
    currentArea: null,
    sites: null,
  };

  async componentDidMount(): Promise<void> | null {
    const { filter, client } = this.props;

    const filterIsActive = !!filter && difference(Object.keys(filter), ['status']).length > 0;

    if (!filterIsActive) return;

    const response = await client.query({
      query: ALL_SITES_FOR_MAP_QUERY,
      variables: {
        filter: handleNestedFilter(filter),
      },
      fetchPolicy: 'no-cache',
    });

    if (response.errors) return;

    this.setState({
      sites: response.data && response.data.allSites.data,
      currentArea: null,
    });
  }

  updateDataMethod = async ({ bounds, zoom }: UpdateDataType) => {
    const { client, filter } = this.props;
    const { currentArea } = this.state;

    // Do not use or update data when Filter is active
    const filterIsActive = !!filter && difference(Object.keys(filter), ['status']).length > 0;
    if (filterIsActive) return;

    // Do not fetch data if zoom is to small
    if (zoom < +process.env.REACT_APP_MIN_ZOOM) {
      this.setState({
        sites: null,
        currentArea: null,
      });

      return;
    }

    // Do not update data if current displaying area is already fetched
    if (currentArea && areaInArea(bounds, currentArea)) return;

    // Base on current displaying area prepare area that is 3 times bigger
    const biggerBounds = makeBoundsBigger(bounds, 3);

    // Prepare area filter for backend
    const geojsonFilter = makeGeojsonFilterFromBounds(biggerBounds);

    // Fetch new data
    const response = await client.query({
      query: ALL_SITES_FOR_MAP_QUERY,
      variables: {
        filter: {
          ...filter,
          geojson_geom_intersects: geojsonFilter,
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (response.errors) return;

    this.setState({
      sites: response.data.allSites.data,
      currentArea: biggerBounds,
    });
  };

  render(): React.Node {
    const { sites } = this.state;
    const { filter } = this.props;

    const showFilterWarning = !!filter && difference(Object.keys(filter), ['status']).length > 0;

    if (!sites && showFilterWarning) return 'Loading...';

    return (
      <SitesMap
        sites={sites}
        filterIsActive={showFilterWarning}
        showFilterWarning={showFilterWarning}
        updateData={this.updateDataMethod}
      />
    );
  }
}

export default (withApollo(SitesMapContainer): any);
