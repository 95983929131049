// @flow
import * as React from 'react';
import {
  Row, Col, Divider, Input, Button as ButtonAnt, Popconfirm,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';
import {
  FormattedMessage, injectIntl, defineMessages,
} from 'react-intl';
// Components
import DictionarySelect from '../../../../../DictionarySelect';
// Own validation
import { required } from '../../../../../../lib/formValidation';
// Own Types
import type { MuseumCollectionType } from '../../../../../../lib/types';

const FormItem = Form.Item;

const translations = defineMessages({
  removeQuestion: {
    defaultMessage: 'Czy chcesz usunąć zbiór?',
    id: 'MuseumCollectionsContainer.removeQuestion',
  },
  yes: {
    defaultMessage: 'Tak',
    id: 'general.yes',
  },
  no: {
    defaultMessage: 'Nie',
    id: 'general.no',
  },
});

type PropsTypes = {
  getFieldDecorator: Function,
  handleRemoveMuseumCollection: (museumCollection: MuseumCollectionType) => Promise<void>,
  intl: any,
  museumCollection: MuseumCollectionType,
  name: string,
  setFieldsValue: Function,
  validateFields: Function,
};

const MuseumCollectionsField = (props: PropsTypes): React.Node => {
  const {
    museumCollection,
    getFieldDecorator,
    setFieldsValue,
    validateFields,
    intl,
    name,
    handleRemoveMuseumCollection,
  } = props;

  return (
    <Row gutter={16} type="flex" justify="start" align="middle">
      {museumCollection.id && (
        <HiddenFormItem label="id">
          {getFieldDecorator(`${name}[id]`, {
            initialValue: museumCollection.id,
          })(<Input />)}
        </HiddenFormItem>
      )}
      <HiddenFormItem label="localId">
        {getFieldDecorator(`${name}[localId]`, {
          initialValue: museumCollection.localId,
        })(<Input />)}
      </HiddenFormItem>
      <Col lg={7}>
        <DictionarySelect
          dictionaryId={
            museumCollection.dictionary_id
            || (museumCollection.storage_location && museumCollection.storage_location.dictionary_id)
          }
          label={
            <FormattedMessage id="MuseumCollectionsContainer.storageLocation" defaultMessage="Miejsce przechowywania" />
          }
          name={`${name}[storage_location][id]`}
          getFieldDecorator={getFieldDecorator}
          validateFields={validateFields}
          setFieldsValue={setFieldsValue}
          initialFieldValue={museumCollection ? museumCollection.storage_location : null}
          validationRules={[required]}
        />
      </Col>
      <Col lg={7}>
        <FormItem
          label={<FormattedMessage id="MuseumCollectionsContainer.inventoryNumber" defaultMessage="Nr Inwentarza" />}
        >
          {getFieldDecorator(`${name}[inventory_number]`, {
            initialValue: museumCollection.inventory_number,
          })(<Input />)}
        </FormItem>
      </Col>
      <FormattedMessage id="MuseumCollectionsField.remove" defaultMessage="Usuń zbiór">
        {(buttonText: string): React.Node => (
          <Popconfirm
            title={intl.formatMessage(translations.removeQuestion)}
            onConfirm={(): Promise<void> => handleRemoveMuseumCollection(museumCollection)}
            okText={intl.formatMessage(translations.yes)}
            cancelText={intl.formatMessage(translations.no)}
          >
            <ButtonAnt>{buttonText}</ButtonAnt>
          </Popconfirm>
        )}
      </FormattedMessage>
      <Divider />
    </Row>
  );
};

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

export default (injectIntl(MuseumCollectionsField): any);
