// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import {
  FormattedMessage, defineMessages, injectIntl,
} from 'react-intl';
import { Icon } from '@ant-design/compatible';
// Leaflet
import {
  Map, TileLayer, LayersControl, ScaleControl,
} from 'react-leaflet';
import { Link } from 'react-router-dom';

// Leaflet Marker Icon bug
import 'leaflet.markercluster';
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

// Styles
import 'leaflet/dist/leaflet.css';
import { navigationHeight, smallerFontSize } from '../../../lib/style/themes';

import getScaleFactor from '../../../lib/utils/getScaleFactor';
import prepareWMSLayers from '../../../lib/utils/prepareWMSLayers';
import WMSLayers from '../../../lib/constants/WMSLayers';
import { accessibility } from '../../../lib/constants/themeModes';
import {
  Control, Deflate, Geocoder, Coordinates, LocateControl,
} from '../../../components/LeafletComponents';
import { mergeGeometriesToFeatureGroup } from './SitesMapUtils';
import BreadCrumb from '../../../components/BreadCrumb';
import getBoundsFromGeoJson from '../../../lib/utils/getBoundsFromGeoJson';
import Breakpoints from '../../../lib/constants/breakpoints';
import withRouter from '../../../lib/utils/withRouter';

// Types
import type { ViewportType, SiteType } from '../../../lib/types';

import type { UpdateDataType, UpdateDataReturnType } from './SitesMapContainer';

// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

type PropsType = {
  filterIsActive: boolean,
  intl: any,
  location: Location,
  navigate: any,
  showFilterWarning: boolean,
  sites: ?(SiteType[]),
  updateData: UpdateDataType => UpdateDataReturnType,
};

type StateType = {
  lat: number,
  lng: number,
  scale: number,
  zoom: number,
};

class SitesMap extends React.PureComponent<PropsType, StateType> {
  mapRef: any;

  constructor() {
    super();
    this.mapRef = React.createRef();
  }

  state = {
    lat: 52.2,
    lng: 15.505757,
    scale: getScaleFactor(+process.env.REACT_APP_MIN_ZOOM - 2),
    zoom: +process.env.REACT_APP_MIN_ZOOM - 2,
  };

  componentDidMount() {
    window.addEventListener('mapPopupLinkClick', (e: any) => this.handlePopupClick(e));
  }

  componentDidUpdate(prevProps: PropsType) {
    const { filterIsActive } = this.props;

    // When deactivate filtering on map view, need to fetch sites
    if (prevProps.filterIsActive && !filterIsActive) this.updateData();
  }

  componentWillUnmount() {
    window.removeEventListener('mapPopupLinkClick', (e: any) => this.handlePopupClick(e));
  }

  onViewportChanged = (viewport: ViewportType) => {
    const { zoom } = viewport;
    const scale = getScaleFactor(zoom);
    this.setState({ zoom, scale });
    this.updateData();
  };

  updateData = () => {
    const { zoom } = this.state;
    const { updateData, filterIsActive } = this.props;

    if (filterIsActive) return;

    const bounds = this.mapRef.current.leafletElement.getBounds();
    updateData({ bounds, zoom });
  };

  handlePopupClick(e: any) {
    const { navigate } = this.props;
    navigate(`/sites/${e.detail}`);
  }

  static translations = defineMessages({
    detailsButton: {
      defaultMessage: 'Zobacz szczegóły',
      id: 'SitesMap.showDetails',
    },
  });

  render(): React.Node {
    const {
      zoom, lat, lng, scale,
    } = this.state;
    const {
      sites, filterIsActive, showFilterWarning, intl, location,
    } = this.props;
    const center = [lat, lng];
    const { BaseLayer } = LayersControl;
    const layers = prepareWMSLayers(WMSLayers);

    // When zoom is too small, and filter is NOT active we need to show warning
    const showZoomWarning = zoom < +process.env.REACT_APP_MIN_ZOOM && !filterIsActive;

    // To show all sites on the map, need to merge all geojsons from sites to one feature collection
    const mergedSitesGeoJson = mergeGeometriesToFeatureGroup(sites);

    // When filtering we need to calculate bounds base on selected sites
    const bounds = filterIsActive ? getBoundsFromGeoJson(mergedSitesGeoJson) : undefined;
    const showDetailsButtonText = intl.formatMessage(SitesMap.translations.detailsButton);

    const showZeroFoundWarning = sites && sites.length === 0 && filterIsActive;

    const onEachFeature = (feature: { properties: SiteType }, featureLayer: any) => {
      featureLayer.bindPopup(`
        <div class="popup">
          <div>
            <h3>${String(feature.properties.local_name)}</h3>
            <span>AZP: ${String((feature.properties.azp && feature.properties.azp.value_pl) || '-')}</span>
            <span> / ${String(feature.properties.ordinal_number_in_azp || '-')}</span>
          </div>
          <a
            class="popup-button"
            href="/sites/${(feature.properties.azp && feature.properties.azp.value_pl) || '-'}/${String(
  feature.properties.ordinal_number_in_azp,
)}"
            onclick="
              window.dispatchEvent(
                new CustomEvent('mapPopupLinkClick', {
                  'detail':
                    '${String(feature.properties.azp && feature.properties.azp.value_pl) || '-'}/${String(
  feature.properties.ordinal_number_in_azp,
)}'
                }));
              return false;
            "
          >
            ${showDetailsButtonText}
          </a>
        </div>
      `);
    };

    return (
      <React.Fragment>
        <BreadCrumb />
        <MapWrapper>
          <Map
            ref={this.mapRef}
            style={{ height: '100%' }}
            center={center}
            zoom={zoom}
            bounds={bounds}
            onViewportChanged={this.onViewportChanged}
          >
            <Geocoder position="topleft" />
            <LocateControl position="topleft" />

            <LayersControl position="topleft">
              <BaseLayer checked name="OpenStreetMap">
                <TileLayer
                  attribution="&copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  url={process.env.REACT_APP_TILE_URL}
                  maxZoom={20}
                />
              </BaseLayer>
              {layers}
            </LayersControl>

            <Control position="topright">
              {showZeroFoundWarning && (
                <NotFoundMessage>
                  <Icon type="exclamation-circle" />
                  <FormattedMessage
                    id="SitesMap.zerowFoundWarning"
                    defaultMessage="Nie znaleziono stanowisk spełniających warunki filtrowania"
                  />
                </NotFoundMessage>
              )}
              {showZoomWarning && (
                <ZoomWarningMessage>
                  <Icon type="zoom-in" />
                  <FormattedMessage
                    id="SitesMap.zoomWarning"
                    defaultMessage="Przybliż mapę aby zobaczyć zaznaczone stanowiska archeologiczne"
                  />
                </ZoomWarningMessage>
              )}
              {showFilterWarning && (
                <Link to={`/sites/list${location.search}`}>
                  <FilterWarnignMessage>
                    <Icon type="filter" theme="twoTone" />
                    <FormattedMessage
                      id="SitesMap.filterWarning"
                      defaultMessage="Aktywowano filtrowanie stanowisk, kliknij aby zmienić"
                    />
                  </FilterWarnignMessage>
                </Link>
              )}
            </Control>

            <Coordinates position="bottomleft" />
            <ScaleControl position="bottomright" />
            <Control position="bottomright">
              <ScaleFactor>
                1:
                {scale}
              </ScaleFactor>
            </Control>
            <Deflate data={mergedSitesGeoJson} minSize={20} markerCluster onEachFeature={onEachFeature} />
          </Map>
        </MapWrapper>
      </React.Fragment>
    );
  }
}

const ZoomWarningMessage = styled.div`
  user-select: none;
  padding: 15px;
  background: white;
  color: black;
  border: 2px solid #c2bfbb;
  font-size: ${smallerFontSize};
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  span {
    max-width: 250px;
  }
  i {
    font-size: 26px;
    margin-right: 20px;
  }

  @media (max-width: ${Breakpoints.mobile}) {
    font-size: 10px;
    padding: 5px;
    i {
      display: none;
    }
  }
`;

const FilterWarnignMessage = styled(ZoomWarningMessage)`
  color: #1395ff;
`;

const NotFoundMessage = styled(ZoomWarningMessage)`
  color: red;
`;

const MapWrapper = styled.div`
  height: calc(100vh - ${navigationHeight});

  .leaflet-control-scale-line {
    font-size: ${smallerFontSize};
    border-left: 2px solid #888;
    border-right: 2px solid #888;
    background: #fff;
  }

  .leaflet-control-container {
    .leaflet-left,
    .leaflet-right {
      max-width: 50%;
    }
  }

  @media (max-width: ${Breakpoints.mobile}), (max-height: 500px) {
    .leaflet-control-coordinates {
      display: none;
    }
  }

  .leaflet-control-coordinates {
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-clip: padding-box;
    background-color: #fff;

    .inputX,
    .inputY {
      max-width: 90px;
      margin: 0 5px;
      padding: 4px 11px;
      height: 32px;
      font-size: ${smallerFontSize};
      line-height: 1.5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      transition: all 0.3s;

      &:focus {
        border-color: #40a9ff;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }

    .uiElement.label {
      display: none;
    }
  }

  .marker-cluster-small div {
    background: #2d84c8;
  }

  .marker-cluster {
    background: rgba(45, 132, 200, 0.57);
    span {
      color: white;
      font-weight: bold;
      font-size: ${smallerFontSize};
    }
  }

  .popup {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .popup-button {
    color: white;
    background: #0093ff;
    align-self: flex-end;
    border-radius: 4px;
    padding: 5px 10px;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      display: none;
    `};
`;

const ScaleFactor = styled.div`
  padding: 3px 5px;
  color: #333;
  font-size: ${smallerFontSize};
  background: #fff;
  border: 2px solid #c2bfbb;
  border-radius: 5px;
`;

export default (withRouter(injectIntl(SitesMap)): any);
