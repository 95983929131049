// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import type { QueryRenderProps } from 'react-apollo';
import {
  Table, Button, Spin, Row,
} from 'antd';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled, { css } from 'styled-components';

import { accessibility } from '../../../../lib/constants/themeModes';
import { handleNestedFilter } from '../SitesFilters/SitesFiltersUtils';
import { darkBlueColor, biggerFontSize } from '../../../../lib/style/themes';
import { makeValueTrueFromArrayOfString } from './SiteTableUtils';
// Query
import ALL_SITES_QUERY from '../../../../lib/queries/allSites';
// Own types
import type { SiteType, SiteFilterUrlType, StringSiteType } from '../../../../lib/types';
import type { SitesTableHeaderType, AllSitesQueryVariablesType } from './SitesTableContainer';
import CSVButton from './CSVButton';
import NoDataBecauseError from '../../../../components/NoDataBecauseError';

type PropsType = {
  allSitesQueryVariables: AllSitesQueryVariablesType,
  className: string,
  columnSortOrder: ?string,
  filter: SiteFilterUrlType,
  handleSitesTableSort: Function,
  limit: number,
  orderedColumns: Array<string>,
  pageNumber: number,
  prepareSitesTableBody: (data: SiteType[]) => StringSiteType[],
  selectedColumns: Array<string>,
  sitesTableHeader: SitesTableHeaderType[],
  updatePageNumber: () => void,
};

const SitesTable = (props: PropsType): React.Node => {
  const {
    sitesTableHeader,
    allSitesQueryVariables,
    columnSortOrder,
    limit,
    pageNumber,
    filter,
    selectedColumns,
    prepareSitesTableBody,
    className,
    handleSitesTableSort,
    updatePageNumber,
    orderedColumns,
  } = props;

  // selectedColumns is in format ['azp', 'cos'] for query we need it as { azp: true, zos: true }
  const queryVariables = makeValueTrueFromArrayOfString(selectedColumns);

  return (
    <Query
      query={ALL_SITES_QUERY}
      variables={{
        ...queryVariables,
        order: columnSortOrder,
        paginator: { limit, page: 1 },
        filter: handleNestedFilter(filter),
      }}
      fetchPolicy="cache-and-network"
    >
      {({
        loading,
        error,
        data,
        fetchMore,
      }: QueryRenderProps<{ allSites: { __typename: string, data: SiteType[], total: number } }>): React.Node => {
        if (error) return <NoDataBecauseError error={error} />;

        if (data && data.allSites && data.allSites.data) {
          // eslint-disable-next-line
          const total = data.allSites.total;

          const sitesTableBody = prepareSitesTableBody(data.allSites.data);

          return (
            <div role="textbox" tabIndex="0" aria-label="Tabela przedstawiająca wszystkie stanowiska archeologiczne">
              <Row type="flex" justify="space-between">
                <SitesStatistics tabIndex="0">
                  <FormattedMessage id="SitesTable.sitesFound" defaultMessage="Liczba znalezionych stanowisk: " />
                  <SitesCounter>
                    <FormattedNumber value={total} />
                  </SitesCounter>
                </SitesStatistics>
                <div>
                  <CSVButton
                    columnSortOrder={columnSortOrder}
                    orderedColumns={orderedColumns}
                    selectedColumns={selectedColumns}
                    filter={filter}
                  />
                </div>
              </Row>
              <Table
                columns={sitesTableHeader}
                loading={loading}
                dataSource={[...sitesTableBody]}
                pagination={false}
                bordered
                scroll={{ x: 'auto' }}
                className={className}
                role="table"
                onChange={handleSitesTableSort}
              />
              <FormattedMessage id="general.loadMore" defaultMessage="Wczytaj kolejne">
                {(buttonText: string): React.Node => (
                  <StyledButton
                    type="primary"
                    disabled={[...sitesTableBody].length >= total}
                    onClick={() => {
                      fetchMore({
                        variables: {
                          ...allSitesQueryVariables,
                          order: columnSortOrder,
                          paginator: { limit, page: pageNumber + 1 },
                        },
                        // eslint-disable-next-line
                        updateQuery: (prev, { fetchMoreResult }): any => {
                          if (!fetchMoreResult) return prev;

                          return {
                            ...prev,
                            allSites: {
                              data: [...prev.allSites.data, ...fetchMoreResult.allSites.data],
                              __typename: fetchMoreResult.allSites.__typename,
                            },
                          };
                        },
                      });
                      updatePageNumber();
                    }}
                  >
                    {buttonText}
                  </StyledButton>
                )}
              </FormattedMessage>
            </div>
          );
        }

        return <CenteredSpin />;
      }}
    </Query>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  margin: 20px 0;
`;

const SitesStatistics = styled.div`
  display: flex;
  align-items: center;
  color: gray;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
      &:focus {
        border: 2px solid red !important;
      }
    `};
`;

const SitesCounter = styled.div`
  font-size: 22px;
  font-weight: bold;
  min-width: 50px;
  text-align: center;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  margin: 0 0 3px 3px;
  padding: 0 5px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background: ${darkBlueColor};
    `};
`;

const CenteredSpin = styled(Spin)`
  display: block !important;
  margin: 5px auto !important;
`;

export default SitesTable;
