// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Table, Row, Col, Divider, Button,
} from 'antd';
import styled, { css } from 'styled-components';
import type { MuseumCollectionType, ResearchType } from '../../../../../../lib/types';

import { accessibility } from '../../../../../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize, generalFontSize } from '../../../../../../lib/style/themes';
import { SCHONAICHANUM_ID } from '../../../../../../lib/constants/dictionariesId';
import parseMuseumCollections from '../../../../../../lib/utils/parseMuseumCollections';
import LinkToExternal from '../../../../../../components/LinkToExternal';

type PropsTypes = {
  handleCloseDrawer: () => Promise<void>,
  intl: any,
  researchData: ResearchType,
};

const ResearchDetail = (props: PropsTypes) => {
  const {
    researchData,
    handleCloseDrawer,
    intl: { locale },
  } = props;

  const columns = [
    {
      title: <FormattedMessage id="ResearchDetail.inventoryNumber" defaultMessage="Numer Inwentarza" />,
      key: 'inventory_number',
      dataIndex: 'inventory_number',
      sorter: (item: MuseumCollectionType, nextItem: MuseumCollectionType): number | boolean => {
        if (item.inventory_number) return item.inventory_number.localeCompare(nextItem.inventory_number);

        return false;
      },
    },
    {
      title: <FormattedMessage id="ResearchDetail.storage_location" defaultMessage="Miejsce Przechowywania" />,
      key: 'storage_location',
      dataIndex: `storage_location.value_${locale}`,
      sorter: (item: MuseumCollectionType, nextItem: MuseumCollectionType): number | boolean => {
        if (item.storage_location[`value_${locale}`]) {
          return item.storage_location[`value_${locale}`].localeCompare(nextItem.storage_location[`value_${locale}`]);
        }

        return false;
      },
    },
    {
      key: 'link',
      dataIndex: 'link',
      render: (_, record: { inventory_number: string, storage_location: { id: string } }): React.Node => {
        if (record.storage_location.id === SCHONAICHANUM_ID && record.inventory_number) {
          return (
            <LinkToExternal
              linkPattern=":type/:catalogId"
              serviceUrl={process.env.REACT_APP_ARCHIVE_ADRESS}
              linkParams={{
                type: record.inventory_number.split('/')[0],
                catalogId: record.inventory_number.split('/')[1],
              }}
            >
              <FormattedMessage id="ResearchDetail.showArtifact" defaultMessage="Zobacz zabytek" />
            </LinkToExternal>
          );
        }

        return null;
      },
    },
  ];

  const museumCollections = parseMuseumCollections(researchData.museum_collections);

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={8}>
          <StyledDivider orientation="left">
            <FormattedMessage id="ResearchDetail.researchSupervisor" defaultMessage="Kierownik" />
          </StyledDivider>
          <StyledCol span={24}>
            {researchData.research_supervisor ? (
              <span>{researchData.research_supervisor}</span>
            ) : (
              <FormattedMessage id="ResearchDetail.empty" defaultMessage="Brak danych" />
            )}
          </StyledCol>
        </Col>
        <Col span={8}>
          <StyledDivider orientation="left">
            <FormattedMessage id="ResearchDetail.year" defaultMessage="Rok" />
          </StyledDivider>
          <StyledCol span={24}>
            {researchData.year ? (
              <span>{researchData.year}</span>
            ) : (
              <FormattedMessage id="ResearchDetail.empty" defaultMessage="Brak danych" />
            )}
          </StyledCol>
        </Col>
        <Col span={8}>
          <StyledDivider orientation="left">
            <FormattedMessage id="ResearchDetail.institution" defaultMessage="Instytucja" />
          </StyledDivider>
          <StyledCol span={24}>
            {researchData.institution ? (
              <span>{researchData.institution}</span>
            ) : (
              <FormattedMessage id="ResearchDetail.empty" defaultMessage="Brak danych" />
            )}
          </StyledCol>
        </Col>
        <Col span={24}>
          <StyledDivider orientation="left">
            <FormattedMessage id="ResearchDetail.description" defaultMessage="Opis badania" />
          </StyledDivider>
          <StyledCol span={24}>
            {researchData.description ? (
              <span>{researchData.description}</span>
            ) : (
              <FormattedMessage id="ResearchDetail.empty" defaultMessage="Brak danych" />
            )}
          </StyledCol>
        </Col>
        <Col span={24}>
          <StyledDivider orientation="left">
            <FormattedMessage id="ResearchDetail.documentation" defaultMessage="Dokumantacja" />
          </StyledDivider>
          <StyledCol span={24}>
            {researchData.documentation ? (
              <span>{researchData.documentation}</span>
            ) : (
              <FormattedMessage id="ResearchDetail.empty" defaultMessage="Brak danych" />
            )}
          </StyledCol>
        </Col>
        <Col span={24}>
          <StyledDivider orientation="left">
            <FormattedMessage id="ResearchDetail.literature" defaultMessage="Literatura" />
          </StyledDivider>
          <StyledCol span={24}>
            {researchData.literature ? (
              <span>{researchData.literature}</span>
            ) : (
              <FormattedMessage id="ResearchDetail.empty" defaultMessage="Brak danych" />
            )}
          </StyledCol>
        </Col>
        <Col span={24}>
          <DetailTitle span={24}>
            <FormattedMessage id="ResearchDetail.museumCollections" defaultMessage="Zbiory" />
          </DetailTitle>
          <StyledTable columns={columns} dataSource={museumCollections} scroll={{ x: 'auto' }} />
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="start">
        <StyledButton onClick={handleCloseDrawer}>
          <FormattedMessage id="ResearchDetail.closeDrawer" defaultMessage="Zamknij" />
        </StyledButton>
      </Row>
    </React.Fragment>
  );
};

const StyledTable = styled(Table)`
  .ant-table td {
    white-space: nowrap;
  }

  td: focus, th: focus {
    outline: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-pagination-item-link,
      .ant-pagination-item {
        color: yellow !important;
        font-weight: 600;
        background-color: ${darkBlueColor} !important;
        font-size: ${generalFontSize};
      }
    `};
`;

const DetailTitle = styled(Col)`
  margin: 15px 15px 5px 0;
  font-size: ${generalFontSize};
  font-weight: 500;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;
    `};
`;

const StyledButton = styled(Button)`
  width: 100%;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

const StyledCol = styled(Col)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      font-size: ${biggerFontSize};
    `};
`;

const StyledDivider = styled(Divider)`
  .ant-divider-inner-text {
    padding: 0 8px !important;
  }

  &::before {
    width: 0 !important;
  }
`;

export default (injectIntl(ResearchDetail): any);
