// @flow
import store from 'store';
import client from '../../../../config/apollo';
import {
  columnsSettings,
  sitesListDefaultColumnsForAdministration,
  sitesListDefaultColumnsForMyWork,
  sitesListDefaultColumnsForModeration,
} from '../../../../lib/constants/sitesListFloatingColumns';
import SET_SITES_LIST_COLUMNS from '../../../../lib/mutations/setSitesListColumns';

function saveColumnsInLocalState(variables: {
  columnSortOrder?: string,
  orderedColumns?: Array<string>,
  selectedColumns?: Array<string>,
}) {
  client.mutate({
    mutation: SET_SITES_LIST_COLUMNS,
    variables,
  });
}

// input all columns as ['azp', 'status']
// output filtered columns if no admin ['azp']
function filterColumnsNotForAdmin(columns: string[]): string[] {
  const token: string = store.get('authToken');

  if (token) return columns;

  return columns.filter(
    (column: string): boolean => {
      // if we have settings for this column and this is column only for admin, remove it
      return !(column in columnsSettings) || !columnsSettings[column].onlyForAdmin;
    },
  );
}
// input ['azs', 'zupa']
// putput { azs: true, zupa: true }
function makeValueTrueFromArrayOfString(array: string[]): { [string]: boolean } {
  const valueTrueObject = {};

  array.forEach((element: string) => {
    valueTrueObject[element] = true;
  });

  return valueTrueObject;
}

// its return specjal columns selection base on url
function switchDefaultColumnsBaseOnUrl(selectedColumns: string[]): string[] {
  const path = window.location.pathname;

  switch (path) {
    case '/sites/list/administration':
      return sitesListDefaultColumnsForAdministration;
    case '/sites/list/my-work':
      return sitesListDefaultColumnsForMyWork;
    case '/sites/list/moderation':
      return sitesListDefaultColumnsForModeration;
    default:
      return selectedColumns;
  }
}

export {
  saveColumnsInLocalState,
  filterColumnsNotForAdmin,
  makeValueTrueFromArrayOfString,
  switchDefaultColumnsBaseOnUrl,
};
