// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { Input } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import formItemLayout from '../../../lib/constants/formItemLayout';
import type { ValidationRulesType } from '../../../lib/types';
// Query
import SITE_VALIDATION from '../../../lib/queries/siteValidation';

type PropsType = {
  client: any,
  getFieldDecorator: Function,
  getFieldValue: Function,
  initialFieldValue: string,
  label: string,
  messageIsNotUnique: string,
  name: string,
  relatedFieldInitialValue: {
    id: number,
    value_de?: string,
    value_en?: string,
    value_pl?: string,
  },
  relatedFieldName: string,
  resetFields: Function,
  setFieldsValue: Function,
  validationRules?: ValidationRulesType,
};

class InputUniqInScopeOfRelatedField extends React.PureComponent<PropsType, void> {
  static defaultProps = {
    validationRules: [],
  };

  componentDidMount() {
    const { setFieldsValue, initialFieldValue, name } = this.props;

    if (initialFieldValue) {
      setFieldsValue({
        [name]: initialFieldValue,
      });
    }
  }

  validateIsUnique = async (rule: Function, value: string, callback: Function): Promise<void> => {
    const {
      client,
      getFieldValue,
      initialFieldValue,
      messageIsNotUnique,
      name,
      relatedFieldInitialValue,
      relatedFieldName,
      resetFields,
      setFieldsValue,
    } = this.props;

    const relatedFielValue = getFieldValue(relatedFieldName);

    // If there is no value or related field value skip validation
    if (!value || !relatedFielValue) {
      // Set null value for site edition and reset value for adding a new one
      if (initialFieldValue || relatedFieldInitialValue) {
        setFieldsValue({
          [name]: null,
        });
      } else {
        resetFields([name]);
      }

      callback();

      return;
    }

    // When new value is initial value (the same in related field) there is no need to send request,
    if (value === initialFieldValue && relatedFieldInitialValue.id === relatedFielValue) {
      callback();

      return;
    }

    const siteInAZPareaQuery = {
      query: SITE_VALIDATION,
      variables: {
        [name]: value,
        [relatedFieldName]: relatedFielValue,
      },
      fetchPolicy: 'network-only',
    };

    const isUnique = await client.query(siteInAZPareaQuery);
    const isUniqueResponse = isUnique.data.siteValidation;

    if (!isUniqueResponse) {
      callback(messageIsNotUnique);
    }
  };

  render(): React.Node {
    const FormItem = Form.Item;
    const {
      label, name, getFieldDecorator, getFieldValue, relatedFieldName, validationRules,
    } = this.props;

    const isDisabled = !getFieldValue(relatedFieldName);
    const rules = [
      // nie wiem co tu sie dzieje niestety
      /* eslint-disable */
      // $FlowFixMe[incompatible-type]
      ...validationRules,
      {
        validator: this.validateIsUnique,
      },
    ];

    return (
      <FormItem {...formItemLayout} label={label}>
        {getFieldDecorator(name, {
          rules,
        })(<Input name={name} disabled={isDisabled} />)}
      </FormItem>
    );
  }
}

export default (withApollo(InputUniqInScopeOfRelatedField): any);
