// @flow
import * as React from 'react';
import styled from 'styled-components';
// Leaflet
import {
  Map, TileLayer, LayersControl, ScaleControl, GeoJSON,
} from 'react-leaflet';
import { FormattedMessage } from 'react-intl';

// Leaflet Marker Icon bug
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

import WMSLayers from '../../lib/constants/WMSLayers';
import getBoundsFromGeoJson from '../../lib/utils/getBoundsFromGeoJson/getBoundsFromGeoJson';
import prepareWMSLayers from '../../lib/utils/prepareWMSLayers';
import { smallerFontSize } from '../../lib/style/themes';


// Types
import type { GeoJsonType } from '../../lib/types';

// Components

// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

type PropsType = {
  geojsonData: GeoJsonType,
  height: string,
};

const SimpleMap: React$ComponentType<PropsType> = (props: PropsType) => {
  const { geojsonData, height } = props;
  const layers = prepareWMSLayers(WMSLayers);
  const { BaseLayer } = LayersControl;

  if (!geojsonData) return <FormattedMessage id="SimpleMap.noData" defaultMessage="Brak danych" />;

  const bounds = getBoundsFromGeoJson(geojsonData);

  return (
    <React.Fragment>
      <MapWrapper className="map-wrapper">
        <Map bounds={bounds} style={{ height: height || '100%' }}>
          <ScaleControl position="bottomright" />
          <LayersControl position="topright">
            <BaseLayer checked name="OpenStreetMap">
              <TileLayer
                attribution="&copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                url={process.env.REACT_APP_TILE_URL}
                maxZoom={20}
              />
            </BaseLayer>
            {layers}
            <GeoJSON data={geojsonData} />
          </LayersControl>
        </Map>
      </MapWrapper>
    </React.Fragment>
  );
};

const MapWrapper = styled.div`
  .leaflet-control-scale-line {
    font-size: ${smallerFontSize};
    border-left: 2px solid #888;
    border-right: 2px solid #888;
    background: #fff;
  }
`;

export default SimpleMap;
