// @flow
import * as React from 'react';
import { Row, Card } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import dictionariesId from '../../../../../lib/constants/dictionariesId';
import groupResearches from '../../../../../lib/utils/groupResearches';
// Queries
import ALL_DICTIONARY_ENTRIES_QUERY from '../../../../../lib/queries/allDictionaryEntries';
// Own types
import type { ResearchType, GroupedResearchType } from '../../../../../lib/types';
// Component
import SitesDetailsPrintDiscoveriesReasearchesDetail from './SitesDetailsPrintDiscoveriesReasearchesDetail';

type PropsTypes = {
  client: any,
  intl: any,
  researches: ResearchType[],
};

type StateTypes = {
  groupedResearches: GroupedResearchType[],
};

class SitesDetailsPrintDiscoveriesReasearches extends React.PureComponent<PropsTypes, StateTypes> {
  state = {
    groupedResearches: [],
  };

  async componentDidMount(): Promise<void> {
    const { client, researches } = this.props;
    const { reasearchDictionaryId } = dictionariesId;

    const researchesDictionary = await client.query({
      query: ALL_DICTIONARY_ENTRIES_QUERY,
      variables: { filter: { dictionaryId: reasearchDictionaryId } },
      fetchPolicy: 'cache-first',
    });

    const dictionariesData = researchesDictionary.data.allDictionaryEntries.data;

    const groupedResearches = groupResearches(researches, dictionariesData);

    this.setState({
      groupedResearches,
    });
  }

  render(): React.Node {
    const { researches } = this.props;
    const { groupedResearches } = this.state;

    if (researches.length === 0) {
      return (
        <Row type="flex" justify="center">
          <FormattedMessage id="SitesDetailsPrintDiscoveriesReasearches.noResearches" defaultMessage="Brak badań" />
        </Row>
      );
    }

    return groupedResearches.map(
      (groupedResearch: GroupedResearchType): React.Node => {
        const {
          intl: { locale },
        } = this.props;
        const { data: groupedResearchData } = groupedResearch;
        const numberOfResearches = groupedResearchData.length;

        if (numberOfResearches === 0) {
          return null;
        }

        return (
          <React.Fragment key={groupedResearch.id}>
            <StyledCard
              bordered={false}
              title={<StyledResearchType>{groupedResearch[`value_${locale}`]}</StyledResearchType>}
              tabIndex="0"
            >
              <SitesDetailsPrintDiscoveriesReasearchesDetail researches={groupedResearchData} />
            </StyledCard>
          </React.Fragment>
        );
      },
    );
  }
}

const StyledResearchType = styled.span`
  font-size: 14px !important;
  text-transform: capitalize;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;

  @media print {
    padding: 0 !important;
    page-break-inside: avoid;

    .ant-card-bordered {
      border: 0;
    }

    .ant-card-head,
    .ant-card-wider-padding,
    .ant-card-body {
      padding: 0 !important;
    }

    .ant-card-head {
      min-height: 0;
      margin: 10px 0;
      page-break-after: avoid;
      font-size: 14px !important;
    }

    .ant-card-body {
      font-size: 12px !important;
    }

    .ant-card-head-title {
      padding: 0;
    }

    .ant-card-body > .ant-card > .ant-card-head {
      border: 0;
    }

    .ant-table-thead {
      font-size: 12px;
    }
  }
`;

export default (withApollo(injectIntl(SitesDetailsPrintDiscoveriesReasearches)): React.ComponentType<mixed>);
