// @flow
import * as React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Popconfirm } from 'antd';
import { HomeOutlined, RocketOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import RejectDrawer from './RejectDrawer';

type PropsType = {
  accept: Function,
  reject: Function,
};

type StateType = {
  showRejectDrawer: boolean,
};

class AcceptRejectBar extends React.Component<PropsType, StateType> {
  state: StateType = {
    showRejectDrawer: false,
  };

  showDrawer: (() => void) = () => {
    this.setState({
      showRejectDrawer: true,
    });
  };

  onClose: (() => void) = () => {
    this.setState({
      showRejectDrawer: false,
    });
  };

  render(): React.Node {
    const { accept, reject } = this.props;
    const { showRejectDrawer } = this.state;

    return (
      <ButtonsContainer>
        <Popconfirm
          title={
            <FormattedMessage id="AcceptRejectBar.acceptQuestion" defaultMessage="Czy chcesz opublikować ten szkic?" />
          }
          onConfirm={accept}
          okText={<FormattedMessage id="general.yes" defaultMessage="Tak" />}
          cancelText={<FormattedMessage id="general.no" defaultMessage="Nie" />}
        >
          <Button icon={<LegacyIcon type={RocketOutlined} />} size="large" type="primary">
            <FormattedMessage id="AcceptRejectBar.accept" defaultMessage="Akceptuj i opublikuj szkic" />
          </Button>
        </Popconfirm>

        <Button
          type="danger"
          icon={<LegacyIcon type={HomeOutlined} />}
          size="large"
          style={{ marginLeft: '5px' }}
          onClick={this.showDrawer}
        >
          <FormattedMessage id="AcceptRejectBar.reject" defaultMessage="Odrzuć szkic i zgłoś uwagi" />
        </Button>
        <RejectDrawer open={showRejectDrawer} onClose={this.onClose} reject={reject} />
      </ButtonsContainer>
    );
  }
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default AcceptRejectBar;
