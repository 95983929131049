// @flow
export const sitesListFloatingColumns = [
  'created_at',
  'updated_at',
  'status',
  'published_at',
  'created_by_email',
  'local_name',
  'physicalgeographical_unit',
  'voivodeship',
  'county',
  'community',
  'city',
  'ordinal_number_in_city',
  'azp',
  'ordinal_number_in_azp',
];

export const sitesListDefaultColumns = ['azp', 'ordinal_number_in_azp', 'city', 'ordinal_number_in_city'];

export const sitesListDefaultColumnsForAdministration = [
  'created_at',
  'updated_at',
  'published_at',
  'status',
  'azp',
  'ordinal_number_in_azp',
  'city',
  'ordinal_number_in_city',
];

export const sitesListDefaultColumnsForModeration = [
  'created_at',
  'updated_at',
  'created_by_email',
  'status',
  'local_name',
];

export const sitesListDefaultColumnsForMyWork = ['created_at', 'updated_at', 'status', 'local_name'];

export const columnsSettings = {
  azp: { onlyForAdmin: false, align: 'center' },
  created_at: { onlyForAdmin: true, align: 'lest' },
  created_by_email: { onlyForAdmin: true, align: 'left' },
  id: { onlyForAdmin: false, align: 'center' },
  ordinal_number_in_azp: { onlyForAdmin: false, align: 'center' },
  ordinal_number_in_city: { onlyForAdmin: false, align: 'center' },
  published_at: { onlyForAdmin: true, align: 'left' },
  status: { onlyForAdmin: true, align: 'center' },
  updated_at: { onlyForAdmin: true, align: 'left' },
};
