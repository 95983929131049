// @flow
import * as React from 'react';
import { Select } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import formItemLayout from '../../lib/constants/formItemLayout';
// Own types
import type { ValidationRulesType } from '../../lib/types';

type PropsType = {
  getFieldDecorator: Function,
  initialFieldValue: string,
  label: string,
  name: string,
  validationRules: ValidationRulesType,
};

const { Option } = Select;

const StatusSelect = (props: PropsType): React.Node => {
  const FormItem = Form.Item;
  const {
    label, name, getFieldDecorator, validationRules, initialFieldValue,
  } = props;

  return (
    <FormItem {...formItemLayout} label={label}>
      {getFieldDecorator(name, {
        rules: validationRules,
        initialValue: initialFieldValue,
      })(
        <Select allowClear mode="multiple">
          <Option value="DRAFT">
            <FormattedMessage id="general.status.draft" defaultMessage="Szkic" />
          </Option>
          <Option value="READY">
            <FormattedMessage id="general.status.ready" defaultMessage="Do akceptacji" />
          </Option>
          <Option value="PUBLISHED">
            <FormattedMessage id="general.status.published" defaultMessage="Opublikowany" />
          </Option>
          <Option value="REJECTED">
            <FormattedMessage id="general.status.rejected" defaultMessage="Odrzucony" />
          </Option>
        </Select>,
      )}
    </FormItem>
  );
};

export default StatusSelect;
