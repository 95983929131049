// @flow
import * as React from 'react';
import styled, { css, withTheme } from 'styled-components';
import Footer from './Footer';
import FooterInAccessibility from './FooterInAccessibility';
import { standard, accessibility } from '../../lib/constants/themeModes';

const NavigationContainer = ({ theme }: { theme: any }): React.Node => {
  const { mode } = theme;

  return (
    <StyledFooter>
      {mode === standard && <Footer />}
      {mode === accessibility && <FooterInAccessibility />}
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: #001529 !important;
      color: yellow;
      font-size: 18px;
    `};
`;

export default (withTheme(NavigationContainer): any);
