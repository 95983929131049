// @flow
import * as Sentry from '@sentry/browser';

export default () => {
  const environment = process.env.REACT_APP_ENV;
  const dsn = process.env.REACT_APP_SENTRY_KEY;

  if (!environment || !dsn) return;

  Sentry.init({ dsn, environment });
};
