// @flow
// React
import * as React from 'react';
import { graphql } from 'react-apollo';
import { message } from 'antd';
import { injectIntl, defineMessages } from 'react-intl';
import type {
  MutationFunc, MutationUpdaterFn, DataProxy, FetchResult,
} from 'react-apollo';

import parseNotes from '../../../lib/utils/parseNotes';
import parseDiscoveries from '../../../lib/utils/parseDiscoveries';
import SITE_STATUS from '../../../lib/constants/siteStatus';
import withRouter from '../../../lib/utils/withRouter';

// Queries and mutation
import ADD_SITE_MUTATION from '../../../lib/mutations/addSite';
import ALL_SITES_QUERY from '../../../lib/queries/allSites';

// Components
import SiteForm from '../../../components/SiteForm';
import LoadingSpin from '../../../components/LoadingSpin';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';

// Utils
import removePropertiesFromGeoJson from '../../../lib/utils/removePropertiesFromGeoJson';

// Own Types
import type { GeoJsonType, SiteType, UserType } from '../../../lib/types';

type ResponseType = {
  allSites: {
    concat: Function,
    data: SiteType[],
  },
};

type ResultType = {
  addSite: SiteType,
};

type PropsType = {
  AddSiteMUTATION: MutationFunc<ResultType, SiteType>,
  intl: any,
  navigate: any,
  user: UserType,
};

type StateType = {
  isGeojsonTouched: boolean,
  updating: boolean,
};

export class SitesNew extends React.PureComponent<PropsType, StateType> {
  geojson: ?GeoJsonType;

  area: number;

  constructor() {
    super();

    this.geojson = null;
    this.area = 0;
  }

  state: StateType = {
    isGeojsonTouched: false,
    updating: false,
  };

  // eslint-disable-next-line
  handleMapChange: (({ area: number, geojson: GeoJsonType }) => void) = ({ geojson, area }: { area: number, geojson: GeoJsonType }) => {
    this.geojson = geojson;
    this.area = area;

    this.setState({
      isGeojsonTouched: true,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  mutationUpdater: MutationUpdaterFn<ResultType> = (cache: DataProxy, mutationResult: FetchResult<ResultType>) => {
    try {
      const response: ?ResponseType = cache.readQuery({ query: ALL_SITES_QUERY });
      const { data } = mutationResult;
      if (response && data) {
        cache.writeQuery({
          query: ALL_SITES_QUERY,
          data: { allSites: response.allSites.concat([data.addSite]) },
        });
      }
    } catch (e) {
      // https://github.com/apollographql/apollo-client/issues/1701
    }
  };

  addNewSite: (
    formValues: SiteType,
    afterSave: string
  ) => Promise<void> = async (
      formValues: SiteType,
      afterSave: string,
    ): Promise<void> => {
      const {
        AddSiteMUTATION,
        intl,
        navigate,
        user: { email },
      } = this.props;

      this.setState({ updating: true });
      const response = await AddSiteMUTATION({
        variables: {
          ...formValues,
          geojson: removePropertiesFromGeoJson(formValues.geojson),
          monuments_register_addition_date:
          formValues.monuments_register_addition_date
          && formValues.monuments_register_addition_date.format('YYYY-MM-DD'),
          keza_created_at: formValues.keza_created_at && formValues.keza_created_at.format('YYYY-MM-DD'),
          notes: parseNotes(formValues.notes),
          discoveries: parseDiscoveries(formValues.discoveries),
        },
        update: this.mutationUpdater,
      });
      this.setState({ updating: false });

      if ('errors' in response) {
        return;
      }

      const translatedSuccessMessage = intl.formatMessage(SitesNew.translations.submitSuccessMessage);
      if (afterSave === 'list') {
        navigate(`/sites/list/my-work?createdByEmail=${String(email)}&status=${String(SITE_STATUS.draft)}`);
      } else if (response && response.data && response.data.addSite) {
        navigate(`/admin/sites/${String(response.data.addSite.id)}/edit`);
      }
      message.success(translatedSuccessMessage);
    };

  static translations: any = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Stanowisko zostało prawidłowo dodane',
      id: 'SitesNew.submitSuccessMessage',
    },
    submitErrorMessage: {
      defaultMessage: 'Błąd walidacji formularza, sprawdź wszystkie pola',
      id: 'SitesNew.errorMessage',
    },
  });

  render(): React.Node {
    const { intl } = this.props;
    const { updating, isGeojsonTouched } = this.state;
    const submitErrorMessage = intl.formatMessage(SitesNew.translations.submitErrorMessage);

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['admin', 'addSite']} />
        {updating && <LoadingSpin tip="Loading..." />}
        {!updating && (
          <SiteForm
            onSubmit={this.addNewSite}
            submitErrorMessage={submitErrorMessage}
            isGeojsonTouched={isGeojsonTouched}
          />
        )}
      </React.Fragment>
    );
  }
}

export default (graphql(ADD_SITE_MUTATION, { name: 'AddSiteMUTATION' })(
  injectIntl(withRouter(withUser(SitesNew))),
): any);
