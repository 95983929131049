// @flow
import * as React from 'react';
import { compile } from 'path-to-regexp';
import queryString from 'query-string';

type PropsType = {
  children: React.Node,
  className: ?string,
  linkParams: ?Object,
  linkPattern: string,
  searchParams: ?Object,
  serviceUrl: string,
};

export default (props: PropsType): React.Node => {
  const {
    serviceUrl, linkPattern, linkParams = {}, searchParams = {}, children, className = null,
  } = props;

  const toPath = compile(linkPattern);
  const link = toPath(linkParams);

  let searchString = queryString.stringify(searchParams, { encode: false });
  if (searchString) searchString = `?${searchString}`;

  return (
    <a href={`${serviceUrl}${link}${searchString}`} target="_blank" rel="noopener noreferrer" className={className}>
      {children}
    </a>
  );
};
