// @flow
import type { LeafletBoundsType } from '../../types';

type NestedArray<T> = Array<T | NestedArray<T>>;

export default function boundsToArray(leafletBounds: LeafletBoundsType): NestedArray<number> {
  return [
    [leafletBounds._northEast.lat, leafletBounds._northEast.lng],
    [leafletBounds._southWest.lat, leafletBounds._southWest.lng],
  ];
}
