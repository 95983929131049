// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button, Card, Col, Divider, Row, message, Input, Alert, Popconfirm, Checkbox,
} from 'antd';
import { Form, Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';
import turfFlatten from '@turf/flatten';
import DatePickerField from '../DatePickerField';
import InputTextareaField from '../InputTextareaField.js';
import DictionarySelect from '../DictionarySelect';
import SiteNotes from '../SiteNotes';
import SiteDiscoveries from './SiteDiscoveries';
import InputUniqInScopeOfRelatedField from './InputUniqInScopeOfRelatedField';
import dictionariesId from '../../lib/constants/dictionariesId';
import type { SiteType, GeoJsonType, UserType } from '../../lib/types';
import siteDataTranslationsMessages from '../../lib/constants/siteDataTranslationsMessages';
import { pageContainerStyle } from '../../lib/style/pageStyle';
import SITE_STATUS from '../../lib/constants/siteStatus';
import USER_LEVEL from '../../lib/constants/userLevel';
import StatusFormatter from '../StatusFormatter';
import withUser from '../withUser';
import EditableMap from '../EditableMap';
import { generalFontSize, darkGreyColor } from '../../lib/style/themes';

// Form Validation
import { required, min5Characters } from '../../lib/formValidation';
import withRouter from '../../lib/utils/withRouter';

export type getFieldDecoratorType = (?string, Object) => Function;
export type setFieldsValueType = Object => void;

type PropsType = {
  form: {
    getFieldDecorator: getFieldDecoratorType,
    getFieldsValue: Function,
    getFieldValue: Function,
    isFieldsTouched: Function,
    resetFields: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  geojson: GeoJsonType,
  handleRemoveSite: (id: string) => Promise<void>,
  navigate: any,
  onSubmit: (val: any, afterSave: string) => void,
  site: SiteType,
  submitErrorMessage: string,
  submitSiteToReview: ?Function,
  user: UserType,
};

type StateType = {
  area: number,
  geojson: GeoJsonType | null,
  isGeojsonTouched: boolean,
  loading: boolean,
  showGeojsonRequiredError: boolean,
};

class SiteForm extends React.PureComponent<PropsType, StateType> {
  state = {
    // nie mam pojecia po co ten loading tu jest
    loading: false,
    area: 0,
    geojson: null,
    isGeojsonTouched: false,
    showGeojsonRequiredError: false,
  };

  componentDidMount() {
    const { site } = this.props;

    const geojson = (site && site.geojson) ? turfFlatten(site.geojson) : null;

    const area = site && site.site_area;

    this.setState({
      geojson,
      area,
    });
  }

  setLoading = (loadingState: boolean) => {
    this.setState({ loading: loadingState });
  };

  handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>, afterSave: string) => {
    event.preventDefault();

    const {
      onSubmit,
      form: { validateFields, getFieldsValue },
      submitErrorMessage,
    } = this.props;

    const { geojson, area } = this.state;

    validateFields(SiteForm.draftValidationsFields, (errors: string) => {
      if (!errors) {
        const values = getFieldsValue();
        onSubmit({
          geojson,
          site_area: area,
          ...values,
        }, afterSave);
      } else {
        message.error(submitErrorMessage);
      }
    });
  };

  handleSubmitToReview = () => {
    const {
      form: { validateFields },
      submitErrorMessage,
      submitSiteToReview,
    } = this.props;

    const { geojson } = this.state;

    this.setState({
      showGeojsonRequiredError: !geojson,
    });

    validateFields((errors: string) => {
      if (!errors && geojson && typeof submitSiteToReview === 'function') {
        submitSiteToReview();
      } else {
        message.error(submitErrorMessage);
      }
    });
  };

  handleMapChange = ({ geojson, area }: { area: number, geojson: GeoJsonType }) => {
    this.setState({
      area,
      geojson,
      isGeojsonTouched: true,
      showGeojsonRequiredError: !geojson,
    });
  };

  static draftValidationsFields = [
    'local_name',
    'discoveries',
    'notes',
  ];

  render(): React.Node {
    const {
      form: {
        getFieldDecorator, validateFields, setFieldsValue, getFieldValue, resetFields, isFieldsTouched,
      },
      form,
      handleRemoveSite,
      navigate,
      site,
      user,
    } = this.props;

    const {
      geojson, isGeojsonTouched, showGeojsonRequiredError, loading,
    } = this.state;
    const FormItem = Form.Item;

    const {
      voivodeshipId,
      countyId,
      communityId,
      cityId,
      azpSiteNumberId,
      physicalgeographicalUnitId,
    } = dictionariesId;

    // yes omg yes...
    const isPublished = !!(site && site.status === SITE_STATUS.published);
    const isDraft = !!(site && site.status === SITE_STATUS.draft);
    const isReady = !!(site && site.status === SITE_STATUS.ready);
    const isRejected = !!(site && site.status === SITE_STATUS.rejected);
    const isSuperAdmin = !!(user.level === USER_LEVEL.superAdmin);
    const isTouched = !!(isFieldsTouched() || !!isGeojsonTouched);
    const isOwner = !!(!site || (site.created_by_email === user.email));

    const disableSave = (isPublished && !isSuperAdmin) || !isTouched || (!isOwner && !isSuperAdmin) || isReady;
    const disableSendToReview = !site
      || isTouched
      || isPublished
      || isReady
      || isRejected
      || (!isOwner && !isSuperAdmin);
    const showRemoveButton = (isPublished && isSuperAdmin) || (isDraft && isOwner);

    return (
      <StyledPageContainer>
        <Form layout="vertical">
          <StyledCard
            bordered={false}
            title={(
              <div className="title">
                <FormattedMessage id="SiteForm.sectionMetadataTitle" defaultMessage="Metadane" />
              </div>
          )}
          >
            <Row gutter={48} type="flex" align="middle">
              <Col lg={12}>
                <FormItem label={<FormattedMessage {...siteDataTranslationsMessages.local_name} />}>
                  {getFieldDecorator('local_name', {
                    rules: [required, min5Characters],
                    initialValue: site ? site.local_name : null,
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col lg={4}>
                <FormattedMessage {...siteDataTranslationsMessages.status} />: &nbsp;
                <StatusFormatter status={site ? site.status : 'DRAFT'} />
              </Col>
              <Col lg={8}>
                <FormattedMessage {...siteDataTranslationsMessages.created_by_email} />: &nbsp;
                {(site && site.created_by_email) || user.email}
              </Col>
            </Row>
            { site && site.reject_reason && (
              <Alert
                message={
                  // eslint-disable-next-line
                  <span dangerouslySetInnerHTML={{ __html: site.reject_reason.replace(/\r?\n/g, '<br/>') }} />
                }
                showIcon
                type="error"
              />
            )}
          </StyledCard>
          <StyledCard
            bordered={false}
            title={(
              <React.Fragment>
                <span style={{ color: '#f5222d' }}>*&nbsp;</span>
                <FormattedMessage id="SiteForm.sectionLocationOnMapTitle" defaultMessage="Lokalizacja na mapie" />
                {showGeojsonRequiredError && (
                  <span style={{ color: '#f5222d' }}>&nbsp; - {required.message}</span>
                )}
              </React.Fragment>
            )}
          >
            <EditableMap onChange={this.handleMapChange} geojsonData={geojson} />
          </StyledCard>
          <StyledCard
            bordered={false}
            title={<FormattedMessage id="SiteForm.sectionLocationTitle" defaultMessage="Lokalizacja" />}
          >
            <Row gutter={48}>
              <Col lg={12}>
                <DictionarySelect
                  dictionaryId={voivodeshipId}
                  label={<FormattedMessage {...siteDataTranslationsMessages.voivodeship} />}
                  name="voivodeship_id"
                  getFieldDecorator={getFieldDecorator}
                  validateFields={validateFields}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.voivodeship : null}
                />
                <DictionarySelect
                  dictionaryId={countyId}
                  label={<FormattedMessage {...siteDataTranslationsMessages.county} />}
                  name="county_id"
                  getFieldDecorator={getFieldDecorator}
                  validateFields={validateFields}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.county : null}
                />
              </Col>
              <Col lg={12}>
                <DictionarySelect
                  dictionaryId={communityId}
                  label={<FormattedMessage {...siteDataTranslationsMessages.community} />}
                  name="community_id"
                  getFieldDecorator={getFieldDecorator}
                  validateFields={validateFields}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.community : null}
                />
              </Col>
            </Row>
            <Divider />
            <Row gutter={48}>
              <Col lg={12}>
                <DictionarySelect
                  dictionaryId={azpSiteNumberId}
                  label={<FormattedMessage {...siteDataTranslationsMessages.azp} />}
                  name="azp_id"
                  getFieldDecorator={getFieldDecorator}
                  validateFields={validateFields}
                  relatedFieldName="ordinal_number_in_azp"
                  validationRules={[required]}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.azp : null}
                />
              </Col>
              <Col lg={12}>
                <InputUniqInScopeOfRelatedField
                  label={<FormattedMessage {...siteDataTranslationsMessages.ordinal_number_in_azp} />}
                  name="ordinal_number_in_azp"
                  getFieldDecorator={getFieldDecorator}
                  messageIsNotUnique={(
                    <FormattedMessage
                      id="SiteForm.messageIsNotUnique.ordinalNumberInAzp"
                      defaultMessage="Nr stanowiska na obszarze AZP już istnieje"
                    />
                  )}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  resetFields={resetFields}
                  relatedFieldName="azp_id"
                  relatedFieldInitialValue={site ? site.azp : null}
                  validationRules={[required]}
                  initialFieldValue={site ? site.ordinal_number_in_azp : null}
                />
              </Col>
            </Row>
            <Row gutter={48}>
              <Col lg={12}>
                <DictionarySelect
                  dictionaryId={cityId}
                  label={<FormattedMessage {...siteDataTranslationsMessages.city} />}
                  name="city_id"
                  getFieldDecorator={getFieldDecorator}
                  validateFields={validateFields}
                  relatedFieldName="ordinal_number_in_city"
                  validationRules={[required]}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.city : null}
                />
              </Col>
              <Col lg={12}>
                <InputUniqInScopeOfRelatedField
                  label={<FormattedMessage {...siteDataTranslationsMessages.ordinal_number_in_city} />}
                  name="ordinal_number_in_city"
                  getFieldDecorator={getFieldDecorator}
                  messageIsNotUnique={(
                    <FormattedMessage
                      id="SiteForm.messageIsNotUnique.ordinalNumberInCity"
                      defaultMessage="Nr stanowiska w miejscowości już istnieje"
                    />
                  )}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  resetFields={resetFields}
                  validationRules={[required]}
                  relatedFieldName="city_id"
                  relatedFieldInitialValue={site ? site.city : null}
                  initialFieldValue={site ? site.ordinal_number_in_city : null}
                />
              </Col>
            </Row>
            <Divider />
            <Row gutter={48}>
              <Col lg={12}>
                <DictionarySelect
                  dictionaryId={physicalgeographicalUnitId}
                  label={<FormattedMessage {...siteDataTranslationsMessages.physicalgeographical_unit} />}
                  name="physicalgeographical_unit_id"
                  getFieldDecorator={getFieldDecorator}
                  validateFields={validateFields}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.physicalgeographical_unit : null}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard bordered={false} title={<FormattedMessage {...siteDataTranslationsMessages.conservator_notes} />}>
            <Row gutter={48}>
              <Col lg={24}>
                <InputTextareaField
                  name="conservator_notes"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.conservator_notes : null}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard
            bordered={false}
            title={
              <FormattedMessage id="SiteForm.sectionProtectionThreatsTitle" defaultMessage="Ochrona i zagrożenia" />
            }
          >
            <StyledParagraph>
              <FormattedMessage
                id="SiteForm.sectionProtectionThreats.currentProtection"
                defaultMessage="Aktualna ochrona"
              />
            </StyledParagraph>
            <Divider />
            <Row gutter={48}>
              <Col lg={12}>
                <FormItem label={<FormattedMessage {...siteDataTranslationsMessages.monuments_register_number} />}>
                  {getFieldDecorator('monuments_register_number', {
                    initialValue: site ? site.monuments_register_number : null,
                  })(<Input />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('culture_park', {
                    initialValue: site ? site.culture_park : false,
                    valuePropName: 'checked',
                  })(
                    <StyledCheckbox>
                      <FormattedMessage
                        {...siteDataTranslationsMessages.culture_park}
                      />
                    </StyledCheckbox>,
                  )}
                </FormItem>
              </Col>
              <Col lg={12}>
                <DatePickerField
                  label={<FormattedMessage {...siteDataTranslationsMessages.monuments_register_addition_date} />}
                  name="monuments_register_addition_date"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={site ? site.monuments_register_addition_date : null}
                />
                <FormItem>
                  {getFieldDecorator('zoning_plan', {
                    initialValue: site ? site.zoning_plan : false,
                    valuePropName: 'checked',
                  })(
                    <StyledCheckbox><FormattedMessage {...siteDataTranslationsMessages.zoning_plan} /></StyledCheckbox>,
                  )}
                </FormItem>
              </Col>
            </Row>
            <StyledParagraph>
              <FormattedMessage
                id="SiteForm.sectionProtectionThreats.conservatorConclusions"
                defaultMessage="Wnioski konserwatora zabytków"
              />
            </StyledParagraph>
            <Divider />
            <Row gutter={48}>
              <Col lg={12}>
                <InputTextareaField
                  label={<FormattedMessage {...siteDataTranslationsMessages.hazards} />}
                  name="hazards"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  initialFieldValue={site ? site.hazards : null}
                />
              </Col>
              <Col lg={12}>
                <FormItem>
                  {getFieldDecorator('conservator_detailed_inventory', {
                    initialValue: site ? site.conservator_detailed_inventory : false,
                    valuePropName: 'checked',
                  })(
                    <StyledCheckbox>
                      <FormattedMessage {...siteDataTranslationsMessages.conservator_detailed_inventory} />
                    </StyledCheckbox>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('conservator_excavation_research', {
                    initialValue: site ? site.conservator_excavation_research : false,
                    valuePropName: 'checked',
                  })(
                    <StyledCheckbox>
                      <FormattedMessage {...siteDataTranslationsMessages.conservator_excavation_research} />
                    </StyledCheckbox>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('conservator_administrative_inventory', {
                    initialValue: site ? site.conservator_administrative_inventory : false,
                    valuePropName: 'checked',
                  })(
                    <StyledCheckbox>
                      <FormattedMessage {...siteDataTranslationsMessages.conservator_administrative_inventory} />
                    </StyledCheckbox>,
                  )}
                </FormItem>
              </Col>
            </Row>
          </StyledCard>
          <StyledCard
            bordered={false}
            title={
              <FormattedMessage id="SiteForm.sectionKEZATitle" defaultMessage="Wprowadzono na podstawie karty KEZA" />
            }
          >
            <Row gutter={48}>
              <Col lg={12}>
                <FormItem label={<FormattedMessage {...siteDataTranslationsMessages.keza_author} />}>
                  {getFieldDecorator('keza_author', {
                    initialValue: site ? site.keza_author : null,
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col lg={12}>
                <DatePickerField
                  label={<FormattedMessage {...siteDataTranslationsMessages.keza_created_at} />}
                  name="keza_created_at"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={site ? site.keza_created_at : null}
                />
              </Col>
            </Row>
          </StyledCard>
          <StyledCard
            bordered={false}
            title={<FormattedMessage id="sitesNew.researchFindings" defaultMessage="Badania i znaleziska" />}
          >
            <Row gutter={48}>
              <SiteDiscoveries discoveries={site ? site.discoveries : []} form={form} />
            </Row>
          </StyledCard>
          <StyledCard bordered={false} title={<FormattedMessage id="SiteForm.sectionNotes" defaultMessage="Notatki" />}>
            <Row gutter={48}>
              <SiteNotes notes={site ? site.notes : []} form={form} setLoading={this.setLoading} />
            </Row>
          </StyledCard>
          <StyledCard>
            <ButtonContainer>
              {showRemoveButton && (
                <Popconfirm
                  title={(
                    <FormattedMessage
                      id="SiteForm.removeQuestion"
                      defaultMessage="Czy na pewno chcesz usunąć stanowisko?"
                    />
  )}
                  onConfirm={(): Promise<void> => handleRemoveSite(site.id)}
                  okText={<FormattedMessage id="general.yes" defaultMessage="Tak" />}
                  cancelText={<FormattedMessage id="general.no" defaultMessage="Nie" />}
                >
                  <Button
                    size="large"
                  >
                    <Icon type="delete" />
                    <FormattedMessage id="general.remove" defaultMessage="Usuń" />
                  </Button>
                </Popconfirm>
              )}
              <Button
                size="large"
                onClick={(): void => navigate(-1)}
              >
                <Icon type="undo" />
                <FormattedMessage id="general.cancel" defaultMessage="Anuluj" />
              </Button>
              <Button
                type="primary"
                size="large"
                onClick={(e: SyntheticInputEvent<HTMLInputElement>): void => this.handleSubmit(e, 'back')}
                loading={loading}
                disabled={disableSave}
              >
                <Icon type="save" theme="filled" />
                <FormattedMessage id="general.save" defaultMessage="Zapisz" />
              </Button>
              <Button
                size="large"
                onClick={(e: SyntheticInputEvent<HTMLInputElement>): void => this.handleSubmit(e, 'list')}
                loading={loading}
                disabled={disableSave}
              >
                <Icon type="save" theme="filled" />
                <FormattedMessage id="general.saveAndGoBackToTheList" defaultMessage="Zapisz i wróć" />
              </Button>
              <Button
                size="large"
                loading={loading}
                disabled={disableSendToReview}
                onClick={this.handleSubmitToReview}
              >
                <Icon type="rocket" />
                <FormattedMessage id="SiteFirm.sendToReview" defaultMessage="Wyślij do akceptacji" />
              </Button>
            </ButtonContainer>
          </StyledCard>
        </Form>
      </StyledPageContainer>
    );
  }
}

const ButtonContainer = styled.div`
  > * {
    margin-left: 5px;
  }
  text-align: right;
`;

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;
`;

const StyledParagraph = styled.p`
  color: ${darkGreyColor};
  font-size: ${generalFontSize};
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${darkGreyColor} !important;
`;

export default (Form.create()(withRouter(withUser(SiteForm))): any);
