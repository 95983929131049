// @flow
import * as React from 'react';
import { Layout, message } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import NavigationContainer from '../Navigation';
import Footer from '../Footer';
import Routes from '../../pages/routes';
import 'antd/dist/antd.min.css';
import { navigationHeight } from '../../lib/style/themes';
import Breakpoints from '../../lib/constants/breakpoints';
import QuestionnaireModal from '../QuestionnaireModal';
import CookieBanner from '../CookieBanner';

const { Content } = Layout;

const AppLayout = (): React.Node => (
  <React.Fragment>
    <GlobalStyle />
    <FullHeightLayout>
      <NavigationContainer />
      <Content>
        <Routes />
      </Content>
      <QuestionnaireModal />
      <Footer />
    </FullHeightLayout>
    <CookieBanner />
  </React.Fragment>
);

// global styles for antd messages
message.config({
  top: 84,
});

const FullHeightLayout = styled(Layout)`
  padding-top: ${navigationHeight};
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  @media print {
    padding-top: 0;
    background-color: #ffffff !important;
  }
  .ant-layout-content {
    display: flex;
    flex-direction: column;
  }
  @page {
    margin: 0.5cm;
    size: portrait;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: scroll;
  }

  .ant-menu-dark.ant-menu-submenu-popup {
    position: fixed !important;
  }

  @media print{
    .no-print, .no-print *
    {
        display: none !important;
    }
  }

  @media (max-width: ${Breakpoints.tablet}) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }

  .leaflet-control-locate-marker {
    color: #505050;
    position: absolute;
    top: 7px;
    left: 9px;
    width: 12px;
    height: 12px;
    border: solid 1px currentColor;
    border-radius: 7px 7px 7px 0;
    background-color: currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }

  .leaflet-control-locate-marker:before {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    width: 4px;
    height: 4px;
    color: white;
    border: solid 1px currentColor;
    border-radius: 3px;
    background-color: currentColor;
  }

  @keyframes jump {
    0%   {transform: translate3d(0,10%,0) scale3d(1,1,1) rotate(-45deg);}
    40%  {transform: translate3d(0,-30%,0) scale3d(.7,1.5,1) rotate(-45deg);}
    100% {transform: translate3d(0,-80%,0) scale3d(1.5,.7,1) rotate(-45deg);}
  }

  .leaflet-control-locate-marker.loading {
    color: pink;
    transform-origin: 50% 50%;
    animation: jump 0.5s linear alternate infinite;
  }

  .leaflet-control-locate a {
    font-size: 1.4em;
    color: #444;
    cursor: pointer;
  }

  .leaflet-control-locate.active a span {
    color: blue !important;
  }

  .leaflet-control-locate a span {
    line-height: 30px;
  }

  .leaflet-control-locate.active a {
    color: #2074B6 !important;
  }

  .leaflet-control-locate.active.following a {
    color: #FC8428 !important;
  }
`;

export default AppLayout;
