// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const required = {
  required: true,
  message: (<FormattedMessage id="validation.required" defaultMessage="Pole wymagane" />: React.Node),
};

export const max64Characters = {
  max: 64,
  message: (<FormattedMessage id="validation.max64" defaultMessage="Maksymalnie 64 znaki" />: React.Node),
};

export const max128Characters = {
  max: 128,
  message: (<FormattedMessage id="validation.max128" defaultMessage="Maksymalnie 128 znaki" />: React.Node),
};

export const max2048Characters = {
  max: 2048,
  message: (<FormattedMessage id="validation.max2048" defaultMessage="Maksymalnie 2048 znaków" />: React.Node),
};

export const min3Characters = {
  min: 3,
  message: (<FormattedMessage id="validation.min3" defaultMessage="Minimum 3 znaki" />: React.Node),
};

export const min5Characters = {
  min: 5,
  message: (<FormattedMessage id="validation.min5" defaultMessage="Minimum 5 znaków" />: React.Node),
};

export const emailFormat = {
  type: 'email',
  message: ((
    <FormattedMessage
      id="validation.emailFormat"
      defaultMessage="Wprowadzony adres e-mail jest nieprawidłowy"
    />
  ): React.Node),
};
