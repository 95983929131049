// @flow
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import { RetryLink } from 'apollo-link-retry';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { RestLink } from 'apollo-link-rest';
import resolvers from '../../lib/resolvers';
import errorHandler from './errorHandler';
import refreshTokenLink from './refreshTokenLink';
import authLink from './authLink';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const httpBatch = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ADDRESS,
});

const cache = new InMemoryCache();

const stateLink = withClientState({
  ...resolvers,
  cache,
});

const retryLink = new RetryLink();
const restLink = new RestLink({ uri: process.env.REACT_APP_CMS_ADDRESS });

const client: ApolloClient<mixed> = new ApolloClient({
  link: ApolloLink.from([stateLink, refreshTokenLink, authLink, errorHandler, retryLink, restLink, httpBatch]),
  cache,
  defaultOptions,
  connectToDevTools: !!process.env.REACT_APP_DEBUG,
});

export default client;
