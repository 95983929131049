import gql from 'graphql-tag';

export default gql`
  query site($id: ID, $isAdmin: Boolean = false, $azp_valuePl: String, $ordinalNumberInAzp: String) {
    site(id: $id, azp_valuePl: $azp_valuePl, ordinalNumberInAzp: $ordinalNumberInAzp) {
      id
      geojson
      local_name
      status @include(if: $isAdmin)
      created_at @include(if: $isAdmin)
      updated_at @include(if: $isAdmin)
      published_at @include(if: $isAdmin)
      created_by_email @include(if: $isAdmin)
      reject_reason @include(if: $isAdmin)
      voivodeship {
        id
        value_pl
        value_en
        value_de
      }
      county {
        id
        value_pl
        value_en
        value_de
      }
      community {
        id
        value_pl
        value_en
        value_de
      }
      city {
        id
        value_pl
        value_en
        value_de
      }
      physicalgeographical_unit {
        id
        value_pl
        value_en
        value_de
      }
      azp {
        id
        value_pl
        value_en
        value_de
      }
      ordinal_number_in_azp
      ordinal_number_in_city
      conservator_notes
      hazards
      culture_park
      monuments_register_number
      monuments_register_addition_date
      zoning_plan
      conservator_detailed_inventory
      conservator_excavation_research
      conservator_administrative_inventory
      site_area
      keza_created_at
      keza_author
      notes {
        id
        created_by_email
        content
        created_at
      }
      discoveries {
        id
        discovery_function {
          id
          value_pl
          value_en
          value_de
          dictionary_id
        }
        archaeological_culture {
          id
          value_pl
          value_en
          value_de
          dictionary_id
        }
        chronology {
          id
          value_pl
          value_en
          value_de
          dictionary_id
        }
        researches {
          id
          research_type {
            id
            value_pl
            value_en
            value_de
            dictionary_id
          }
          year
          research_supervisor
          institution
          description
          museum_collections {
            id
            storage_location {
              id
              value_pl
              value_en
              value_de
              dictionary_id
            }
            inventory_number
          }
          documentation
          literature
          other_information
        }
      }
    }
  }
`;

export const getSiteResponseMock = {
  data: {
    site: {
      id: '4',
      geojson: { type: 'GeometryCollection', geometries: [{ type: 'Point', coordinates: [15.608139, 52.075075] }] },
      local_name: 'Bartkowe bez azp',
      status: 'PUBLISHED',
      created_at: '2019-03-21 14:12:35',
      updated_at: '2019-04-03 12:26:52',
      published_at: '2019-03-26 12:16:23',
      created_by_email: 'admin@archeo.pl',
      reject_reason: null,
      voivodeship: {
        id: '101',
        value_pl: 'lubuskie',
        value_en: 'lubuskie',
        value_de: 'lubuskie',
        __typename: 'DictionaryEntry',
      },
      county: null,
      community: {
        id: '190',
        value_pl: 'Zielona G\u00f3ra gmina',
        value_en: 'Zielona G\u00f3ra gmina',
        value_de: 'Zielona G\u00f3ra gmina',
        __typename: 'DictionaryEntry',
      },
      city: {
        id: '860',
        value_pl: 'Lubanice',
        value_en: 'Lubanice',
        value_de: 'Lubanice',
        __typename: 'DictionaryEntry',
      },
      physicalgeographical_unit: null,
      azp: {
        id: '1745',
        value_pl: '42-19',
        value_en: '42-19',
        value_de: '42-19',
        __typename: 'DictionaryEntry',
      },
      ordinal_number_in_azp: '3',
      ordinal_number_in_city: '5',
      conservator_notes: null,
      hazards: null,
      culture_park: false,
      monuments_register_number: null,
      monuments_register_addition_date: null,
      zoning_plan: false,
      conservator_detailed_inventory: false,
      conservator_excavation_research: false,
      conservator_administrative_inventory: false,
      site_area: 0,
      keza_created_at: null,
      keza_author: null,
      notes: [],
      discoveries: [
        {
          id: '5',
          discovery_function: {
            id: '2161',
            value_pl: 'cmentarzysko p\u0142askie',
            value_en: 'cmentarzysko p\u0142askie',
            value_de: 'cmentarzysko p\u0142askie',
            dictionary_id: '6',
            __typename: 'DictionaryEntry',
          },
          archaeological_culture: {
            id: '2202',
            value_pl: 'hamburska',
            value_en: 'hamburska',
            value_de: 'hamburska',
            dictionary_id: '7',
            __typename: 'DictionaryEntry',
          },
          chronology: null,
          researches: [
            {
              id: '4',
              research_type: {
                id: '2276',
                value_pl: 'badania nieinwazyjne',
                value_en: 'badania nieinwazyjne',
                value_de: 'badania nieinwazyjne',
                dictionary_id: '9',
                __typename: 'DictionaryEntry',
              },
              year: null,
              research_supervisor: null,
              institution: null,
              description: null,
              museum_collections: [
                {
                  id: '3',
                  storage_location: {
                    id: '100',
                    value_pl: 'Schonaichanum \u2013 Centrum Historyczno-Archeologiczne w Bytomiu Odrza\u0144skim',
                    value_en: 'Schonaichanum \u2013 Centrum Historyczno-Archeologiczne w Bytomiu Odrza\u0144skim',
                    value_de: 'Schonaichanum \u2013 Centrum Historyczno-Archeologiczne w Bytomiu Odrza\u0144skim',
                    dictionary_id: '10',
                    __typename: 'DictionaryEntry',
                  },
                  inventory_number: '01001',
                  __typename: 'MuseumCollection',
                },
                {
                  id: '4',
                  storage_location: {
                    id: '2280',
                    value_pl: 'Muzeum Archeologiczne \u015arodkowego Nadodrza w \u015awidnicy',
                    value_en: 'Muzeum Archeologiczne \u015arodkowego Nadodrza w \u015awidnicy',
                    value_de: 'Muzeum Archeologiczne \u015arodkowego Nadodrza w \u015awidnicy',
                    dictionary_id: '10',
                    __typename: 'DictionaryEntry',
                  },
                  inventory_number: '2',
                  __typename: 'MuseumCollection',
                },
              ],
              documentation: null,
              literature: null,
              other_information: null,
              __typename: 'Research',
            },
          ],
          __typename: 'Discovery',
        },
      ],
      __typename: 'Site',
    },
  },
};
