// @flow
const WMSLayers = [
  {
    name: 'ortofotomapa',
    maxNativeZoom: 18,
    maxZoom: 20,
    layers: 'Raster',
    format: 'image/png',
    transparent: true,
    url: 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/ORTO/WMS/StandardResolution',
  },
  {
    name: 'topograficzna',
    maxNativeZoom: 18,
    maxZoom: 20,
    layers: 'Raster',
    format: 'image/png',
    transparent: true,
    url: 'https://mapy.geoportal.gov.pl/wss/service/img/guest/TOPO/MapServer/WMSServer',
  },
  {
    name: 'hipsometria',
    maxNativeZoom: 18,
    maxZoom: 20,
    layers: 'ISOK_HipsoDyn',
    format: 'image/png',
    opacity: 0.2,
    transparent: true,
    url: 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/NMT/GRID1/WMS/Hypsometry',
  },
  {
    name: 'cieniowanie',
    maxNativeZoom: 18,
    maxZoom: 20,
    layers: 'Raster',
    format: 'image/png',
    opacity: 0.7,
    transparent: true,
    url: 'https://mapy.geoportal.gov.pl/wss/service/PZGIK/NMT/GRID1/WMS/ShadedRelief',
  },
  {
    name: 'katastralna',
    maxNativeZoom: 18,
    maxZoom: 20,
    layers: ['dzialki', 'numery_dzialek'],
    format: 'image/png',
    transparent: true,
    url: 'https://integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow',
  },
];

export default WMSLayers;
