import ReactDOM from 'react-dom';
import { MapControl } from 'react-leaflet';
import { Control, DomUtil, DomEvent } from 'leaflet';

const DumbControl = Control.extend({
  options: {
    className: '',
    onOff: '',
    handleOff: function noop() {},
  },

  onAdd() {
    const _controlDiv = DomUtil.create('div', this.options.className);
    DomEvent.disableClickPropagation(_controlDiv);

    return _controlDiv;
  },

  onRemove(map) {
    if (this.options.onOff) {
      map.off(this.options.onOff, this.options.handleOff, this);
    }

    return this;
  },
});

class LeafletControl extends MapControl {
  // eslint-disable-next-line
  createLeafletElement(props) {
    return new DumbControl(Object.assign({}, props));
  }

  componentDidMount() {
    this.forceUpdate();
    this.leafletElement.addTo(this.context.map);
  }

  render() {
    if (!this.leafletElement || !this.leafletElement.getContainer()) {
      return null;
    }

    return ReactDOM.createPortal(this.props.children, this.leafletElement.getContainer());
  }
}

export default LeafletControl;
