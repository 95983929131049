// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import { withApollo } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

import SitesTable from './SitesTable';

import type {
  SiteType, SiteFilterUrlType, StringSiteType, UserType,
} from '../../../../lib/types';
import siteDataTranslationsMessages from '../../../../lib/constants/siteDataTranslationsMessages';
import { accessibility } from '../../../../lib/constants/themeModes';

// Queries & Mutation
import GET_SITES_LIST_COLUMNS_QUERY from '../../../../lib/queries/getSitesListColumns';
import {
  saveColumnsInLocalState,
  filterColumnsNotForAdmin,
  makeValueTrueFromArrayOfString,
  switchDefaultColumnsBaseOnUrl,
} from './SiteTableUtils';

import prepareColumnSortOrder from '../../../../lib/utils/prepareColumnSortOrder';

import ColumnsList from '../../../../components/ColumnsList';
import StatusFormatter from '../../../../components/StatusFormatter';
import { columnsSettings } from '../../../../lib/constants/sitesListFloatingColumns';
import Buttons from './Buttons';
import withUser from '../../../../components/withUser';
import TranslatedValue from '../../../../components/TranslatedValue/TranslatedValue';
import { darkBlueColor, biggerFontSize } from '../../../../lib/style/themes';
import Breakpoints from '../../../../lib/constants/breakpoints';

type PropsType = {
  className: string,
  client: any,
  data: {
    allSites: { data: SiteType[] },
    error: string,
    loading: boolean,
    orderedColumns: Array<string>,
    selectedColumns: Array<string>,
  },
  filter: SiteFilterUrlType,
  user: UserType,
};

export type DragSortingRecordType = {
  key: string,
  name: string,
};

type OnDragSortingTableSelectChangeType = (selectedRowKeys: string[]) => void;

export type DragSortingSelectedRowsType = {
  onChange: OnDragSortingTableSelectChangeType,
  selectedRowKeys: Array<string>,
};

type HandleDragSortingSubmitReturnType = Promise<void>;
export type HandleDragSortingSubmitType = ({
  orderedColumns: Array<string>,
  selectedColumns: Array<string>,
}) => HandleDragSortingSubmitReturnType;

export type OnDragSortingTableRowType = (
  record: DragSortingRecordType,
  _index: number
) => { index: number, moveRow: (dragIndex: number, hoverIndex: number) => void };

export type SitesTableHeaderType = {
  dataIndex: string,
  key: string,
  render?: Function,
  title: React.Element<string>,
};

export type AllSitesQueryVariablesType = {
  azp?: boolean,
  city?: boolean,
  community?: boolean,
  county?: boolean,
  local_name?: boolean,
  ordinal_number_in_azp?: boolean,
  ordinal_number_in_city?: boolean,
  physicalgeographical_unit?: boolean,
  voivodeship?: boolean,
};

type StateType = {
  allSitesQueryVariables: AllSitesQueryVariablesType,
  columnSortOrder: ?string,
  isInitialized: boolean,
  limit: number,
  orderedColumns: Array<string>,
  pageNumber: number,
  selectedColumns: Array<string>,
  sitesTableHeader: SitesTableHeaderType[],
};

type TabIndexType = {
  tabIndex: string,
};

class SitesTableContainer extends React.PureComponent<PropsType, StateType> {
  static prepareSitesTableBody = (data: SiteType[]): StringSiteType[] => {
    const sitesTableBody = data.map(
      (site: SiteType): StringSiteType => ({
        azp: (site.azp && site.azp.value_pl) || '-',
        city: site.city && <TranslatedValue value={site.city} />,
        community: site.community && <TranslatedValue value={site.community} />,
        county: site.county && <TranslatedValue value={site.county} />,
        created_at: site.created_at,
        id: site.id,
        key: site.id,
        local_name: site.local_name,
        ordinal_number_in_azp: (site.ordinal_number_in_azp && String(site.ordinal_number_in_azp)) || '-',
        ordinal_number_in_city: (site.ordinal_number_in_city && String(site.ordinal_number_in_city)) || '-', // fix me
        physicalgeographical_unit: site.physicalgeographical_unit && (
          <TranslatedValue value={site.physicalgeographical_unit} />
        ),
        published_at: site.published_at || '-',
        status: <StatusFormatter status={site.status} />,
        updated_at: site.updated_at,
        voivodeship: site.voivodeship && <TranslatedValue value={site.voivodeship} />,
        created_by_email: site.created_by_email,
      }),
    );

    return sitesTableBody;
  };

  static prepareSitesTableHeader = (
    orderedColumns: Array<string>,
    selectedColumns: Array<string>,
    user: UserType,
  ): SitesTableHeaderType[] => {
    const sitesTableHeader = orderedColumns.filter((element: string): boolean => selectedColumns.includes(element)).map(
      (element: string): SitesTableHeaderType => ({
        key: element,
        title: <FormattedMessage {...siteDataTranslationsMessages[element]} />,
        dataIndex: element,
        sorter: true,
        ...(columnsSettings[element] && { align: columnsSettings[element].align }),
        onCell: (): TabIndexType => ({ tabIndex: '0' }),
        onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
      }),
    );

    sitesTableHeader.unshift(SitesTableContainer.sitesTableHeaderId);
    sitesTableHeader.push(SitesTableContainer.sitesTableHeaderCta(user));

    return sitesTableHeader;
  };

  static sitesTableHeaderCta = (user: UserType): Object => ({
    key: 'cta',
    title: <FormattedMessage {...siteDataTranslationsMessages.cta} />,
    dataIndex: 'cta',
    render: (rowText: string, record: SiteType): React.Node => <Buttons record={record} user={user} />,
    onCell: (): TabIndexType => ({ tabIndex: '0' }),
    onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
  });

  state = {
    orderedColumns: [],
    selectedColumns: [],
    sitesTableHeader: [],
    allSitesQueryVariables: {},
    columnSortOrder: null,
    limit: 50,
    pageNumber: 1,
    isInitialized: false,
  };

  async componentDidMount(): Promise<void> {
    await this.initializeSitesTable();
  }

  getColumnsQuery = async (): Promise<{
    columnSortOrder: string,
    orderedColumns: Array<string>,
    selectedColumns: Array<string>,
  }> => {
    const { client } = this.props;

    const columnsList = await client.query({
      query: GET_SITES_LIST_COLUMNS_QUERY,
      fetchPolicy: 'cache-only',
    });

    const {
      data: {
        sitesListColumns: { orderedColumns, selectedColumns, columnSortOrder },
      },
    } = columnsList;

    // We need to remove columns not for admin if user is logged out
    const filteredOrderedColumns = filterColumnsNotForAdmin(orderedColumns);
    const filteredSelectedColumns = filterColumnsNotForAdmin(selectedColumns);

    return {
      columnSortOrder,
      orderedColumns: filteredOrderedColumns,
      selectedColumns: filteredSelectedColumns,
    };
  };

  initializeSitesTable = async (): Promise<void> => {
    const { orderedColumns, selectedColumns, columnSortOrder } = await this.getColumnsQuery();

    const { user } = this.props;

    const selectedColumnsBaseOnUrl = switchDefaultColumnsBaseOnUrl(selectedColumns);
    const sitesTableHeader = SitesTableContainer.prepareSitesTableHeader(
      orderedColumns,
      selectedColumnsBaseOnUrl,
      user,
    );

    this.setState({
      orderedColumns,
      selectedColumns: selectedColumnsBaseOnUrl,
      sitesTableHeader,
      columnSortOrder,
      isInitialized: true,
    });
  };

  updateSitesTable: Function = async ({
    orderedColumns,
    selectedColumns,
  }: any): Promise<void> => {
    const { user } = this.props;

    const sitesTableHeader = SitesTableContainer.prepareSitesTableHeader(orderedColumns, selectedColumns, user);

    let allSitesQueryVariables = orderedColumns.filter((element: string): boolean => selectedColumns.includes(element));

    allSitesQueryVariables = makeValueTrueFromArrayOfString(allSitesQueryVariables);
    this.setState({
      sitesTableHeader,
      allSitesQueryVariables,
      orderedColumns,
      selectedColumns,
      pageNumber: 1,
    });
  };

  updatePageNumber = () => {
    const { pageNumber } = this.state;

    this.setState({ pageNumber: pageNumber + 1 });
  };

  handleDragSortingSubmit = async ({
    orderedColumns,
    selectedColumns,
  }: any): HandleDragSortingSubmitReturnType => {
    if (window.location.pathname === '/sites/list') await saveColumnsInLocalState({ selectedColumns, orderedColumns });
    this.updateSitesTable({ orderedColumns, selectedColumns });
  };

  handleSitesTableSort = async (_: Object, __: Object, sorter: { field: string, order: string }): Promise<void> => {
    const columnSortOrder = prepareColumnSortOrder(sorter);
    if (!columnSortOrder) return;

    if (window.location.pathname === '/sites/list') await saveColumnsInLocalState({ columnSortOrder });

    this.setState({
      columnSortOrder,
      pageNumber: 1,
    });
  };

  static sitesTableHeaderId = {
    key: 'id',
    title: <FormattedMessage {...siteDataTranslationsMessages.id} />,
    dataIndex: 'id',
    onCell: (): TabIndexType => ({ tabIndex: '0' }),
    onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
  };

  render(): React.Node {
    const { className, filter } = this.props;
    const {
      selectedColumns,
      orderedColumns,
      sitesTableHeader,
      allSitesQueryVariables,
      columnSortOrder,
      limit,
      pageNumber,
      isInitialized,
    } = this.state;

    return (
      <React.Fragment>
        <ColumnsList
          handleDragSortingSubmit={this.handleDragSortingSubmit}
          selectedColumns={selectedColumns}
          orderedColumns={orderedColumns}
        />
        <StyledEditColumnsButton id="edit-columns-button" />
        {isInitialized && (
          <SitesTable
            sitesTableHeader={sitesTableHeader}
            allSitesQueryVariables={allSitesQueryVariables}
            columnSortOrder={columnSortOrder}
            limit={limit}
            updatePageNumber={this.updatePageNumber}
            pageNumber={pageNumber}
            filter={filter}
            selectedColumns={selectedColumns}
            prepareSitesTableBody={SitesTableContainer.prepareSitesTableBody}
            className={className}
            handleSitesTableSort={this.handleSitesTableSort}
            orderedColumns={orderedColumns}
          />
        )}
      </React.Fragment>
    );
  }
}

const SitesTableWithApollo = withApollo(withUser(SitesTableContainer));

const StyledEditColumnsButton = styled.div`
  float: right;
  margin-left: 16px;
  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;
      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

const StyledSitesTable: any = styled(SitesTableWithApollo)`
  .ant-table td {
    white-space: nowrap;
  }

  td: focus, th: focus {
    outline: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-table-thead > tr > th {
        background: yellow;
        color: ${darkBlueColor};
        &:focus {
          border: 4px solid red !important;
        }
      }
      td,
      th {
        border: 2px solid black;
        font-size: ${biggerFontSize};
        color: black;
        background: white;
        &:focus {
          border: 4px solid red !important;
        }
      }
      ::selection {
        background: yellow;
        color: black;
      }

      .ant-table-tbody > tr > td.ant-table-column-sort {
        background: white;
      }
    `};
`;

export default StyledSitesTable;
