// @flow
const dictionariesId = {
  voivodeshipId: 1,
  countyId: 2,
  communityId: 3,
  cityId: 4,
  azpSiteNumberId: 5,
  functionId: 6,
  archeoCulturesId: 7,
  chronologyId: 8,
  reasearchDictionaryId: 9,
  physicalgeographicalUnitId: 11,
  storageLocationId: 10,
};

export const SCHONAICHANUM_ID = '100';

export default dictionariesId;
