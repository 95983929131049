// @flow
import * as React from 'react';
import { Row, Card, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import PrintMuseumCollections from './PrintMuseumCollections';

// Own types
import type { ResearchType } from '../../../../../lib/types';

const SitesDetailsPrintDiscoveriesReasearchesDetail = (props: { researches: ResearchType[] }): React.Node => {
  const { researches } = props;

  return researches.map(
    (research: ResearchType, index: number): React.Node => {
      return (
        <StyledCard
          key={research.id}
          title={(
            <FormattedMessage id="SiteDiscoveriesResearchField.researchDetail" defaultMessage="Szczegóły badania">
              {(formatedText: string): React.Node => (
                <StyledSpan>
                  {formatedText}: {index + 1}
                </StyledSpan>
              )}
            </FormattedMessage>
)}
        >
          <Row gutter={16}>
            <Col span={8}>
              <FormattedMessage id="SitesDetailsPrintDiscoveriesReasearchesDetail.year" defaultMessage="Rok">
                {(formatedText: string): React.Node => (
                  <span>
                    <StyledLabel>{formatedText}: </StyledLabel>
                    {research.year ? (
                      research.year
                    ) : (
                      <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                    )}
                  </span>
                )}
              </FormattedMessage>
            </Col>
            <Col span={8}>
              <FormattedMessage
                id="SitesDetailsPrintDiscoveriesReasearchesDetail.researchSupervisor"
                defaultMessage="Kierownik"
              >
                {(formatedText: string): React.Node => (
                  <span>
                    <StyledLabel>{formatedText}: </StyledLabel>
                    {research.research_supervisor ? (
                      research.research_supervisor
                    ) : (
                      <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                    )}
                  </span>
                )}
              </FormattedMessage>
            </Col>
            <Col span={8}>
              <FormattedMessage
                id="SitesDetailsPrintDiscoveriesReasearchesDetail.institution"
                defaultMessage="Instytucja"
              >
                {(formatedText: string): React.Node => (
                  <span>
                    <StyledLabel>{formatedText}: </StyledLabel>
                    {research.institution ? (
                      research.institution
                    ) : (
                      <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                    )}
                  </span>
                )}
              </FormattedMessage>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormattedMessage
                id="SitesDetailsPrintDiscoveriesReasearchesDetail.description"
                defaultMessage="Opis badania"
              >
                {(formatedText: string): React.Node => (
                  <span>
                    <StyledLabel>{formatedText}: </StyledLabel>
                    {research.description ? (
                      research.description
                    ) : (
                      <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                    )}
                  </span>
                )}
              </FormattedMessage>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormattedMessage
                id="SitesDetailsPrintDiscoveriesReasearchesDetail.documentation"
                defaultMessage="Dokumentacja"
              >
                {(formatedText: string): React.Node => (
                  <span>
                    <StyledLabel>{formatedText}: </StyledLabel>
                    {research.documentation ? (
                      research.documentation
                    ) : (
                      <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                    )}
                  </span>
                )}
              </FormattedMessage>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormattedMessage
                id="SitesDetailsPrintDiscoveriesReasearchesDetail.literature"
                defaultMessage="Literatura"
              >
                {(formatedText: string): React.Node => (
                  <span>
                    <StyledLabel>{formatedText}: </StyledLabel>
                    {research.literature ? (
                      research.literature
                    ) : (
                      <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                    )}
                  </span>
                )}
              </FormattedMessage>
            </Col>
          </Row>
          <Row>
            <StyledCard
              key={research.id}
              title={(
                <FormattedMessage
                  id="SitesDetailsPrintDiscoveriesReasearchesDetail.museumCollections"
                  defaultMessage="Zbiory"
                />
)}
            >
              <PrintMuseumCollections museumCollections={research.museum_collections || []} />
            </StyledCard>
          </Row>
        </StyledCard>
      );
    },
  );
};

const StyledSpan = styled.span`
  font-size: 12px;
`;

const StyledLabel = styled.span`
  font-weight: 600;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;

  @media print {
    padding: 0 !important;
    page-break-inside: avoid;

    .ant-card-bordered {
      border: 0;
    }

    .ant-card-head,
    .ant-card-wider-padding,
    .ant-card-body {
      padding: 0 !important;
    }

    .ant-card-head {
      min-height: 0;
      margin-bottom: 10px;
      page-break-after: avoid;
      font-size: 14px !important;
    }

    .ant-card-body {
      font-size: 12px !important;
    }

    .ant-card-head-title {
      padding: 0;
    }

    .ant-card-body > .ant-card > .ant-card-head {
      border: 0;
    }
  }
`;

export default SitesDetailsPrintDiscoveriesReasearchesDetail;
