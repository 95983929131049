// @flow
import * as React from 'react';
import { DatePicker } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { defineMessages, injectIntl } from 'react-intl';
import moment from 'moment';
import formItemLayout from '../../lib/constants/formItemLayout';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const translations = defineMessages({
  placeholder: {
    defaultMessage: 'RRRR-MM-DD',
    id: 'DateRangePickerField.placeholder',
  },
});

type PropsType = {
  getFieldDecorator: Function,
  initialFieldValue: [string, string],
  intl: any,
  isRequired: boolean,
  label: string,
  message: string,
  name: string,
  setFieldsValue: Function,
};

const DateRangePickerField = (props: PropsType): React.Node => {
  const {
    label, name, getFieldDecorator, message, isRequired, intl, initialFieldValue,
  } = props;

  const rules = [
    {
      required: isRequired,
      message,
    },
  ];
  const datePickerPlaceholder = intl.formatMessage(translations.placeholder);

  const initialData = [
    initialFieldValue[0] ? moment(initialFieldValue[0], 'YYYY-MM-DD') : null,
    initialFieldValue[1] ? moment(initialFieldValue[1], 'YYYY-MM-DD') : null,
  ];

  return (
    <FormItem {...formItemLayout} label={label}>
      {getFieldDecorator(name, {
        initialValue: initialData,
        rules,
      })(<RangePicker name={name} format="YYYY-MM-DD" placeholder={datePickerPlaceholder} />)}
    </FormItem>
  );
};

export default (injectIntl(DateRangePickerField): any);
