// @flow
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import LanguageSwitchInAccessibility from '../LanguageSwitch/LanguageSwitchInAccessibility';
import AccessibilitySwitchInAccessibility from '../AccessibilitySwitch/AccessibilitySwitchInAccessibility';
import { darkBlueColor, biggerFontSize } from '../../lib/style/themes';
import Breakpoints from '../../lib/constants/breakpoints';
import withRouter from '../../lib/utils/withRouter';

type PropsType = {
  className: string,
};

type NavigationRefType = { current: React.ElementRef<any> | null };

class NavigationAccessibility extends React.Component<PropsType, void> {
  navigationRef: NavigationRefType;

  constructor() {
    super();
    this.navigationRef = React.createRef();
  }

  componentDidMount() {
    if (this.navigationRef.current) {
      this.navigationRef.current.focus();
    }
  }

  render(): React.Node {
    const { className } = this.props;

    return (
      <StyledNavigationAccessibilityWrapper>
        <nav className={className} ref={this.navigationRef} aria-label="Nawigacja">
          <ul className="nav-list" role="menu" aria-label="Strona domowa" tabIndex="0">
            <li role="presentation">
              <Link to="/" tabIndex="0" className="nav-list__item">
                <span>E-platforma Archeologiczna</span>
              </Link>
            </li>
            <li role="menuitem" tabIndex="0">
              <span className="nav-list__item">
                <FormattedMessage id="Navigation.about.subMenu" defaultMessage="O platformie" />
              </span>
              <ul className="nav-list" role="menubar" tabIndex="0">
                <li role="presentation">
                  <Link to="/about/experts" className="nav-list__item" tabIndex="0" role="menuitem">
                    <FormattedMessage id="Navigation.about.experts" defaultMessage="Dla specjalistów" />
                  </Link>
                </li>
                <li role="presentation">
                  <Link to="/about/finders" className="nav-list__item" tabIndex="0" role="menuitem">
                    <FormattedMessage id="Navigation.about.finders" defaultMessage="Dla poszukiwaczy" />
                  </Link>
                </li>
                <li role="presentation">
                  <Link to="/about/developers" className="nav-list__item" tabIndex="0" role="menuitem">
                    <FormattedMessage id="Navigation.about.developers" defaultMessage="Dla deweloperów" />
                  </Link>
                </li>
                <li role="presentation">
                  <Link to="/about/conservator" className="nav-list__item" tabIndex="0" role="menuitem">
                    <FormattedMessage id="Navigation.about.conservator" defaultMessage="Dane kontaktowe konserwatora" />
                  </Link>
                </li>
                <li role="presentation">
                  <Link to="/about/faq" className="nav-list__item" tabIndex="0" role="menuitem">
                    <FormattedMessage id="Navigation.about.faq" defaultMessage="Jak korzystać" />
                  </Link>
                </li>
                <li role="presentation">
                  <Link to="/about/contact" className="nav-list__item" tabIndex="0" role="menuitem">
                    <FormattedMessage id="Navigation.about.contactForm" defaultMessage="Formularz kontaktowy" />
                  </Link>
                </li>
              </ul>
            </li>
            <li role="presentation">
              <Link to="/sites/map" className="nav-list__item" tabIndex="0" role="menuitem">
                <FormattedMessage id="Navigation.mapView" defaultMessage="Widok mapy" />
              </Link>
            </li>
            <li role="presentation">
              <Link to="/sites/list" className="nav-list__item" tabIndex="0" role="menuitem">
                <FormattedMessage id="Navigation.listView" defaultMessage="Widok tabelaryczny" />
              </Link>
            </li>
          </ul>
          <StyledAccessibilitySection>
            <AccessibilitySwitchInAccessibility />
            <LanguageSwitchInAccessibility />
          </StyledAccessibilitySection>
        </nav>
      </StyledNavigationAccessibilityWrapper>
    );
  }
}

const StyledAccessibilitySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledNavigationAccessibility = styled(NavigationAccessibility)`
  &:focus {
    border: 3px solid red !important;
  }
  .nav-list {
    list-style-type: none;
    line-height: 30px;

    &__item {
      color: yellow;
      font-size: ${biggerFontSize};
      text-decoration: none;

      &:focus {
        border: 2px solid white;
        padding: 2px;
      }
    }
  }
`;

const StyledNavigationAccessibilityWrapper = styled.div`
  nav {
    background-color: ${darkBlueColor};
    padding: 15px 0 11px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: ${Breakpoints.tablet}) {
      flex-direction: column-reverse;
    }
  }
`;

export default (withRouter(StyledNavigationAccessibility): any);
