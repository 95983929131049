import Leaflet from 'leaflet';
import { MapLayer } from 'react-leaflet';
import 'Leaflet.Deflate';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

export default class Deflate extends MapLayer {
  initMapClasses() {
    const { map } = this.props.leaflet || this.context;

    const mapClassName = map._container.className;

    const isAnimClassApplied = mapClassName.indexOf('leaflet-cluster-anim') !== -1;
    const isStyledClassApplied = mapClassName.indexOf('marker-cluster-styled') !== -1;
    const isAnimatedClassApplied = mapClassName.indexOf('marker-cluster-animated') !== -1;

    if (!isAnimClassApplied) map._container.className += ' leaflet-cluster-anim';
    if (!isStyledClassApplied) map._container.className += ' marker-cluster-styled';
    if (isAnimatedClassApplied) map._container.className += ' marker-cluster-animated';
  }

  // eslint-disable-next-line class-methods-use-this
  createLeafletElement(props) {
    return new Leaflet.Deflate(props);
  }

  componentDidMount() {
    const { markerCluster } = this.props;
    super.componentDidMount();
    if (markerCluster) this.initMapClasses();
  }

  updateLeafletElement(_, toProps) {
    const { layerContainer } = this.props.leaflet || this.context;
    layerContainer.removeLayer(this.leafletElement);
    const markerLayer = Leaflet.markerClusterGroup();
    this.leafletElement = new Leaflet.Deflate({ ...toProps, markerLayer });
    layerContainer.addLayer(this.leafletElement);
    if (toProps.data.features.length > 0) Leaflet.geoJSON(toProps.data, toProps).addTo(this.leafletElement);
  }
}
