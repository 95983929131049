// @flow
import * as React from 'react';
import {
  Row, Col, Button, Input, Tabs,
} from 'antd';
import { Form, Icon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import styled, { css, withTheme } from 'styled-components';

import dictionariesId from '../../../../lib/constants/dictionariesId';
import siteDataTranslationsMessages from '../../../../lib/constants/siteDataTranslationsMessages';
import DictionarySelect from '../../../../components/DictionarySelect';
import StatusSelector from '../../../../components/StatusSelector';
import DateRangePickerField from '../../../../components/DateRangePickerField';
import { accessibility, standard } from '../../../../lib/constants/themeModes';
import FallbackInA11y from '../../../../components/FallbackInA11y';
import withUser from '../../../../components/withUser';
import { makeFilterNameWithBadge } from './SitesFiltersUtils';
import formItemLayout from '../../../../lib/constants/formItemLayout';
import type { SiteFilterType, SiteFilterUrlType, UserType } from '../../../../lib/types';
import { darkBlueColor, biggerFontSize } from '../../../../lib/style/themes';

const FormItem = Form.Item;
const { TabPane } = Tabs;

type PropsType = {
  filter: SiteFilterUrlType,
  filterSites: (SiteFilterType | {}) => void,
  form: Object,
  theme: any,
  toggleFullWidthTable: () => void,
  user: UserType,
};

const SitesFilters = (props: PropsType): React.Node => {
  const {
    form: {
      getFieldDecorator,
      validateFields,
      setFieldsValue,
      getFieldValue,
      resetFields,
    },
    toggleFullWidthTable,
    filter,
    filterSites,
    theme: { mode },
    user,
  } = props;

  const handleSubmit = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault();

    validateFields((errors: any, values: SiteFilterType) => {
      // remove empty filter from filters
      Object.keys(values).forEach((key: string) => {
        if (!values[key]) {
          // eslint-disable-next-line
          delete values[key];
        }
      });

      if (!errors) {
        filterSites(values);
      }
    });
  };

  const handleReset = () => {
    filterSites({});
    resetFields();
  };

  const search = queryString.stringify(filter);
  const isNotTabelarView = window.location.pathname !== '/sites/list';


  const basicFilter = <FormattedMessage id="SitesFilter.basicFilter" defaultMessage="Nazwa i AZP" />;
  const basicFilterName = makeFilterNameWithBadge(
    basicFilter,
    filter,
    ['localName_contains', 'azpId', 'ordinalNumberInAzp'],
  );

  const geographyFilter = <FormattedMessage id="SitesFilter.geographyFilter" defaultMessage="Geografia" />;
  const geographyFilterName = makeFilterNameWithBadge(
    geographyFilter,
    filter,
    ['cityId', 'communityId', 'countyId', 'physicalgeographicalUnitId', 'voivodeshipId', 'ordinalNumberInCity'],
  );

  const moderationFilter = <FormattedMessage id="SitesFilter.moderationFilter" defaultMessage="Moderacja" />;
  const moderationFilterName = makeFilterNameWithBadge(
    moderationFilter,
    filter,
    ['status', 'createdByEmail', 'createdAt_date_gte', 'updatedAt_date_gte', 'updatedAt_date_gte'],
  );

  const researchAndDiscoveriesFilter = (
    <FormattedMessage id="SitesFilter.researchAndDiscoveries" defaultMessage="Badania i znaleziska" />
  );
  const researchAndDiscoveriesFilterName = makeFilterNameWithBadge(
    researchAndDiscoveriesFilter,
    filter,
    [
      'discoveries,discovery_function,id',
      'discoveries,archaeological_culture,id',
      'discoveries,chronology,id',
      'discoveries,researches,research_type,id',
      'discoveries,researches,museum_collections,storage_location,id',
    ],
  );

  return (
    <StyledForm className="ant-advanced-search-form" onSubmit={handleSubmit}>
      <FallbackInA11y component={Tabs} type="card" tabBarGutter={10}>
        <FallbackInA11y component={TabPane} tab={basicFilterName} key="1" forceRerender>
          <Row gutter={24}>
            <Col xs={12} sm={12} md={8} lg={6}>
              <FormItem label={<FormattedMessage {...siteDataTranslationsMessages.local_name} />} {...formItemLayout}>
                {getFieldDecorator('localName_contains', {
                  initialValue: filter.localName_contains,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.azpSiteNumberId}
                label={<FormattedMessage {...siteDataTranslationsMessages.azp} />}
                name="azpId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.azpId}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <FormItem
                label={<FormattedMessage {...siteDataTranslationsMessages.ordinal_number_in_azp} />}
                {...formItemLayout}
              >
                {getFieldDecorator('ordinalNumberInAzp', {
                  initialValue: filter.ordinalNumberInAzp,
                })(<Input />)}
              </FormItem>
            </Col>
          </Row>
        </FallbackInA11y>
        <FallbackInA11y component={TabPane} tab={geographyFilterName} key="2" forceRender>
          <Row gutter={24}>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.voivodeshipId}
                label={<FormattedMessage {...siteDataTranslationsMessages.voivodeship} />}
                name="voivodeshipId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.voivodeshipId}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.countyId}
                label={
                  <FormattedMessage {...siteDataTranslationsMessages.county} />
                }
                name="countyId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.countyId}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.communityId}
                label={(
                  <FormattedMessage {...siteDataTranslationsMessages.community} />
                  )}
                name="communityId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.communityId}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.cityId}
                label={
                  <FormattedMessage {...siteDataTranslationsMessages.city} />
                }
                name="cityId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.cityId}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <FormItem
                label={<FormattedMessage {...siteDataTranslationsMessages.ordinal_number_in_city} />}
                {...formItemLayout}
              >
                {getFieldDecorator('ordinalNumberInCity', {
                  initialValue: filter.ordinalNumberInCity,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                dictionaryId={dictionariesId.physicalgeographicalUnitId}
                label={(
                  <FormattedMessage
                    {...siteDataTranslationsMessages.physicalgeographical_unit}
                  />
                )}
                name="physicalgeographicalUnitId"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter.physicalgeographicalUnitId}
                mode="multiple"
              />
            </Col>
          </Row>
        </FallbackInA11y>
        <FallbackInA11y component={TabPane} tab={researchAndDiscoveriesFilterName} key="3" forceRender>
          <Row gutter={24}>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                label={<FormattedMessage {...siteDataTranslationsMessages.discovery_function} />}
                dictionaryId={dictionariesId.functionId}
                name="discoveries,discovery_function,id"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter['discoveries,discovery_function,id']}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                label={<FormattedMessage {...siteDataTranslationsMessages.archaeological_culture} />}
                dictionaryId={dictionariesId.archeoCulturesId}
                name="discoveries,archaeological_culture,id"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter['discoveries,archaeological_culture,id']}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                label={<FormattedMessage {...siteDataTranslationsMessages.chronology} />}
                dictionaryId={dictionariesId.chronologyId}
                name="discoveries,chronology,id"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter['discoveries,chronology,id']}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                label={<FormattedMessage {...siteDataTranslationsMessages.research_type} />}
                dictionaryId={dictionariesId.reasearchDictionaryId}
                name="discoveries,researches,research_type,id"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter['discoveries,researches,research_type,id']}
                mode="multiple"
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <DictionarySelect
                label={<FormattedMessage {...siteDataTranslationsMessages.storage_location} />}
                dictionaryId={dictionariesId.storageLocationId}
                name="discoveries,researches,museum_collections,storage_location,id"
                getFieldDecorator={getFieldDecorator}
                validateFields={validateFields}
                setFieldsValue={setFieldsValue}
                initialFieldValue={filter['discoveries,researches,museum_collections,storage_location,id']}
                mode="multiple"
              />
            </Col>
          </Row>
        </FallbackInA11y>
        {user.email && (
          <FallbackInA11y component={TabPane} tab={moderationFilterName} key="4" forceRender>
            <Row gutter={24}>
              { isNotTabelarView && (
                <Col xs={12} sm={12} md={8} lg={6}>
                  <StatusSelector
                    label={
                      <FormattedMessage {...siteDataTranslationsMessages.status} />
                    }
                    name="status"
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    initialFieldValue={filter.status}
                  />
                </Col>
              )}
              <Col xs={12} sm={12} md={8} lg={6}>
                <FormItem
                  label={<FormattedMessage {...siteDataTranslationsMessages.created_by_email} />}
                  {...formItemLayout}
                >
                  {getFieldDecorator('createdByEmail', {
                    initialValue: filter.createdByEmail,
                  })(<Input name="createdByEmail" />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12} sm={12} md={8} lg={6}>
                <DateRangePickerField
                  label={<FormattedMessage {...siteDataTranslationsMessages.created_at} />}
                  name="createdAt_date"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={[filter.createdAt_date_gte, filter.createdAt_date_lte]}
                />
              </Col>
              <Col xs={12} sm={12} md={8} lg={6}>
                <DateRangePickerField
                  label={<FormattedMessage {...siteDataTranslationsMessages.updated_at} />}
                  name="updatedAt_date"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={[filter.updatedAt_date_gte, filter.updatedAt_date_lte]}
                />
              </Col>
              <Col xs={12} sm={12} md={8} lg={6}>
                <DateRangePickerField
                  label={<FormattedMessage {...siteDataTranslationsMessages.published_at} />}
                  name="publishedAt_date"
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  isRequired={false}
                  initialFieldValue={[filter.publishedAt_date_gte, filter.publishedAt_date_lte]}
                />
              </Col>
            </Row>
          </FallbackInA11y>
        )}
      </FallbackInA11y>
      <ButtonsRow type="flex">
        <Col span={6}>
          <Button onClick={(): void => toggleFullWidthTable()}>
            <Icon type="fullscreen" />
            <FormattedMessage
              id="SitesFilter.fullScreen"
              defaultMessage="Szerokość tabeli"
            />
          </Button>
        </Col>
        <Col span={18} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 8 }}
          >
            <Icon type="filter" />{' '}
            <FormattedMessage
              id="SitesFilters.filter"
              defaultMessage="Filtruj wyniki"
            />
          </Button>
          {mode === standard && !isNotTabelarView && (
            <Link to={`/sites/map?${search}`}>
              <Button type="primary" disabled={!search}>
                <Icon type="compass" />
                &nbsp;&nbsp;
                <FormattedMessage id="SitesFilters.showOnMap" defaultMessage="Pokaż wyniki na mapie" />
              </Button>
            </Link>
          )}
          <Button style={{ marginLeft: 8 }} onClick={handleReset}>
            <Icon type="delete" />{' '}
            <FormattedMessage
              id="SitesFilters.clear"
              defaultMessage="Wyczyść filtry"
            />
          </Button>
        </Col>
      </ButtonsRow>
    </StyledForm>
  );
};

const ButtonsRow = styled(Row)`
  margin-top: 16px;
  background: #fafafa;
  padding: 10px;
  border-top: 1px solid #e8e8e8;
`;

const StyledForm = styled(Form)`
  max-width: 1130px;
  margin: 0 auto !important;
  .ant-row {
    margin-left: -6px !important;
    margin-right: -6px !important;
  }
  .ant-legacy-form-item-label {
    text-align: left;
    user-select: none;
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor};

      .ant-input,
      .ant-select-selection {
        height: 46px !important;
        font-size: ${biggerFontSize};
        color: black;
        background-color: white !important;
        border: 2px solid yellow;
      }

      .ant-input:focus,
      .ant-select-focused .ant-select-selection {
        border-color: red !important;
        box-shadow: 0 0 0 2px red !important;
      }

      .ant-legacy-form-item-label label {
        color: yellow;
        font-size: ${biggerFontSize};
      }

      .ant-btn,
      .ant-btn-primary {
        color: black;
        background-color: yellow;
        border: 2px solid black;
        font-size: ${biggerFontSize};
      }

      .ant-btn:focus,
      .ant-btn-primary:focus {
        color: white;
        background-color: black;
        border: 2px solid yellow;
      }
    `};
`;

export default (withTheme(withUser(SitesFilters)): any);
