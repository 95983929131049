// @flow
import { MapControl } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet-control-geocoder/dist/Control.Geocoder';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import { injectIntl, defineMessages } from 'react-intl';

const translations = defineMessages({
  placeholder: {
    defaultMessage: 'Wyszukaj...',
    id: 'Geocoder.placeholder',
  },
});

class MeasureControl extends MapControl {
  // eslint-disable-next-line
  createLeafletElement(props: Object) {
    const { intl } = this.props;

    return Leaflet.Control.geocoder({
      ...props,
      placeholder: intl.formatMessage(translations.placeholder),
      defaultMarkGeocode: false,
      geocoder: Leaflet.Control.Geocoder.nominatim({
        serviceUrl: process.env.REACT_APP_GEOCODER_URL,
      }),
    });
  }

  componentDidMount() {
    const { map } = this.context;
    this.leafletElement
      .on('markgeocode', (e: Object) => {
        const { bbox } = e.geocode;
        const poly = Leaflet.polygon([
          bbox.getSouthEast(),
          bbox.getNorthEast(),
          bbox.getNorthWest(),
          bbox.getSouthWest(),
        ]);
        map.flyToBounds(poly.getBounds());
      })
      .addTo(map);
  }
}

export default (injectIntl(MeasureControl): any);
