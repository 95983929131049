// @flow
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import SitesNew from './Sites/SitesNew';
import SitesEdit from './Sites/SitesEdit';
import Sites from './Sites';
import Dictionary from './Dictionaries/Dictionary';
import DictionariesList from './Dictionaries/DictionariesList';
import Auth from './Auth';
import ContactForm from './ContactForm';
import ErrorPage from './ErrorPage';
import StaticPage from './StaticPage';
import SitesChangeLog from './Sites/SitesChangeLog/SitesChangeLog';

const RoutesComponent = (): React.Element<typeof Routes> => (
  <Routes>
    {/* Static */}
    <Route path="/" element={<StaticPage />} />
    <Route path="about/experts" element={<StaticPage />} />
    <Route path="about/finders" element={<StaticPage />} />
    <Route path="about/developers" element={<StaticPage />} />
    <Route path="about/conservator" element={<StaticPage />} />
    <Route path="about/faq" element={<StaticPage />} />
    <Route path="about/privacy" element={<StaticPage />} />
    <Route path="about/rules" element={<StaticPage />} />

    {/* Sites */}
    <Route path="sites/*" element={<Sites />} />

    {/* Auth */}
    <Route path="/auth" element={<Auth />} />

    {/* Contact */}
    <Route path="/about/contact" element={<ContactForm />} />

    {/* Private routes */}
    <Route path="/" element={<PrivateRoute location="/" />}>
      {/* Sites */}
      <Route path="admin/sites/new" element={<SitesNew />} />
      <Route path="admin/sites/:siteId/edit" element={<SitesEdit />} />
      <Route path="admin/change-log" element={<SitesChangeLog />} />
      <Route
        path="admin/sites/:siteId/change-log/:changeId?"
        element={<SitesChangeLog />}
      />

      {/* Dictionaries */}
      <Route path="admin/dictionaries" element={<DictionariesList />} />
      <Route path="admin/dictionary/:dictionaryId" element={<Dictionary />} />

      {/* Sentry test */}
      <Route
        path="sentry"
        element={(
          <button type="button" onClick={(): void => window.doError()}>
            Do Error
          </button>
        )}
      />
    </Route>

    {/* Error page */}
    <Route path="*" element={<ErrorPage />} />
  </Routes>
);

export default RoutesComponent;
