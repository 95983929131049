// @flow
import * as React from 'react';
import { withTheme } from 'styled-components';
import Navigation from './Navigation';
import NavigationInAccessibility from './NavigationInAccessibility';
import { standard, accessibility } from '../../lib/constants/themeModes';

const NavigationContainer = ({ theme }: { theme: any }): React.Node => {
  const { mode } = theme;

  return (
    <React.Fragment>
      {mode === standard && <Navigation />}
      {mode === accessibility && <NavigationInAccessibility />}
    </React.Fragment>
  );
};

export default (withTheme(NavigationContainer): any);
