// @flow
import * as React from 'react';
import { WMSTileLayer, LayersControl } from 'react-leaflet';

import type { LayerType } from '../../types';

const prepareWMSLayers = (layers: LayerType[]): React.Node => {
  const { Overlay } = LayersControl;

  return layers.map((layer: LayerType): React.Element<'Overlay'> => (
    <Overlay name={layer.name} key={layer.name}>
      <WMSTileLayer
        layers={layer.layers}
        format={layer.format}
        url={layer.url}
        maxZoom={layer.maxZoom}
        maxNativeZoom={layer.maxNativeZoom}
        transparent={layer.transparent || false}
        opacity={layer.opacity}
      />
    </Overlay>
  ));
};

export default prepareWMSLayers;
