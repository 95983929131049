// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import type { ApolloCurrentResult } from 'react-apollo';
import styled from 'styled-components';
import { Alert, message } from 'antd';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import getSearchParam from '../../lib/utils/getSearchParam';
import CURRENT_USER_MUTATION from '../../lib/mutations/CurrentUser';
import TOKEN_QUERY from '../../lib/queries/Token';
import type { TokenType } from '../../lib/queries/Token';
import LoadingSpin from '../../components/LoadingSpin';

const translations = defineMessages({
  login: {
    defaultMessage: 'Zostałeś zalogowany',
    id: 'Auth.login',
  },
});

type PropsType = {
  client: any,
  intl: any,
};

type StateType = {
  error: boolean,
  loading: boolean,
};

class Auth extends React.PureComponent<PropsType, StateType> {
  state = {
    error: false,
    loading: true,
  };

  async componentDidMount(): Promise<void> {
    const { client, intl } = this.props;
    const code: ?string = getSearchParam('code');

    if (!code) {
      this.setState({
        loading: false,
        error: true,
      });

      return;
    }

    const response = await this.getToken(code);

    if (!response.errors) {
      client.mutate({
        mutation: CURRENT_USER_MUTATION,
        variables: {
          token: (response && response.data.token && response.data.token.value) || null,
        },
      });
      message.success(intl.formatMessage(translations.login));
    }

    this.setState({
      loading: false,
      error: !!response.errors,
    });
  }

  getToken = (code: string): Promise<ApolloCurrentResult<{ token: TokenType }>> => {
    const { client } = this.props;

    return client.query({
      query: TOKEN_QUERY,
      variables: {
        code,
      },
      fetchPolicy: 'network-only',
    });
  };

  render(): React.Node {
    const { error, loading } = this.state;

    return (
      <Wrapper>
        {error && (
          <StyledAlert
            message={<FormattedMessage id="error.login" defaultMessage="Błąd logowania" />}
            description={(
              <FormattedMessage
                id="error.login.description"
                defaultMessage="Wystąpił błąd podczas logowania, zaloguj się ponownie."
              />
)}
            type="error"
            showIcon
          />
        )}

        {!error && <LoadingSpin tip="Logowanie..." />}

        {!loading && !error && <Navigate to="/" />}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledAlert = styled(Alert)`
  max-width: 600px;
  .ant-alert-message {
    font-size: 24px !important;
  }
`;

export default (withApollo(injectIntl(Auth)): React.ComponentType<mixed>);
