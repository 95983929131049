// @flow
import gql from 'graphql-tag';

export default (gql`
  query GetSitesListColumns {
    sitesListColumns {
      selectedColumns @client
      orderedColumns @client
      columnSortOrder @client
      __typename
    }
  }
`: any);
