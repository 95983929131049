// @flow
import * as React from 'react';
import {
  FormattedMessage, injectIntl, defineMessages,
} from 'react-intl';
import {
  Modal, Button, Select, message,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled, { css } from 'styled-components';
import ReactGA from 'react-ga';
import store from 'store';
import { accessibility } from '../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize } from '../../lib/style/themes';

const { Option } = Select;
const FormItem = Form.Item;

type PropTypes = {
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    validateFields: Function,
  },
  intl: any,
};

type StateType = {
  modalVisible: boolean,
};

type QuestionnaireType = {
  answers: string[],
};

type AnswerTranslationType = {
  answer1: string,
  answer2: string,
  answer3: string,
  answer4: string,
};

type GAType = {
  dimension1: string | null,
  dimension2: string | null,
  dimension3: string | null,
  dimension4: string | null,
};

class QuestionnaireModal extends React.Component<PropTypes, StateType> {
  state = {
    modalVisible: false,
  };

  componentDidMount() {
    const questionnaireSent = store.get('questionnaireSent');

    if (!questionnaireSent) {
      setTimeout(this.showQuestionnaireModal, 120000);
    }
  }

  showQuestionnaireModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  hideQuestionnaireModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  parseAnswersforGA = (answers: ?(string[])): GAType => {
    const dimensions = {
      dimension1: 'nie wybrano',
      dimension2: 'nie wybrano',
      dimension3: 'nie wybrano',
      dimension4: 'nie wybrano',
    };

    if (!answers) {
      return dimensions;
    }

    const {
      answer1, answer2, answer3, answer4,
    } = this.getAnswerTranslation();

    // $FlowExpectedError[incompatible-use]
    for (let i = 0; i <= answers.length; i += 1) {
      // $FlowExpectedError[incompatible-use]
      switch (answers[i]) {
        case answer1:
          dimensions.dimension1 = answer1;
          break;
        case answer2:
          dimensions.dimension2 = answer2;
          break;
        case answer3:
          dimensions.dimension3 = answer3;
          break;
        case answer4:
          dimensions.dimension4 = answer4;
          break;
        default:
          break;
      }
    }

    return dimensions;
  };

  getAnswerTranslation = (): AnswerTranslationType => {
    const { intl } = this.props;
    const answer1 = intl.formatMessage(QuestionnaireModal.translation.answer1);
    const answer2 = intl.formatMessage(QuestionnaireModal.translation.answer2);
    const answer3 = intl.formatMessage(QuestionnaireModal.translation.answer3);
    const answer4 = intl.formatMessage(QuestionnaireModal.translation.answer4);

    return {
      answer1,
      answer2,
      answer3,
      answer4,
    };
  };

  sendQuestionnaireModal = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();

    const {
      form: { validateFields },
      intl,
    } = this.props;

    validateFields((errors: string, values: QuestionnaireType) => {
      const { answers } = values;

      const submitSuccessMessage = intl.formatMessage(QuestionnaireModal.translation.submitSuccessMessage);

      if (!errors) {
        const dimensions = this.parseAnswersforGA(answers);

        message.success(submitSuccessMessage);
        ReactGA.ga('send', 'pageview', { ...dimensions });
        store.set('questionnaireSent', true);
        this.hideQuestionnaireModal();
      }
    });
  };

  static translation = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Dziękujemy za wysłanie ankiety!',
      id: 'QuestionnaireModal.successSendForm',
    },
    answer1: {
      defaultMessage: 'Odpowiedź: 1',
      id: 'QuestionnaireModal.answer1',
    },
    answer2: {
      defaultMessage: 'Odpowiedź: 2',
      id: 'QuestionnaireModal.answer2',
    },
    answer3: {
      defaultMessage: 'Odpowiedź: 3',
      id: 'QuestionnaireModal.answer3',
    },
    answer4: {
      defaultMessage: 'Odpowiedź: 4',
      id: 'QuestionnaireModal.answer4',
    },
    placeholder: {
      defaultMessage: 'Proszę wybrać jeden lub więcej powodów',
      id: 'QuestionnaireModal.placeholder',
    },
    reason: {
      defaultMessage: 'Powód: ',
      id: 'QuestionnaireModal.reason',
    },
  });

  render(): React.Node {
    const { modalVisible } = this.state;
    const {
      intl,
      form: { getFieldDecorator },
    } = this.props;

    const {
      answer1, answer2, answer3, answer4,
    } = this.getAnswerTranslation();

    const placeholder = intl.formatMessage(QuestionnaireModal.translation.placeholder);
    const reason = intl.formatMessage(QuestionnaireModal.translation.reason);

    return (
      <StyledModal
        title={(
          <StyledTitle tabIndex="0">
            <FormattedMessage id="QuestionnaireModal.title" defaultMessage="W jakim celu korzystasz z platformy?" />
          </StyledTitle>
)}
        open={modalVisible}
        onCancel={this.hideQuestionnaireModal}
        footer={(
          <FormItem>
            <Button
              key="submit"
              type="primary"
              onClick={this.sendQuestionnaireModal}
              onKeyPress={this.sendQuestionnaireModal}
            >
              <FormattedMessage id="general.send" defaultMessage="Wyślij" />
            </Button>
          </FormItem>
)}
      >
        <StyledForm onSubmit={this.sendQuestionnaireModal}>
          <FormItem label={reason}>
            {getFieldDecorator('answers', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="QuestionnaireModal.errorMessage"
                      defaultMessage="Proszę wybrać choć jedną odpowiedź"
                    />
                  ),
                },
              ],
            })(
              <Select mode="multiple" style={{ width: '100%' }} placeholder={placeholder}>
                <Option key={answer1}>{answer1}</Option>
                <Option key={answer2}>{answer2}</Option>
                <Option key={answer3}>{answer3}</Option>
                <Option key={answer4}>{answer4}</Option>
              </Select>,
            )}
          </FormItem>
        </StyledForm>
      </StyledModal>
    );
  }
}

const StyledModal = styled(Modal)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-modal-content {
        border: 4px solid white;
      }

      .ant-modal-body,
      .ant-modal-header,
      .ant-modal-footer {
        background-color: ${darkBlueColor} !important;
      }

      .ant-btn,
      .ant-btn-primary {
        color: black;
        background-color: yellow;
        border: 2px solid black;
        font-size: ${biggerFontSize};
      }

      .ant-btn:focus,
      .ant-btn-primary:focus {
        color: white;
        background-color: black;
        border: 2px solid yellow;
      }

      .ant-modal-close-icon {
        font-size: 20px;
        color: yellow;
      }
    `};
`;

const StyledForm = styled(FormItem)`
  margin-bottom: 100px !important;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor} !important;

      .ant-input,
      .ant-select-selection {
        height: 46px !important;
        font-size: ${biggerFontSize};
        color: black;
        background-color: white !important;
        border: 2px solid yellow;
      }

      .ant-input:focus,
      .ant-select-focused .ant-select-selection {
        border-color: red !important;
        box-shadow: 0 0 0 2px red !important;
      }

      .ant-legacy-form-item-label label {
        color: yellow;
        font-size: ${biggerFontSize};
      }
    `};
`;

const StyledTitle = styled.span`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      font-size: ${biggerFontSize};
      color: yellow;
      &:focus {
        border: 2px solid red;
      }
    `};
`;

export default (injectIntl(Form.create()(QuestionnaireModal)): any);
