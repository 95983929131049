// @flow
import gql from 'graphql-tag';

export default (gql`
  query AllSites(
    $city: Boolean = false
    $community: Boolean = false
    $county: Boolean = false
    $created_at: Boolean = false
    $created_by_email: Boolean = false
    $filter: SiteFilter
    $local_name: Boolean = false
    $order: String
    $ordinal_number_in_city: Boolean = false
    $paginator: Paginator
    $physicalgeographical_unit: Boolean = false
    $published_at: Boolean = false
    $status: Boolean = false
    $updated_at: Boolean = false
    $voivodeship: Boolean = false
  ) {
    allSites(order: $order, paginator: $paginator, filter: $filter) {
      total
      data {
        id
        local_name @include(if: $local_name)
        status @include(if: $status)
        updated_at @include(if: $updated_at)
        published_at @include(if: $published_at)
        created_at @include(if: $created_at)
        created_by_email @include(if: $created_by_email)
        physicalgeographical_unit @include(if: $physicalgeographical_unit) {
          id
          value_pl
          value_en
          value_de
        }
        voivodeship @include(if: $voivodeship) {
          id
          value_pl
          value_en
          value_de
        }
        county @include(if: $county) {
          id
          value_pl
          value_en
          value_de
        }
        community @include(if: $community) {
          id
          value_pl
          value_en
          value_de
        }
        city @include(if: $city) {
          id
          value_pl
          value_en
          value_de
        }
        azp {
          id
          value_pl
          value_en
          value_de
        }
        ordinal_number_in_azp
        ordinal_number_in_city @include(if: $ordinal_number_in_city)
        keza_author
        keza_created_at
      }
    }
  }
`: any);
