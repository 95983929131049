// @flow
import * as React from 'react';
import { combine, parseShp } from 'shpjs';
import { message, Progress } from 'antd';
import { Icon } from '@ant-design/compatible';
import styled from 'styled-components';
import { defineMessages, injectIntl } from 'react-intl';
import type { GeoJsonType } from '../../../lib/types';
import { biggerFontSize } from '../../../lib/style/themes';
import type { filesGroupType } from './ShapefileUploaderUtils';
import { createFilesGroup } from './ShapefileUploaderUtils';

type PropsType = {
  intl: any,
  onLoad: GeoJsonType => void,
};

type StateType = {
  progress: number,
};

class ShapefileUploader extends React.PureComponent<PropsType, StateType> {
  state = {
    progress: 0,
  };

  fakeProgress: any;

  componentWillUnmount() {
    clearInterval(this.fakeProgress);
  }

  handleFile = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const input = event.target;

    let filesGroup;
    try {
      filesGroup = createFilesGroup(event.target.files);
      input.value = '';
    } catch (error) {
      clearInterval(this.fakeProgress);
      this.setState({ progress: 0 });
      const uploadErrorMessage = formatMessage(ShapefileUploader.translations.uploadError);
      message.error(uploadErrorMessage);

      return;
    }

    this.fakeProgress = setInterval(() => {
      this.setState((prevState: StateType): StateType => ({ progress: prevState.progress + 10 }));
    }, 100);

    const promisesGroups = [];

    Object.entries(filesGroup).forEach((obj: Object) => {
      const promises = [];
      ['prj', 'shp'].forEach((key: string) => {
        const file = obj[1][key];

        const filePromise = this.createFileReaderPromise(file, key);
        promises.push(filePromise);
      });
      promisesGroups.push(promises);
    });

    promisesGroups.forEach((promisesGroup: Array<Object>) => {
      Promise.all(promisesGroup).then((fileContents: Array<Object>) => {
        this.setState({ progress: 0 });
        clearInterval(this.fakeProgress);
        this.toGeoJson({
          prj: fileContents[0],
          shp: fileContents[1],
        });
      });
    });
  };

  createFileReaderPromise = (file: Object, type: string): Promise<void> => {
    return new Promise((resolve: Function) => {
      const reader = new FileReader();
      if (type === 'prj') {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
      reader.onprogress = (data: Object) => {
        if (data.lengthComputable) {
          const progress = parseInt((data.loaded / data.total) * 100, 10);
          clearInterval(this.fakeProgress);
          this.setState({ progress });
        }
      };
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  toGeoJson = (files: filesGroupType) => {
    const { onLoad } = this.props;

    const geojson = combine([parseShp(files.shp, files.prj), []]);
    onLoad(geojson);
  };

  static translations = defineMessages({
    uploadError: {
      id: 'ShapefileUploader.uploadError',
      defaultMessage: 'Błąd! Wczytaj pliki z rozszerzeniami .shp .prj',
    },
  });

  render(): React.Node {
    const { progress } = this.state;

    return (
      <Container>
        {progress > 0 && <Progress type="circle" percent={progress} size="small" />}
        {progress === 0 && (
          <UploadLabel htmlFor="file" title="Upload .shp and .prj files">
            <Icon type="upload" />
          </UploadLabel>
        )}
        <input
          id="file"
          type="file"
          onChange={this.handleFile}
          className="inputfile"
          multiple
          accept=".shp,.prj"
        />
      </Container>
    );
  }
}

const Container = styled.div`
  border: 1px solid #d9d9d9;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  input {
    display: none !important;
  }
`;

const UploadLabel = styled.label`
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  font-size: ${biggerFontSize} !important;
  cursor: pointer;

  &:hover {
    background: #f4f4f4;
  }
`;

export default (injectIntl(ShapefileUploader): any);
