// @flow
import { MapControl } from 'react-leaflet';
import Leaflet from 'leaflet';
import 'leaflet.coordinates/dist/Leaflet.Coordinates-0.1.5.min';
import xMarkIcon from '../../lib/images/xmark.svg';

class Coordinates extends MapControl {
  // eslint-disable-next-line
  createLeafletElement(props: Object): any {
    const image = new Leaflet.Icon({
      iconUrl: xMarkIcon,
      iconSize: [20, 20],
    });

    return Leaflet.control.coordinates({
      ...props,
      markerType: (
        lnglat: typeof Leaflet.GeometryUtil,
      ): typeof Leaflet.Marker => Leaflet.marker(lnglat, { icon: image }),
      useLatLngOrder: true,
      decimals: 6,
    });
  }

  componentDidMount() {
    const { map } = this.context;
    this.leafletElement.addTo(map);
  }
}

export default Coordinates;
