// @flow
import type { ResearchType, GroupedResearchType, DictionaryEntryType } from '../../types';

const groupedResearches = (
  researches: ResearchType[],
  dictionariesData: DictionaryEntryType[],
): GroupedResearchType[] => {
  const parsedResearchData = [];

  // if research data array exist - parse array with data for SiteDiscoveriesResearchField
  if (dictionariesData && researches) {
    for (let i = 0; i < dictionariesData.length; i += 1) {
      const dictionaryId = dictionariesData[i].id;
      const filteredData = researches.filter(
        (research: ResearchType): boolean => {
          if (research.research_type_id) {
            return research.research_type_id === dictionaryId;
          }

          return research.research_type.id === dictionaryId;
        },
      );
      const dictionary = dictionariesData[i];
      const newData = { ...dictionary, data: filteredData };

      parsedResearchData.push(newData);
    }
  }

  // $FlowFixMe[incompatible-return]
  return parsedResearchData;
};

export default groupedResearches;
