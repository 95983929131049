// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Tabs, Table, Drawer, Badge, Row,
} from 'antd';
import { withApollo } from 'react-apollo';
import styled, { css, withTheme } from 'styled-components';
// Queries
import ALL_DICTIONARY_ENTRIES_QUERY from '../../../../../lib/queries/allDictionaryEntries';
// Own types
import type { ResearchType, GroupedResearchType, TabIndexType } from '../../../../../lib/types';
// Component
import Buttons from './Buttons';
import ResearchDetail from './ResearchDetail';
import groupResearches from '../../../../../lib/utils/groupResearches';

import { accessibility } from '../../../../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize, generalFontSize } from '../../../../../lib/style/themes';

import dictionariesId from '../../../../../lib/constants/dictionariesId';
import { blockTabbingBackwards } from '../../../../../lib/utils/blockTabbing/blockTabbing';

const { TabPane } = Tabs;

type StateTypes = {
  groupedResearches: GroupedResearchType[],
  researchData: ?ResearchType,
  visibleDrawer: boolean,
};

type PropTypes = {
  client: any,
  intl: any,
  researches: ResearchType[],
  theme: any,
};

const makeTabsWithBadge = (title: string, numberOfResearches: number): React.Node => {
  return (
    <span>
      {title} <StyledBadge count={numberOfResearches} style={{ backgroundColor: '#1890ff' }} />
    </span>
  );
};

class SitesDetailsDiscoveriesResearches extends React.Component<PropTypes, StateTypes> {
  state = {
    researchData: null,
    groupedResearches: [],
    visibleDrawer: false,
  };

  async componentDidMount(): Promise<void> {
    const { client, researches } = this.props;
    const { reasearchDictionaryId } = dictionariesId;

    const researchesDictionary = await client.query({
      query: ALL_DICTIONARY_ENTRIES_QUERY,
      variables: { filter: { dictionaryId: reasearchDictionaryId } },
      fetchPolicy: 'cache-first',
    });

    const dictionariesData = researchesDictionary.data.allDictionaryEntries.data;

    const groupedResearches = groupResearches(researches, dictionariesData);

    this.setState({
      groupedResearches,
    });
  }

  handleCloseDrawer = () => {
    this.setState({
      visibleDrawer: false,
    });
  };

  showDrawer = (record: ResearchType) => {
    const {
      theme: { mode },
    } = this.props;

    this.setState(
      {
        visibleDrawer: true,
        researchData: record,
      },
      () => {
        if (mode === accessibility) {
          const columnsListDrawer = document.querySelector('.site-details-drawer .ant-drawer-content-wrapper');
          if (columnsListDrawer) {
            columnsListDrawer.setAttribute('tabindex', '0');
            columnsListDrawer.focus();
          }
        }
      },
    );
  };

  render(): React.Node {
    const { groupedResearches, visibleDrawer, researchData } = this.state;
    const { researches } = this.props;

    if (researches.length === 0) {
      return (
        <Row type="flex" justify="center">
          <FormattedMessage id="SitesDetailsDiscoveriesResearches.noResearches" defaultMessage="Brak badań" />
        </Row>
      );
    }

    const columns = [
      {
        title: <FormattedMessage id="SitesDetailsDiscoveriesResearches.year" defaultMessage="Rok" />,
        key: 'year',
        dataIndex: 'year',
        sorter: (item: ResearchType, nextItem: ResearchType): number => item.year - nextItem.year,
        onCell: (): TabIndexType => ({ tabIndex: '0' }),
        onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
      },
      {
        title: (
          <FormattedMessage id="SitesDetailsDiscoveriesResearches.researchSupervisor" defaultMessage="Kierownik" />
        ),
        key: 'research_supervisor',
        dataIndex: 'research_supervisor',
        sorter: (item: ResearchType, nextItem: ResearchType): number | boolean => {
          if (item.research_supervisor) return item.research_supervisor.localeCompare(nextItem.research_supervisor);

          return false;
        },
        onCell: (): TabIndexType => ({ tabIndex: '0' }),
        onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
      },
      {
        title: <FormattedMessage id="SitesDetailsDiscoveriesResearches.institution" defaultMessage="Instytucja" />,
        key: 'institution',
        dataIndex: 'institution',
        sorter: (item: ResearchType, nextItem: ResearchType): number | boolean => {
          if (item.institution) return item.institution.localeCompare(nextItem.institution);

          return false;
        },
        onCell: (): TabIndexType => ({ tabIndex: '0' }),
        onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
      },
      {
        title: <FormattedMessage id="SitesDetailsDiscoveriesResearches.buttons" defaultMessage="Przyciski" />,
        render: (rowText: string, record: ResearchType): React.Node => (
          <Buttons record={record} showDrawer={this.showDrawer} />
        ),
        onCell: (): TabIndexType => ({ tabIndex: '0' }),
        onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
      },
    ];

    const parsedResearch = groupedResearches.map(
      (groupedResearch: GroupedResearchType): React.Node => {
        const { intl: { locale } } = this.props;
        const { data: groupedResearchData } = groupedResearch;
        const numberOfResearches = groupedResearchData.length;

        // Adds "key" for ant designe Table dataSource
        const parsedTableData = groupedResearchData.map(
          (research: ResearchType): ResearchType => {
            return { ...research, key: research.id };
          },
        );

        if (numberOfResearches === 0) {
          return null;
        }

        return (
          <TabPane
            tab={makeTabsWithBadge(groupedResearch[`value_${locale}`], numberOfResearches)}
            key={groupedResearch.id}
            tabIndex="0"
          >
            <StyledTable columns={columns} dataSource={parsedTableData} scroll={{ x: 900 }} />
            <StyledDrawer
              title={(
                <span onKeyDown={blockTabbingBackwards} tabIndex="0" role="textbox">
                  <FormattedMessage
                    id="SitesDetailsDiscoveriesResearches.drawerTitle"
                    defaultMessage="Szczegóły Badania"
                  />
                </span>
)}
              className="site-details-drawer"
              destroyOnClose
              onClose={this.handleCloseDrawer}
              placement="right"
              width="50%"
              open={visibleDrawer}
              zIndex={1002}
              tabIndex="0"
            >
              <ResearchDetail researchData={researchData} handleCloseDrawer={this.handleCloseDrawer} />
            </StyledDrawer>
          </TabPane>
        );
      },
    );

    return (
      <StyledTabs type="card" tabIndex="0">
        {parsedResearch}
      </StyledTabs>
    );
  }
}

const StyledTable = styled(Table)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-pagination-item-link,
      .ant-pagination-item {
        color: yellow !important;
        font-weight: 600;
        background-color: ${darkBlueColor} !important;
        font-size: ${generalFontSize};
      }
    `};
`;

const StyledTabs = styled(Tabs)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-tabs-nav-container {
        height: 60px !important;
      }

      .ant-tabs-tab {
        font-size: ${biggerFontSize} !important;
        padding: 10px 0;
        color: yellow;
        background-color: ${darkBlueColor} !important;
      }
    `};
`;

const StyledDrawer = styled(Drawer)`
  z-index: 1002 !important;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-drawer-wrapper-body {
        background-color: yellow;
      }

      .ant-drawer-header {
        background: ${darkBlueColor};
        border-bottom: 2px solid white;
      }

      .ant-drawer-title {
        color: yellow;
        font-size: ${biggerFontSize};
      }

      .ant-table {
        font-size: ${biggerFontSize};
      }

      .ant-checkbox-inner {
        width: 30px;
        height: 30px;
        border: 2px solid red;
      }
    `};
`;

const StyledBadge = styled(Badge)`
  padding: 0 0 4px 2px !important;
`;

export default (withApollo(withTheme(injectIntl(SitesDetailsDiscoveriesResearches))): React.ComponentType<mixed>);
