import gql from 'graphql-tag';

export default gql`
  mutation UpdateSite(
    $local_name: String!
    $geojson: Geometries
    $voivodeship_id: ID
    $county_id: ID
    $community_id: ID
    $city_id: ID
    $physicalgeographical_unit_id: ID
    $azp_id: ID
    $ordinal_number_in_azp: String
    $ordinal_number_in_city: String
    $conservator_notes: String
    $id: ID!
    $monuments_register_number: String
    $culture_park: Boolean
    $monuments_register_addition_date: Date
    $zoning_plan: Boolean
    $hazards: String
    $conservator_detailed_inventory: Boolean
    $conservator_excavation_research: Boolean
    $conservator_administrative_inventory: Boolean
    $site_area: Int
    $keza_created_at: Date
    $keza_author: String
    $notes: [NoteInput]
    $discoveries: [DiscoveryInput]
  ) {
    updateSite(
      local_name: $local_name
      geojson: $geojson
      voivodeship_id: $voivodeship_id
      county_id: $county_id
      community_id: $community_id
      city_id: $city_id
      physicalgeographical_unit_id: $physicalgeographical_unit_id
      azp_id: $azp_id
      ordinal_number_in_azp: $ordinal_number_in_azp
      ordinal_number_in_city: $ordinal_number_in_city
      conservator_notes: $conservator_notes
      id: $id
      monuments_register_number: $monuments_register_number
      culture_park: $culture_park
      monuments_register_addition_date: $monuments_register_addition_date
      zoning_plan: $zoning_plan
      hazards: $hazards
      conservator_detailed_inventory: $conservator_detailed_inventory
      conservator_excavation_research: $conservator_excavation_research
      conservator_administrative_inventory: $conservator_administrative_inventory
      site_area: $site_area
      keza_created_at: $keza_created_at
      keza_author: $keza_author
      notes: $notes
      discoveries: $discoveries
    ) {
      id
      local_name
      status
      reject_reason
      geojson
      voivodeship {
        id
        value_pl
        value_en
        value_de
      }
      county {
        id
        value_pl
        value_en
        value_de
      }
      community {
        id
        value_pl
        value_en
        value_de
      }
      city {
        id
        value_pl
        value_en
        value_de
      }
      physicalgeographical_unit {
        id
        value_pl
        value_en
        value_de
      }
      azp {
        id
        value_pl
        value_en
        value_de
      }
      ordinal_number_in_azp
      ordinal_number_in_city
      conservator_notes
      monuments_register_number
      culture_park
      monuments_register_addition_date
      zoning_plan
      hazards
      conservator_detailed_inventory
      conservator_excavation_research
      conservator_administrative_inventory
      site_area
      keza_created_at
      keza_author
      notes {
        id
        created_by_email
        content
        created_at
      }
      discoveries {
        id
        discovery_function {
          id
          value_pl
          value_de
          value_en
          dictionary_id
        }
        archaeological_culture {
          id
          value_pl
          value_de
          value_en
          dictionary_id
        }
        chronology {
          id
          value_pl
          value_de
          value_en
          dictionary_id
        }
        researches {
          id
          research_type {
            id
            value_pl
            value_de
            value_en
            dictionary_id
          }
          year
          research_supervisor
          institution
          description
          museum_collections {
            id
            storage_location {
              id
              value_pl
              value_de
              value_en
              dictionary_id
            }
            inventory_number
          }
          documentation
          literature
          other_information
        }
      }
    }
  }
`;
