// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Button as ButtonAnt } from 'antd';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
// Mutation
import REMOVE_DISCOVERY from '../../../lib/mutations/removeDiscovery';
// Components
import SiteDiscoveries from './SiteDiscoveries';
// Own Types
import type { DicoveryType } from '../../../lib/types';
// Utils
import addsDiscoveriesLocalIds from '../../../lib/utils/addsDiscoveriesLocalIds';

type PropsTypes = {
  client: any,
  discoveries: DicoveryType[],
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
};

type StatyTypes = {
  discoveriesData: DicoveryType[],
  lastdiscoveryLocalId: number,
};

class SiteDiscoveriesContainer extends React.Component<PropsTypes, StatyTypes> {
  state = {
    lastdiscoveryLocalId: -1,
    discoveriesData: [],
  };

  componentDidMount() {
    const { discoveries } = this.props;

    const { discoveriesData, lastLocalId } = addsDiscoveriesLocalIds(discoveries);

    this.setState({
      discoveriesData,
      lastdiscoveryLocalId: lastLocalId,
    });
  }

  handleRemoveDiscovery = async (discoveryLocalId: string): Promise<void> => {
    const { client, form } = this.props;

    const discoveriesForm = form.getFieldValue('discoveriesForm');

    const discoveryToRemove = discoveriesForm.find(
      (discovery: DicoveryType): boolean => discovery.localId === discoveryLocalId,
    );

    const filteredDiscoveries = discoveriesForm.filter(
      (discovery: DicoveryType): boolean => discovery.localId !== discoveryLocalId,
    );

    if (discoveryToRemove.id) {
      await client.mutate({
        mutation: REMOVE_DISCOVERY,
        variables: {
          id: discoveryToRemove.id,
        },
      });
    }

    form.setFieldsValue({
      discoveriesForm: filteredDiscoveries,
    });
  };

  handleAddDiscovery = () => {
    const { form } = this.props;
    const { lastdiscoveryLocalId } = this.state;

    const discoveriesForm = form.getFieldValue('discoveriesForm');

    const newDiscoveryLocalId = lastdiscoveryLocalId + 1;

    form.setFieldsValue({
      discoveriesForm: [...discoveriesForm, { localId: newDiscoveryLocalId, researches: [] }],
    });

    this.setState({ lastdiscoveryLocalId: newDiscoveryLocalId });
  };

  render(): React.Node {
    const { form } = this.props;
    const { discoveriesData } = this.state;

    form.getFieldDecorator('discoveriesForm', { initialValue: discoveriesData });
    const discoveriesForm = form.getFieldValue('discoveriesForm');

    const discoveriesFields = discoveriesForm.map(
      (discoveryData: DicoveryType, discoveryFormIndex: number): React.Node => {
        return (
          <Col lg={24} key={discoveryData.localId}>
            <SiteDiscoveries
              discoveryData={discoveryData}
              discoveryIndex={discoveryData.localId}
              discoveryFormIndex={discoveryFormIndex}
              form={form}
              handleAddDiscovery={this.handleAddDiscovery}
              handleRemoveDiscovery={this.handleRemoveDiscovery}
            />
          </Col>
        );
      },
    );

    return (
      <React.Fragment>
        {discoveriesForm && discoveriesFields}
        <Col lg={24}>
          <StyledButtonAnt type="primary" onClick={this.handleAddDiscovery}>
            <FormattedMessage id="SiteDiscoveriesContainer.addNewDiscovery" defaultMessage="Dodaj" />
          </StyledButtonAnt>
        </Col>
      </React.Fragment>
    );
  }
}

const StyledButtonAnt = styled(ButtonAnt)`
  margin-top: 15px;
  width: 100%;
`;

export default (withApollo(SiteDiscoveriesContainer): any);
