// @flow
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Query } from 'react-apollo';
import type { QueryRenderProps } from 'react-apollo';
import CURRENT_USER_QUERY from '../../lib/queries/CurrentUser';
import type { UserType } from '../../lib/types';
import LoadingSpin from '../LoadingSpin';

const PrivateRoute = (): React.Node => (
  /* https://github.com/apollographql/apollo-link-state/issues/236 */
  <Query query={CURRENT_USER_QUERY} fetchPolicy="cache-first">
    {({
      loading,
      error,
      data,
    }: QueryRenderProps<{ current_user: UserType }>): React.Node => {
      if (loading) return <LoadingSpin tip="Loading..." />;
      if (error) return 'Error {error}';

      if (data && data.current_user && data.current_user.name) {
        return <Outlet />;
      }

      return <Navigate to="/401" />;
    }}
  </Query>
);

export default PrivateRoute;
