// @flow
import type { DicoveryType, ResearchType, MuseumCollectionType } from '../../types';

let discoveryLocalId = -1;

type ReturnType = {
  discoveriesData: DicoveryType[],
  lastLocalId: number,
};

const discoveriesDataLocalId = (discoveries: DicoveryType[]): ReturnType => {
  const discoveriesData = discoveries.map(
    (discovery: DicoveryType): DicoveryType => {
      discoveryLocalId += 1;
      if (!discovery.researches) return { ...discovery, localId: discoveryLocalId };

      let researchLocalId = -1;
      const researches = discovery.researches.map(
        (research: ResearchType): ResearchType => {
          researchLocalId += 1;
          if (!research.museum_collections) return { ...research, localId: researchLocalId };

          let museumCollectionLocalId = -1;
          const museumCollections = research.museum_collections.map(
            (museumCollection: MuseumCollectionType): MuseumCollectionType => {
              museumCollectionLocalId += 1;

              return { ...museumCollection, localId: museumCollectionLocalId };
            },
          );

          return { ...research, museum_collections: museumCollections, localId: researchLocalId };
        },
      );

      return { ...discovery, researches, localId: discoveryLocalId };
    },
  );

  return { discoveriesData, lastLocalId: discoveryLocalId };
};

export default discoveriesDataLocalId;
