// @flow
import * as React from 'react';
import { DatePicker } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { defineMessages, injectIntl } from 'react-intl';
import moment from 'moment';
import formItemLayout from '../../lib/constants/formItemLayout';

type PropsType = {
  getFieldDecorator: Function,
  initialFieldValue: string,
  intl: any,
  isRequired: boolean,
  label: string,
  message: string,
  name: string,
  setFieldsValue: Function,
};

class DatePickerField extends React.PureComponent<PropsType, void> {
  handleOnChange = (date: {}, dateString: string) => {
    const { name, setFieldsValue } = this.props;

    setFieldsValue({
      [name]: dateString,
    });
  };

  static translations = defineMessages({
    placeholder: {
      defaultMessage: 'RRRR-MM-DD',
      id: 'DatePickerField.placeholder',
    },
  });

  render(): React.Node {
    const FormItem = Form.Item;
    const {
      label, name, getFieldDecorator, message, isRequired, intl, initialFieldValue,
    } = this.props;

    const rules = [
      {
        required: isRequired,
        message,
      },
    ];
    const datePickerPlaceholder = intl.formatMessage(DatePickerField.translations.placeholder);

    const initialData = initialFieldValue ? moment(initialFieldValue, 'YYYY-MM-DD') : null;

    return (
      <FormItem {...formItemLayout} label={label}>
        {getFieldDecorator(name, {
          initialValue: initialData,
          rules,
        })(
          <DatePicker
            name={name}
            onChange={this.handleOnChange}
            format="YYYY-MM-DD"
            placeholder={datePickerPlaceholder}
          />,
        )}
      </FormItem>
    );
  }
}

export default (injectIntl(DatePickerField): any);
