// @flow
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import initSentry from './config/sentry';

import Analytics from './components/Analytics';
import ConfiguredLanguageProviders from './components/ConfiguredLanguageProviders';
import ConfiguredThemeProvider from './components/ConfiguredThemeProvider';
import Layout from './components/Layout';
import client from './config/apollo';

initSentry();

(function render() {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <ApolloProvider client={client}>
      <ConfiguredLanguageProviders>
        <ConfiguredThemeProvider>
          <BrowserRouter>
            {process.env.REACT_APP_GA_ID && <Analytics />}
            <Layout />
          </BrowserRouter>
        </ConfiguredThemeProvider>
      </ConfiguredLanguageProviders>
    </ApolloProvider>,
  );
}());
