// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Drawer } from 'antd';
import { ProjectOutlined } from '@ant-design/icons';
import styled, { css, withTheme } from 'styled-components';
import DragSortingTable from './DragSortingTable';
import RenderButtonInTable from '../RenderButtonInTable';
import { accessibility } from '../../lib/constants/themeModes';
import { blockTabbingBackwards } from '../../lib/utils/blockTabbing/blockTabbing';
import { darkBlueColor, biggerFontSize } from '../../lib/style/themes';

type PropsType = {
  handleDragSortingSubmit: Function,
  orderedColumns: Array<string>,
  selectedColumns: Array<string>,
  theme: any,
};

type StateType = {
  visible: boolean,
};

export type HandleHideColumnsListType = () => void;

class ColumnsList extends React.Component<PropsType, StateType> {
  state = {
    visible: false,
  };

  dragSortingTableRef: React.ElementRef<typeof DragSortingTable>;

  showColumnsList = () => {
    const {
      theme: { mode },
    } = this.props;

    this.setState(
      {
        visible: true,
      },
      () => {
        if (mode === accessibility) {
          const columnsListDrawer = document.querySelector('.columns-list .ant-drawer-content-wrapper');
          if (columnsListDrawer) {
            columnsListDrawer.setAttribute('tabindex', '0');
            columnsListDrawer.focus();
          }
        }
      },
    );
  };

  handleHideColumnsList: HandleHideColumnsListType = () => {
    this.setState({ visible: false });
    const {
      theme: { mode },
    } = this.props;

    this.dragSortingTableRef.reset();

    if (mode === accessibility) {
      const editColumnsButton = document.querySelector('#edit-columns-button');
      if (editColumnsButton) {
        editColumnsButton.focus();
      }
    }
  };

  render(): React.Node {
    const { selectedColumns, orderedColumns, handleDragSortingSubmit } = this.props;

    const { visible } = this.state;

    return (
      <React.Fragment>
        <RenderButtonInTable>
          <StyledButtonEdit
            id="edit-columns-button"
            type="primary"
            icon={<ProjectOutlined />}
            onClick={this.showColumnsList}
            onKeyPress={this.showColumnsList}
          >
            <FormattedMessage id="ColumnsList.editColumns" defaultMessage="Edytuj kolumny" />
          </StyledButtonEdit>
        </RenderButtonInTable>
        <StyledDrawer
          open={visible}
          title={(
            <span onKeyDown={blockTabbingBackwards} tabIndex="0" role="textbox">
              <FormattedMessage id="ColumnsList.editColumns" defaultMessage="Edytuj kolumny" />
            </span>
)}
          width="400"
          onClose={this.handleHideColumnsList}
          zIndex={1002}
          tabIndex="0"
          aria-live="polite"
          className="columns-list"
        >
          <DragSortingTable
            handleHideColumnsList={this.handleHideColumnsList}
            selectedColumns={selectedColumns}
            orderedColumns={orderedColumns}
            onRef={(ref: React.Ref<typeof DragSortingTable>) => { this.dragSortingTableRef = ref; }}
            handleDragSortingSubmit={handleDragSortingSubmit}
          />
        </StyledDrawer>
      </React.Fragment>
    );
  }
}

const StyledDrawer = styled(Drawer)`
  z-index: 1002 !important;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-drawer-wrapper-body {
        background-color: yellow;
      }

      .ant-drawer-header {
        background: ${darkBlueColor};
        border-bottom: 2px solid white;
      }

      .ant-drawer-title {
        color: yellow;
        font-size: ${biggerFontSize};
      }

      .ant-table {
        font-size: ${biggerFontSize};
      }

      .ant-checkbox-inner {
        width: 30px;
        height: 30px;
        border: 2px solid red;
      }
    `};
`;

const StyledButtonEdit = styled(Button)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

export default (withTheme(ColumnsList): any);
