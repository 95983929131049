import gql from 'graphql-tag';

export default gql`
  mutation AddDictionaryEntry($value_pl: String!, $value_en: String, $value_de: String, $dictionary_id: ID!) {
    addDictionaryEntry(value_pl: $value_pl, value_en: $value_en, value_de: $value_de, dictionary_id: $dictionary_id) {
      value_pl
      value_en
      value_de
      dictionary_id
      id
    }
  }
`;
