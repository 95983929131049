// @flow
import * as React from 'react';
import deepMap from 'deep-map';
import { diff } from 'jsondiffpatch/dist/jsondiffpatch.umd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Drawer, Button } from 'antd';

import { format } from './formatters/siteChangeLogFormatter';
import { mapKeysDeep, translateValue, translateKey } from './ChnageLogDiffUtils';
import SimpleMap from '../SimpleMap/SimpleMap';

import './formatters/siteChangeLogFormatter.css';

type PropsType = {
  left: any,
  right: any,
};

type StateType = {
  showDrawer: boolean,
};

class ChangeLogDiff extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    showDrawer: false,
  };

  showDrawer: (() => void) = () => {
    this.setState({
      showDrawer: true,
    });
  };

  onClose: (() => void) = () => {
    this.setState({
      showDrawer: false,
    });
  };

  render(): React.Node {
    const { left, right } = this.props;
    const { showDrawer } = this.state;

    const { geojson: rightGjson, ...restRight } = right || {};
    const { geojson: leftGjson, ...restLeft } = left || {};

    const geojsonChanged = JSON.stringify(leftGjson) !== JSON.stringify(rightGjson);

    const delta = diff(restLeft, restRight);

    const translatedKeysDelta = mapKeysDeep(delta, (_, key: string): string => translateKey(key));
    const translatedKeysAndValuesDelta = deepMap(translatedKeysDelta, (value: string): string => translateValue(value));

    const html = format(translatedKeysAndValuesDelta);

    return (
      <React.Fragment>
        {geojsonChanged && (
          <React.Fragment>
            <GeoJsonChange>
              <FormattedMessage
                id="ChangeLogDiff.geojsonChanged"
                defaultMessage="Zaznaczony obszar na mapie uległ zmianie "
              />
              <Button onClick={this.showDrawer} type="primary">
                <FormattedMessage id="ChangeLogDiff.showDiff" defaultMessage="Pokaż zmiany" />
              </Button>
            </GeoJsonChange>
            <Drawer open={showDrawer} zIndex={1003} onClose={this.onClose} width={800} style={{ maxWidth: '100%' }}>
              <SimpleMapHeader style={{ backgroundColor: '#ffbbbb' }}>
                <FormattedMessage
                  id="ChangeLogDiff.oldMap"
                  defaultMessage="Obszar na mapie przed zmianą"
                />
              </SimpleMapHeader>
              <SimpleMap height="40vh" geojsonData={leftGjson} />

              <SimpleMapHeader style={{ backgroundColor: '#bbffbb' }}>
                <FormattedMessage
                  id="ChangeLogDiff.newMap"
                  defaultMessage="Obszar na mapie po zmianie"
                />
              </SimpleMapHeader>
              <SimpleMap height="40vh" geojsonData={rightGjson} />
            </Drawer>
          </React.Fragment>
        )}
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </React.Fragment>
    );
  }
}

const GeoJsonChange = styled.div`
  background: #fcfcfc;
  border: 1px solid #eee;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: -16px;
`;

const SimpleMapHeader = styled.div`
  padding: 10px;
  margin-top: 15px;
  font-weight: bold;
`;

export default ChangeLogDiff;
