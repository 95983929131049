// @flow
import * as React from 'react';
import { Card } from 'antd';
import { ifProp } from 'styled-tools';
import store from 'store';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';

import SitesTable from './SitesTable';
import SitesFilters from './SitesFilters';
import BreadCrumb from '../../../components/BreadCrumb';
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import { accessibility } from '../../../lib/constants/themeModes';
import type { SiteFilterUrlType } from '../../../lib/types';
import { darkBlueColor } from '../../../lib/style/themes';
import Breakpoints from '../../../lib/constants/breakpoints';
import withRouter from '../../../lib/utils/withRouter';

type PropsType = {
  filter: SiteFilterUrlType,
  location: {
    pathname: string,
  },
};

type StateType = {
  fullWidthTable: boolean,
};

class SitesList extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    fullWidthTable: store.get('fullWidthTable') || false,
  };

  toggleFullWidthTable: (() => void) = () => {
    const { fullWidthTable } = this.state;

    this.setState({
      fullWidthTable: !fullWidthTable,
    });
    store.set('fullWidthTable', !fullWidthTable);
  };

  render(): React.Node {
    const {
      filter,
      location: { pathname },
    } = this.props;
    const { fullWidthTable } = this.state;

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['sitesList']} />
        <StyledPageContainer $fullWidthTable={fullWidthTable}>
          <Card style={{ marginBottom: '20px' }}>
            <SitesFilters key={pathname} toggleFullWidthTable={this.toggleFullWidthTable} />
          </Card>
          <Card>
            <SitesTable key={pathname} filter={filter} />
          </Card>
        </StyledPageContainer>
        <StyledCard style={{ marginBottom: '20px' }}>
          <FormattedMessage
            id="SitesList.sitesListMobileMessage"
            defaultMessage="
            Widok tabelaryczny stanowisk archeologicznych jest dostępny dla urządzeń o większej rozdzielczości ekranu.
            Po zmianie orientacji urządzenia ten widok będzie dostępny"
          />
        </StyledCard>
      </React.Fragment>
    );
  }
}

const StyledCard = styled(Card)`
  display: none;

  @media (max-width: ${Breakpoints.mobile}) {
    display: block;
  }
`;

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
  transition: max-width 0.3s ease;
  max-width: ${ifProp(['$fullWidthTable'], '100%')};

  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-card-body {
        background-color: ${darkBlueColor};
      }
    `};
`;

export default (withRouter(SitesList): any);
