// @flow
import type { LngLatType, MapElementType, NestedArray } from '../../lib/types';

type GeoJsonGeometryType = {
  geometryCoordinates: NestedArray<number>,
};

function prepareCoordinatesForGeodesicArea({ geometryCoordinates }: GeoJsonGeometryType): LngLatType | LngLatType[] {
  // If Point
  let tempLat;
  let tempLng;
  if (typeof geometryCoordinates[0] === 'number') {
    tempLat = +geometryCoordinates[0].toFixed(6);
  }

  if (typeof geometryCoordinates[1] === 'number') {
    tempLng = +geometryCoordinates[1].toFixed(6);
  }

  if (tempLat && tempLng) {
    return {
      lat: tempLat,
      lng: tempLng,
    };
  }

  // If Polygon
  if (typeof geometryCoordinates[0] === 'object') {
    return geometryCoordinates[0].map(
      (geometryCoordinate: any): LngLatType => ({
        lat: +geometryCoordinate[1].toFixed(6),
        lng: +geometryCoordinate[0].toFixed(6),
      }),
    );
  }

  return [];
}

function countArea(mapElements: MapElementType[]): number {
  if (!mapElements) return 0;

  let area = mapElements.reduce(
    (previousValue: number, currentValue: MapElementType): number => previousValue + currentValue.area,
    1,
  );

  area = Math.round(area);

  // No idea why after clear we still get 1m2, tiny hack
  area = area === 1 ? 0 : area;

  return area;
}

function coordinatesToString(mapElements: MapElementType[]): ?string {
  if (!mapElements) return null;

  const displayedCoordinates = mapElements.map(
    ({ coords, type }: { coords: any, type: 'Polygon' | 'Point' | 'LineString' }): string => {
      if (type === 'Point') {
        const { lng, lat } = coords;

        return `${type}\n${Number(lng).toFixed(6)} ${Number(lat).toFixed(6)}`;
      }

      const coordinate = coords.map(
        (element: LngLatType): string => `${Number(element.lat).toFixed(6)} ${Number(element.lng).toFixed(6)}`,
      );

      return `${type}\n${coordinate.join('\n')}`;
    },
  );

  return displayedCoordinates.join('\n\n');
}

export { prepareCoordinatesForGeodesicArea, countArea, coordinatesToString };
