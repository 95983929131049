// @flow
import * as React from 'react';
import { Table } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';

// Componnents
import SitesDetailsDiscoveriesReasearches from './SitesDetailsDiscoveriesReasearches';
// Own types
import type { DicoveryType, TabIndexType } from '../../../../lib/types';

import { accessibility } from '../../../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize, generalFontSize } from '../../../../lib/style/themes';

const SitesDetailsDiscoveries = (props: { discoveries: DicoveryType[], intl: any }): React.Node => {
  const {
    discoveries,
    intl: { locale },
  } = props;

  const columns = [
    {
      title: <FormattedMessage id="SitesDetailsDiscoveries.discoveryFunction" defaultMessage="Funkcja" />,
      dataIndex: `discovery_function.value_${locale}`,
      key: 'discovery_function',
      sorter: (item: DicoveryType, nextItem: DicoveryType): number | boolean => {
        if (item.discovery_function && !nextItem.discovery_function) return 1;
        if (!item.discovery_function && nextItem.discovery_function) return -1;
        if (item.discovery_function && nextItem.discovery_function) {
          return item.discovery_function[`value_${locale}`].localeCompare(
            nextItem.discovery_function[`value_${locale}`],
          );
        }

        return false;
      },
      onCell: (): TabIndexType => ({ tabIndex: '0' }),
      onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
    },
    {
      title: <FormattedMessage id="SitesDetailsDiscoveries.archaeologicalCulture" defaultMessage="Kultura" />,
      dataIndex: `archaeological_culture.value_${locale}`,
      key: 'archaeological_culture',
      sorter: (item: DicoveryType, nextItem: DicoveryType): number | boolean => {
        if (item.archaeological_culture && !nextItem.archaeological_culture) return 1;
        if (!item.archaeological_culture && nextItem.archaeological_culture) return -1;
        if (item.archaeological_culture && nextItem.archaeological_culture) {
          return item.archaeological_culture[`value_${locale}`].localeCompare(
            nextItem.archaeological_culture[`value_${locale}`],
          );
        }

        return false;
      },
      onCell: (): TabIndexType => ({ tabIndex: '0' }),
      onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
    },
    {
      title: <FormattedMessage id="SitesDetailsDiscoveries.chronology" defaultMessage="Chronologia" />,
      dataIndex: `chronology.value_${locale}`,
      key: 'chronology',
      sorter: (item: DicoveryType, nextItem: DicoveryType): number | boolean => {
        if (item.chronology && !nextItem.chronology) return 1;
        if (!item.chronology && nextItem.chronology) return -1;
        if (item.chronology && nextItem.chronology) {
          return item.chronology[`value_${locale}`].localeCompare(nextItem.chronology[`value_${locale}`]);
        }

        return false;
      },
      onCell: (): TabIndexType => ({ tabIndex: '0' }),
      onHeaderCell: (): TabIndexType => ({ tabIndex: '0' }),
    },
  ];

  // Adds "key" for ant designe Table dataSource
  const parsedTableData = discoveries.map(
    (discovery: DicoveryType): DicoveryType => {
      return { ...discovery, key: discovery.id };
    },
  );

  return (
    <StyledTable
      columns={columns}
      scroll={{ x: 900 }}
      expandedRowRender={(record: DicoveryType): React.Node => (
        <SitesDetailsDiscoveriesReasearches researches={record.researches} />
      )}
      dataSource={parsedTableData}
    />
  );
};

const StyledTable = styled(Table)`
  .ant-table-expanded-row:hover > td {
    background: #fbfbfb;
  }

  .ant-table th {
    white-space: nowrap;
    .ant-table-column-sorters {
      margin-right: 10px;
    }
  }

  td: focus, th: focus {
    outline: none;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-table-row-expand-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        border: 2px solid ${darkBlueColor};
        color: ${darkBlueColor};
        background-color: yellow;
        font-size: ${biggerFontSize};
      }
      .ant-pagination-item-link,
      .ant-pagination-item {
        color: ${darkBlueColor} !important;
        font-weight: 600;
        background-color: yellow !important;
        font-size: ${generalFontSize};
      }
      .ant-table-thead > tr > th {
        background: yellow;
        color: ${darkBlueColor};
        &:focus {
          border: 4px solid red !important;
        }
      }
      td,
      th {
        border: 2px solid black;
        font-size: ${biggerFontSize};
        color: black;
        background: white;
        &:focus {
          border: 4px solid red !important;
        }
      }
      ::selection {
        background: yellow;
        color: black;
      }

      .ant-table-tbody > tr > td.ant-table-column-sort {
        background: white;
      }

      .ant-table-placeholder {
        border: 2px solid black;
        font-size: ${biggerFontSize};
        color: black;
        background: white;
      }
    `};
`;

export default (injectIntl(SitesDetailsDiscoveries): any);
