// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import type { QueryRenderProps } from 'react-apollo';
import SitesChangeLogTable from './SitesChangeLogTable';
import type { SiteChangesHeaderType, SiteFilterUrlType } from '../../../../lib/types';

import ALL_SITE_CHANGES_QUERY from '../../../../lib/queries/allSiteChanges';
import NoDataBecauseError from '../../../../components/NoDataBecauseError';
import withRouter from '../../../../lib/utils/withRouter';

type PropsType = {
  filter?: SiteFilterUrlType,
  params: {
    siteId: string,
  },
};

type StateType = {};

class SitesChangeLogTableContainer extends React.PureComponent<PropsType, StateType> {
  static defaultProps = {
    filter: {},
  };

  state = {};

  getFilter = (): Object => {
    const {
      params: { siteId },
      filter,
    } = this.props;

    return { ...filter, siteId };
  };

  render(): React.Node {
    const filter = this.getFilter();

    return (
      <Query query={ALL_SITE_CHANGES_QUERY} variables={{ filter }}>
        {({
          loading,
          error,
          data,
        }: QueryRenderProps<{ allSiteChanges: { data: SiteChangesHeaderType[] } }>): React.Node => {
          if (error) return <NoDataBecauseError />;

          const siteChangesHeaders = (data && data.allSiteChanges && data.allSiteChanges.data) || [];

          return <SitesChangeLogTable loading={loading} siteChangesHeaders={siteChangesHeaders} />;
        }}
      </Query>
    );
  }
}

export default (withRouter(SitesChangeLogTableContainer): any);
