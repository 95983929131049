import gql from 'graphql-tag';

export default gql`
  query siteValidation($ordinal_number_in_azp: String, $azp_id: ID, $ordinal_number_in_city: String, $city_id: ID) {
    siteValidation(
      ordinal_number_in_azp: $ordinal_number_in_azp
      azp_id: $azp_id
      ordinal_number_in_city: $ordinal_number_in_city
      city_id: $city_id
    )
  }
`;
