// @flow
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import queryString from 'query-string';
import type { Location } from 'react-router-dom';

import PrivateRoute from '../../components/PrivateRoute';
import ErrorPage from '../ErrorPage';
import SitesList from './SitesList';
import SitesMap from './SitesMap';
import SitesDetails from './SitesDetails';
import SitesDetailsPrint from './SitesDetailsPrint';
import SITE_STATUS from '../../lib/constants/siteStatus';
import withRouter from '../../lib/utils/withRouter';

type PropsType = {
  location: Location,
};

class Sites extends React.PureComponent<PropsType, void> {
  getFilterFromUrl: (() => ?any) = (): ?Object => {
    const {
      location: { search },
    } = this.props;

    const filter = this.addStatusPublishedFilterIfTableViewOrMap();

    return { ...filter, ...queryString.parse(search) };
  };

  addStatusPublishedFilterIfTableViewOrMap: (() => any) = (): Object => {
    const {
      location: { pathname },
    } = this.props;

    if (pathname === '/sites/list' || pathname === '/sites/map') {
      return {
        status: SITE_STATUS.published,
      };
    }

    return {};
  };

  render(): React.Node {
    const filter = this.getFilterFromUrl();

    return (
      <>
        <Routes>
          <Route path="list" element={<SitesList filter={filter} />} />
          <Route path="list" element={<PrivateRoute location="/" />}>
            <Route
              path="administration"
              element={<SitesList filter={filter} />}
            />
            <Route path="moderation" element={<SitesList filter={filter} />} />
            <Route path="my-work" element={<SitesList filter={filter} />} />
          </Route>
          <Route path="map" element={<SitesMap filter={filter} />} />
          <Route
            path=":azp_valuePl/:ordinalNumberInAzp"
            element={<SitesDetails />}
          />
          <Route path=":siteId" element={<SitesDetails />} />
          <Route path=":siteId/print" element={<SitesDetailsPrint />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </>
    );
  }
}

export default (withRouter(Sites): any);
