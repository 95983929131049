// @flow
import * as React from 'react';
import {
  Row, Col, Input, Button as ButtonAnt, Popconfirm, InputNumber, Tabs,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  FormattedMessage, injectIntl, defineMessages,
} from 'react-intl';
import styled from 'styled-components';
// Own Components
import InputTextareaField from '../../../../InputTextareaField.js';
import MuseumCollections from './MuseumCollections';
// Own types
import type { ResearchType } from '../../../../../lib/types';

const FormItem = Form.Item;
const { TabPane } = Tabs;

const translations = defineMessages({
  removeQuestion: {
    defaultMessage: 'Czy chcesz usunąć badanie?',
    id: 'SiteDiscoveriesResearchField.removeQuestion',
  },
  yes: {
    defaultMessage: 'Tak',
    id: 'general.yes',
  },
  no: {
    defaultMessage: 'Nie',
    id: 'general.no',
  },
});

type PropsTypes = {
  activeResearchKey: string,
  changeActiveResearchKey: (activeResearchKey: string) => void,
  discoveryFormIndex: number,
  discoveryIndex: number,
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    setFieldsValue: Function,
  },
  getResearchIndex: (research: ResearchType) => number,
  handleRemoveResearchField: (research: ResearchType) => Promise<void>,
  intl: any,
  name: string,
  researches: ResearchType[],
};

const SiteDiscoveriesResearchField = (props: PropsTypes): React.Node => {
  const {
    activeResearchKey,
    changeActiveResearchKey,
    researches,
    discoveryIndex,
    discoveryFormIndex,
    name,
    form: { getFieldDecorator, getFieldValue, setFieldsValue },
    form,
    handleRemoveResearchField,
    intl,
    getResearchIndex,
  } = props;

  const removeResearchButton = (research: ResearchType): React.Node => {
    return (
      <FormattedMessage id="general.remove" defaultMessage="Usuń badanie">
        {(): React.Node => (
          <Popconfirm
            title={intl.formatMessage(translations.removeQuestion)}
            onConfirm={(event: SyntheticEvent<HTMLButtonElement>): any => {
              event.stopPropagation();
              handleRemoveResearchField(research);
            }}
            okText={intl.formatMessage(translations.yes)}
            cancelText={intl.formatMessage(translations.no)}
          >
            <StyledButtonAnt
              shape="circle"
              icon="close"
              size="small"
              onClick={(event: SyntheticEvent<HTMLButtonElement>): void => event.stopPropagation()}
            />
          </Popconfirm>
        )}
      </FormattedMessage>
    );
  };

  const parsedResearch = researches.map(
    (research: ResearchType, parsedResearchIndex: number): React.Node => {
      const {
        year,
        research_supervisor: researchSupervisor,
        institution,
        description,
        documentation,
        literature,
        other_information: otherInformation,
        museum_collections: museumCollections,
        localId,
      } = research;

      const researchTypeId = research.research_type ? research.research_type.id : research.research_type_id;
      const researchFormIndex = getResearchIndex(research);

      return (
        <TabPane
          key={localId}
          tab={(
            <FormattedMessage id="SiteDiscoveriesResearchField.researchDetail" defaultMessage="Szczegóły badania">
              {(formatedText: string): React.Node => (
                <span>{formatedText}: {parsedResearchIndex + 1} {removeResearchButton(research)}</span>
              )}
            </FormattedMessage>
            )
          }
          forceRender
        >
          <Row type="flex" justify="space-between">
            {research.id && (
              <HiddenFormItem>
                {getFieldDecorator(`${name}[researches][${research.localId}][id]`, {
                  initialValue: research.id,
                })(<Input disabled />)}
              </HiddenFormItem>
            )}
            <HiddenFormItem>
              {getFieldDecorator(`${name}[researches][${research.localId}][localId]`, {
                initialValue: research.localId,
              })(<Input disabled />)}
            </HiddenFormItem>
            <Col lg={4}>
              <FormItem label={<FormattedMessage id="SiteDiscoveriesResearchField.year" defaultMessage="Rok" />}>
                {getFieldDecorator(`${name}[researches][${research.localId}][year]`, {
                  initialValue: year,
                })(<InputNumber min={1901} max={2155} />)}
              </FormItem>
            </Col>
            <Col lg={6}>
              <FormItem
                label={(
                  <FormattedMessage
                    id="SiteDiscoveriesResearchField.researchSupervisor"
                    defaultMessage="Kierownik badań"
                  />
  )}
              >
                {getFieldDecorator(`${name}[researches][${research.localId}][research_supervisor]`, {
                  initialValue: researchSupervisor,
                })(<Input name={`${name}[researches][${research.localId}][research_supervisor]`} />)}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem
                label={<FormattedMessage id="SiteDiscoveriesResearchField.institution" defaultMessage="Instytucja" />}
              >
                {getFieldDecorator(`${name}[researches][${research.localId}][institution]`, {
                  initialValue: institution,
                })(<Input name={`${name}[researches][${research.localId}][institution]`} />)}
              </FormItem>
            </Col>
            <HiddenFormItem
              label={(
                <FormattedMessage
                  id="SiteDiscoveriesResearchField.research_type_id"
                  defaultMessage="research_type_id"
                />
              )
              }
            >
              {getFieldDecorator(`${name}[researches][${research.localId}][research_type_id]`, {
                initialValue: researchTypeId,
              })(<Input name={`${name}[researches][${research.localId}][research_type_id]`} />)}
            </HiddenFormItem>
            <Col lg={24}>
              <InputTextareaField
                label={
                  <FormattedMessage id="SiteDiscoveriesResearchField.description" defaultMessage="Opis znaleziska" />
                }
                name={`${name}[researches][${research.localId}][description]`}
                getFieldDecorator={getFieldDecorator}
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                initialFieldValue={description}
                rowsNumber={3}
              />
            </Col>
            <Col lg={24}>
              <MuseumCollections
                discoveryIndex={discoveryIndex}
                discoveryFormIndex={discoveryFormIndex}
                researchIndex={research.localId}
                researchFormIndex={researchFormIndex}
                name={`${name}[researches][${research.localId}][museum_collections]`}
                museumCollections={museumCollections || []}
                form={form}
              />
            </Col>
            <Col lg={24}>
              <InputTextareaField
                label={
                  <FormattedMessage id="SiteDiscoveriesResearchField.documentation" defaultMessage="Dokumentacja" />
                }
                name={`${name}[researches][${research.localId}][documentation]`}
                getFieldDecorator={getFieldDecorator}
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                initialFieldValue={documentation}
                rowsNumber={3}
              />
            </Col>
            <Col lg={24}>
              <InputTextareaField
                label={<FormattedMessage id="SiteDiscoveriesResearchField.literature" defaultMessage="Literatura" />}
                name={`${name}[researches][${research.localId}][literature]`}
                getFieldDecorator={getFieldDecorator}
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                initialFieldValue={literature}
                rowsNumber={3}
              />
            </Col>
            <Col lg={24}>
              <InputTextareaField
                label={(
                  <FormattedMessage
                    id="SiteDiscoveriesResearchField.otherInformation"
                    defaultMessage="Inne Informacje"
                  />
                )}
                name={`${name}[researches][${research.localId}][other_information]`}
                getFieldDecorator={getFieldDecorator}
                getFieldValue={getFieldValue}
                setFieldsValue={setFieldsValue}
                initialFieldValue={otherInformation}
                rowsNumber={3}
              />
            </Col>
          </Row>
        </TabPane>
      );
    },
  );

  return (
    <Tabs
      type="card"
      activeKey={activeResearchKey || '0'}
      onTabClick={changeActiveResearchKey}
    >
      {parsedResearch}
    </Tabs>
  );
};

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

const StyledButtonAnt = styled(ButtonAnt)`
  margin-left: 5px;
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;

  i{
    margin-right: 0 !important;
  }
`;

export default (injectIntl(SiteDiscoveriesResearchField): any);
