// @flow
import { onError, ErrorResponse } from 'apollo-link-error';
import type { ApolloLink } from 'apollo-client';
import { notification } from 'antd';
import { defineMessages } from 'react-intl';
import { intl } from '../../components/IntlGlobalProvider';

const translations = defineMessages({
  unknown: {
    defaultMessage: 'Nieoczekiwany błąd serwera',
    id: 'error.unknown',
  },
  network: {
    defaultMessage: 'Problem w komunikacji z serwerem',
    id: 'error.network',
  },
  adminsKnow: {
    defaultMessage: 'Administratorzy systemu zostali już powiadomieni o zaistniałej sytuacji.',
    id: 'error.adminsKnow',
  },
  protectedItem: {
    defaultMessage: 'Wpis chroniony',
    id: 'error.protectedItem',
  },
  protectedInfo: {
    defaultMessage: 'Usunięcie tego wpisu zostało zablokowane.',
    id: 'error.protectedInfo',
  },
  linkedEntry: {
    defaultMessage: 'Wpis połączony',
    id: 'error.linkedEntry',
  },
  linkedInfo: {
    defaultMessage: 'Nie można usunąć wpisu, ponieważ jest używany.',
    id: 'error.linkedInfo',
  },
  resourceNotFound: {
    defaultMessage: 'Nie znaleziono',
    id: 'error.fileUploadInfo',
  },
  resourceNotFoundInfo: {
    defaultMessage: 'Żądany zasób nie został znaleziony.',
    id: 'error.fileUploadInfo',
  },
});

export default (onError(
  ({
    graphQLErrors, networkError, operation, forward,
  }: typeof ErrorResponse): ?ApolloLink => {
    if (graphQLErrors) {
      graphQLErrors.forEach(
        ({ message, validation }: { message: string, validation: Object }): ?ApolloLink => {
          if (validation) {
            Object.keys(validation).forEach(
              // eslint-disable-next-line
              (key: any): any => console.error(`[GraphQL error]: Validation: ${validation[key]}`)
            );

            return null;
          }
          // Log message
          // eslint-disable-next-line
          console.error(`[GraphQL error]: Message: ${message}`);

          switch (message) {
            case 'Cannot remove protected item':
              notification.error({
                duration: 10,
                message: intl.formatMessage(translations.protectedItem),
                description: intl.formatMessage(translations.protectedInfo),
              });
              break;
            case 'Cannot remove resource as it is used by other resources':
              notification.error({
                duration: 10,
                message: intl.formatMessage(translations.linkedEntry),
                description: intl.formatMessage(translations.linkedInfo),
              });
              break;
            case 'Unauthorized':
              window.history.pushState(null, '', '/401');
              break;
            case 'Resource not found':
              notification.error({
                duration: 10,
                message: intl.formatMessage(translations.resourceNotFound),
                description: intl.formatMessage(translations.resourceNotFoundInfo),
              });
              break;
            default:
              notification.error({
                duration: 10,
                message: intl.formatMessage(translations.unknown),
                description: intl.formatMessage(translations.adminsKnow),
              });
          }

          // retry request
          return forward(operation);
        },
      );
    }

    if (networkError) {
      // Log error in console
      // eslint-disable-next-line
      console.error(`[Network error]: ${String(networkError)}`);

      // Logout user and show 401 error page
      if (networkError && networkError.result && networkError.result.error.message === 'Unauthorized') {
        window.history.pushState(null, '', '/401');

        return;
      }

      // If it is 500, show 500 error page
      // https://github.com/apollographql/apollo-link/issues/300#issuecomment-387120102
      if (networkError.statusCode === 500 || undefined) {
        window.history.pushState(null, '', '/500');

        return;
      }

      // Show error about network problem
      notification.error({
        duration: 10,
        message: intl.formatMessage(translations.network),
        description: intl.formatMessage(translations.adminsKnow),
      });
    }
  },
): any);
