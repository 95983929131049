// @flow
import * as React from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
// Component
import SiteNoteFiled from '../SiteNoteFiled';
// Own Types
import type { NotesType } from '../../lib/types';

type propsType = {
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  handleRemoveNote: (noteIndex: number) => Promise<void>,
  notes: [NotesType],
};

const SiteNotes = (props: propsType): React.Node => {
  const { form, notes, handleRemoveNote } = props;

  const generateUniqueId = ((): (() => number) => {
    let id = 1;
    // eslint-disable-next-line
    return (): number => (id += 1);
  })();

  const notesField = notes.map(
    (note: NotesType, noteIndex: number): React.Node => {
      return (
        <React.Fragment key={generateUniqueId()}>
          <Col lg={12}>{note.created_by_email}</Col>
          <ColRight lg={12} className="createdAt">
            {note.created_at}
          </ColRight>
          <Col lg={24}>
            <SiteNoteFiled
              name={`notes[${noteIndex}]`}
              form={form}
              initialData={note}
              noteIndex={noteIndex}
              autoSize={{ minRows: 4, maxRows: 6 }}
              handleRemoveNote={handleRemoveNote}
            />
          </Col>
        </React.Fragment>
      );
    },
  );

  return notesField;
};

const ColRight = styled(Col)`
  text-align: right;
`;

export default SiteNotes;
