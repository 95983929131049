// @flow
import type { GeoJsonType, GeoJsonFeatureType } from '../../types';

export default function removePropertiesFromGeoJson(geojson: GeoJsonType): GeoJsonType | null {
  if (!geojson) {
    return null;
  }

  let { features } = geojson;

  if (features && features.length === 0) {
    return null; // dodane ze wzgledu na blad Cannot read property 'features' of null
  }

  if (!features) return geojson;

  features = features.map(
    (feature: GeoJsonFeatureType): GeoJsonFeatureType => ({ ...feature, properties: null }),
  );

  return { ...geojson, features };
}
