// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { Icon } from '@ant-design/compatible';
import ContentForLoggedInUsers from '../../../../components/ContentForLoggedInUsers/ContentForLoggedInUsers';

const SitesDetailsCtaSection = ({ siteId }: { siteId: string }): React.Node => {
  const path = window.location.pathname;

  return (
    <React.Fragment>
      <ContentForLoggedInUsers>
        <React.Fragment>
          <Link to={`/admin/sites/${siteId}/edit`} state={{ prevPath: path }}>
            <StyledButton type="primary">
              <Icon type="edit" />
              &nbsp;&nbsp;
              <FormattedMessage id="general.edit" defaultMessage="Edytuj" />
            </StyledButton>
          </Link>
          <Link to={`/admin/sites/${siteId}/change-log`}>
            <StyledButton>
              <Icon type="hourglass" />
              &nbsp;&nbsp;
              <FormattedMessage id="SitesDetailsCtaSection.historyOfChanges" defaultMessage="Historia zmian" />
            </StyledButton>
          </Link>
        </React.Fragment>
      </ContentForLoggedInUsers>

      <Link to={`/sites/${siteId}/print`} target="_blank">
        <StyledButton>
          <Icon type="printer" />
          &nbsp;&nbsp;
          <FormattedMessage id="SitesDetailsCtaSection.printView" defaultMessage="Drukuj" />
        </StyledButton>
      </Link>
    </React.Fragment>
  );
};

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

export default SitesDetailsCtaSection;
