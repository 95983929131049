// @flow
import * as React from 'react';
import { Button as ButtonAnt, Tabs, Badge } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
// Own Components
import SiteDiscoveriesResearchField from './SiteDiscoveriesResearchField';
import TranslatedValue from '../../../TranslatedValue/TranslatedValue';
// Own types
import type {
  ResearchType,
  GroupedResearchType,
  DictionaryEntryType,
  AllDictionaryEntries,
} from '../../../../lib/types';
// Queries
import ALL_DICTIONARY_ENTRIES_QUERY from '../../../../lib/queries/allDictionaryEntries';
// Mutations
import REMOVE_RESEARCH from '../../../../lib/mutations/removeResearch';

import groupResearches from '../../../../lib/utils/groupResearches';
import dictionariesId from '../../../../lib/constants/dictionariesId';

const { TabPane } = Tabs;

type PropsTypes = {
  client: any,
  discoveryFormIndex: number,
  discoveryIndex: number,
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  name: string,
  researches: ResearchType[],
};

type StateType = {
  activeResearchKey: ?string,
  dictionariesData: DictionaryEntryType[],
  lastResearchLocalId: number,
};

type DataAllDictionaryEntries = {
  // eslint-disable-next-line
  data: AllDictionaryEntries,
};

class SiteDiscoveriesReasearches extends React.Component<PropsTypes, StateType> {
  state = {
    activeResearchKey: null,
    dictionariesData: [],
    lastResearchLocalId: -1,
  };

  _isMounted: boolean;

  async componentDidMount(): Promise<void> {
    // set flag for component state (becouse of antd forceRender Panel)
    // https://stackoverflow.com/questions/49906437/how-to-cancel-a-fetch-on-componentwillunmount
    this._isMounted = true;

    const { client, researches } = this.props;
    const { reasearchDictionaryId } = dictionariesId;

    // Find and set lastResearchLocalId (used for add new researches element)
    if (researches && researches.length > 0) {
      const lastResearchLocalId = researches[researches.length - 1].localId;

      this.setState({
        lastResearchLocalId,
      });
    }

    const researchesDictionary = await client.watchQuery({
      query: ALL_DICTIONARY_ENTRIES_QUERY,
      variables: { filter: { dictionaryId: reasearchDictionaryId } },
    });

    researchesDictionary.subscribe({
      next: ({ data }: DataAllDictionaryEntries) => {
        const dictionariesData = data ? data.allDictionaryEntries.data : [];

        if (this._isMounted) {
          this.setState({
            dictionariesData,
          });
        }
      },
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAddResearchField = (researchTypeDataId: string) => {
    const { form, researches, discoveryFormIndex } = this.props;
    const { lastResearchLocalId } = this.state;

    const newResearchLocalId = lastResearchLocalId + 1;

    const discoveriesForm = form.getFieldValue('discoveriesForm');

    discoveriesForm[discoveryFormIndex].researches = [
      ...(researches || []),
      { localId: newResearchLocalId, research_type: { id: researchTypeDataId }, museum_collections: [] },
    ];

    form.setFieldsValue({
      discoveriesForm,
    });

    this.setState({
      lastResearchLocalId: newResearchLocalId,
      activeResearchKey: newResearchLocalId.toString(),
    });
  };

  handleRemoveResearchField = async (research: ResearchType): Promise<void> => {
    const {
      form, researches, client, discoveryFormIndex,
    } = this.props;

    const researchIndex = this.getResearchIndex(research);

    const discoveriesForm = form.getFieldValue('discoveriesForm');
    const researchToRemove = researches[researchIndex];
    const filteredResearches = researches.filter((item: ResearchType): boolean => item !== researchToRemove);

    discoveriesForm[discoveryFormIndex].researches = filteredResearches;

    if (research.id) {
      await client.mutate({
        mutation: REMOVE_RESEARCH,
        variables: {
          id: research.id,
        },
      });
    }

    form.setFieldsValue({
      discoveriesForm,
    });
  };

  getResearchIndex = (searchedResearch: ResearchType): number => {
    const { researches } = this.props;

    return researches.findIndex((research: ResearchType): boolean => research.localId === searchedResearch.localId);
  };

  changeActiveResearchKey = (activeResearchKey: string) => {
    this.setState({
      activeResearchKey,
    });
  };

  render(): React.Node {
    const {
      name, form, discoveryIndex, discoveryFormIndex, researches,
    } = this.props;
    const { dictionariesData, activeResearchKey } = this.state;

    // sorting research array in relative to the research_type_id
    const groupedResearches = groupResearches(researches, dictionariesData);

    const parsedResearch = groupedResearches.map(
      (groupedResearch: GroupedResearchType): React.Node => {
        const researchTypeDataId = groupedResearch.id;

        const { data: groupedResearchData } = groupedResearch;
        const numberOfResearches = groupedResearchData.length;

        return (
          <TabPane
            tab={(
              <span>
                <TranslatedValue value={groupedResearch} />
                &nbsp;&nbsp;
                <StyledBadge count={numberOfResearches} style={{ backgroundColor: '#1890ff' }} />
              </span>
)}
            key={groupedResearch.id}
            forceRender
          >
            <StyledButtonAnt type="primary" onClick={(): void => this.handleAddResearchField(researchTypeDataId)}>
              <FormattedMessage id="SiteDiscoveriesReasearches.addNewResearchField" defaultMessage="Dodaj badanie" />
            </StyledButtonAnt>
            <SiteDiscoveriesResearchField
              activeResearchKey={activeResearchKey}
              changeActiveResearchKey={this.changeActiveResearchKey}
              researches={groupedResearchData || []}
              discoveryIndex={discoveryIndex}
              discoveryFormIndex={discoveryFormIndex}
              name={name}
              form={form}
              handleRemoveResearchField={this.handleRemoveResearchField}
              getResearchIndex={this.getResearchIndex}
            />
          </TabPane>
        );
      },
    );

    return <StyledTabs type="card">{parsedResearch}</StyledTabs>;
  }
}

const StyledTabs = styled(Tabs)`
  margin-bottom: 20px !important;
`;

const StyledButtonAnt = styled(ButtonAnt)`
  width: 100%;
  margin: 15px 0;
`;

const StyledBadge = styled(Badge)`
  padding: 0 0 4px 2px !important;
`;

export default (withApollo(SiteDiscoveriesReasearches): any);
