// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import akra from '../../lib/images/akra.png';

const Footer = (): React.Node => (
  <StyledFooter className="no-print">
    <StyledFooterItem to="/about/privacy">
      <FormattedMessage id="StaticPage.privacyTitle" defaultMessage="Polityka prywatności" />
    </StyledFooterItem>
    <StyledFooterItem to="/about/rules">
      <FormattedMessage id="StaticPage.rulesTitle" defaultMessage="Regulamin" />
    </StyledFooterItem>
    <AkraLogoContainer>
      <a href={process.env.REACT_APP_AKRA_ADDRESS} target="_blank" rel="noopener noreferrer">
        <img src={akra} alt="Akra Polska" width="60" />
      </a>
    </AkraLogoContainer>
  </StyledFooter>
);

const StyledFooter = styled.footer`
  display: flex;
  flex: 0 1 auto;
  align-items: baseline;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3px 0px 15px 0px;
`;

const StyledFooterItem = styled(Link)`
  margin: 10px;
  color: rgba(0, 0, 0, 0.85);
`;

const AkraLogoContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
  padding-right: 5px;
  @media (min-width: 1200px) {
    padding-right: 0%;
  }
`;

export default Footer;
