// @flow
import * as React from 'react';
import { Table } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import parseMuseumCollections from '../../../../../../lib/utils/parseMuseumCollections';
// Own types
import type { MuseumCollectionType } from '../../../../../../lib/types';

type PropsTypes = {
  intl: any,
  museumCollections: MuseumCollectionType[],
};

class PrintMuseumCollections extends React.PureComponent<PropsTypes, null> {
  render(): React.Node {
    const {
      museumCollections,
      intl: { locale },
    } = this.props;
    const museumCollectionsData = parseMuseumCollections(museumCollections);

    const columns = [
      {
        title: <FormattedMessage id="PrintMuseumCollections.inventoryNumber" defaultMessage="Numer Inwentarza" />,
        key: 'inventory_number',
        dataIndex: 'inventory_number',
      },
      {
        title: (
          <FormattedMessage id="PrintMuseumCollections.storage_location" defaultMessage="Miejsce Przechowywania" />
        ),
        key: 'storage_location',
        dataIndex: `storage_location.value_${locale}`,
      },
    ];

    if (museumCollections.length === 0) {
      return <FormattedMessage id="general.noData" defaultMessage="Brak danych" />;
    }

    return <Table columns={columns} dataSource={museumCollectionsData} pagination={false} />;
  }
}

export default (injectIntl(PrintMuseumCollections): any);
