// @flow
import * as React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { DragDropContext } from 'react-dnd';
import { Table, Button } from 'antd';
import update from 'immutability-helper';
import siteDataTranslationsMessages from '../../../lib/constants/siteDataTranslationsMessages';
import DragableBodyRow from '../DragableBodyRow';

import type {
  DragSortingRecordType,
  HandleDragSortingSubmitType,
  OnDragSortingTableRowType,
} from '../../../pages/Sites/SitesList/SitesTable';
import type { HandleHideColumnsListType } from '../ColumnsList';
import { blockTabbingForward } from '../../../lib/utils/blockTabbing/blockTabbing';
import { accessibility } from '../../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize } from '../../../lib/style/themes';

const columns = [
  {
    title: <FormattedMessage id="DragSortingTable.checkAllColumns" defaultMessage="Zaznacz wszystkie kolumny" />,
    dataIndex: 'name',
    key: 'key',
  },
];

type OnDragSortingTableSelectChangeType = (selectedRowKeys: string[]) => void;
interface DragSortingTableInterface {
  onDragSortingTableRowMethod: OnDragSortingTableRowType,
  onDragSortingTableSelectChangeMethod: OnDragSortingTableSelectChangeType,
}

type PropsType = {
  handleDragSortingSubmit: HandleDragSortingSubmitType,
  handleHideColumnsList: HandleHideColumnsListType,
  onRef: Function,
  orderedColumns: Array<string>,
  selectedColumns: Array<string>,
};

type StateType = {
  dragSortingTable: DragSortingRecordType[],
  orderedColumns: Array<string>,
  selectedColumns: Array<string>,
};

class DragSortingTable extends React.Component<PropsType, StateType> implements DragSortingTableInterface {
  static prepareDragSortingTable = (orderedColumns: Array<string>): DragSortingRecordType[] => {
    const dragSortingTable = orderedColumns.map(
      (element: string): Object => ({
        key: element,
        name: <FormattedMessage {...siteDataTranslationsMessages[element]} />,
      }),
    );

    return dragSortingTable;
  };

  constructor(props: PropsType) {
    super(props);
    const { dragSortingTable, orderedColumns, selectedColumns } = this.prepareTableBaseOnInitialData();

    this.state = {
      dragSortingTable,
      orderedColumns,
      selectedColumns,
    };
  }

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    onRef(undefined);
  }

  prepareTableBaseOnInitialData = (): StateType => {
    const { orderedColumns, selectedColumns } = this.props;

    const dragSortingTable = DragSortingTable.prepareDragSortingTable(orderedColumns);

    return {
      dragSortingTable,
      orderedColumns,
      selectedColumns,
    };
  };

  onDragSortingTableRowMethod = (_record, _index) => {
    const moveRow = (dragIndex: number, hoverIndex: number) => {
      const { dragSortingTable } = this.state;
      const dragRow = dragSortingTable[dragIndex];
      const updatedDragSortingTable = update(dragSortingTable, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
      });
      const orderedColumns = updatedDragSortingTable.map((v: { key: string }): string => v.key);
      this.setState({
        dragSortingTable: updatedDragSortingTable,
        orderedColumns,
      });
    };

    return {
      index: _index,
      moveRow,
    };
  };

  onDragSortingTableSelectChangeMethod = (_selectedRowKeys) => {
    this.setState({ selectedColumns: _selectedRowKeys });
  };

  submit = () => {
    const { handleDragSortingSubmit, handleHideColumnsList } = this.props;
    const { orderedColumns, selectedColumns, dragSortingTable } = this.state;

    handleDragSortingSubmit({ orderedColumns, selectedColumns });
    handleHideColumnsList();
    this.setState({
      dragSortingTable,
      orderedColumns,
      selectedColumns,
    });
  };

  cancel = () => {
    const { handleHideColumnsList } = this.props;

    this.reset();
    handleHideColumnsList();
  };

  reset = () => {
    const { dragSortingTable, orderedColumns, selectedColumns } = this.prepareTableBaseOnInitialData();

    this.setState({
      dragSortingTable,
      orderedColumns,
      selectedColumns,
    });
  };

  render(): React.Node {
    const { dragSortingTable, selectedColumns } = this.state;

    const components = {
      body: {
        row: DragableBodyRow,
      },
    };

    const dragSortingSelectedRows = {
      selectedRowKeys: selectedColumns,
      onChange: this.onDragSortingTableSelectChangeMethod,
    };

    return (
      <React.Fragment>
        <Table
          columns={columns}
          pagination={false}
          dataSource={dragSortingTable}
          components={components}
          onRow={this.onDragSortingTableRowMethod}
          rowSelection={dragSortingSelectedRows}
          id="edit-columns-drawer"
        />
        <StyledButton block onClick={(): void => this.submit()} type="primary" href="#edit-columns-button">
          <FormattedMessage id="general.save" defaultMessage="Zastosuj" />
        </StyledButton>
        <StyledButton
          block
          onClick={(): void => this.cancel()}
          href="#edit-columns-button"
          onKeyDown={blockTabbingForward}
        >
          <FormattedMessage id="general.cancel" defaultMessage="Anuluj" />
        </StyledButton>
      </React.Fragment>
    );
  }
}

const StyledButton = styled(Button)`
  margin-top: 5px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

export default (DragDropContext(HTML5Backend)(DragSortingTable): any);
