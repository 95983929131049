import gql from 'graphql-tag';

export default gql`
  query GetSiteChange($filter: SiteChangeFilter, $paginator: Paginator) {
    allSiteChanges(filter: $filter, paginator: $paginator) {
      data {
        id
        changed_by_email
        created_at
        old {
          ...siteRevision
        }
        new {
          ...siteRevision
        }
      }
    }
  }

  fragment siteRevision on SiteRevision {
    local_name
    status
    geojson
    voivodeship
    county
    community
    city
    physicalgeographical_unit
    azp
    ordinal_number_in_azp
    ordinal_number_in_city
    conservator_notes
    hazards
    conservator_detailed_inventory
    conservator_excavation_research
    conservator_administrative_inventory
    monuments_register_number
    monuments_register_addition_date
    culture_park
    zoning_plan
    keza_author
    keza_created_at
    site_area
    discoveries
    notes
  }
`;
