// @flow
import type { DicoveryType, ResearchType, MuseumCollectionType } from '../../types';

const parsedDiscoveries = (discoveries: DicoveryType[]): DicoveryType[] => {
  if (discoveries) {
    // removes empty elements from discoveries array
    const filteredDiscoveries = discoveries.filter(
      (discovery: DicoveryType): boolean => {
        return discovery !== null;
      },
    );

    const discoveriesWithoutLocalId = filteredDiscoveries.map(
      (discovery: DicoveryType): any => {
        const { localId, ...restDiscovery } = discovery;

        if (!restDiscovery.researches) {
          return {
            id: discovery.id,
            discovery_function_id: discovery.discovery_function.id,
            archaeological_culture_id: discovery.archaeological_culture.id,
            chronology_id: discovery.chronology.id,
          };
        }

        const { researches } = restDiscovery;

        const researchesWithoutLocalId = researches.map(
          (research: ResearchType): any => {
            const { localId: researchLocalId, ...restResearch } = research;

            if (!research.museum_collections) return { ...restResearch };

            const { museum_collections: museumCollections } = research;

            const museumCollectionsWithoutLocalId = museumCollections.map(
              (museumCollection: MuseumCollectionType): any => {
                return {
                  id: museumCollection.id,
                  inventory_number: museumCollection.inventory_number,
                  storage_location_id: museumCollection.storage_location.id,
                };
              },
            );

            return { ...restResearch, museum_collections: museumCollectionsWithoutLocalId };
          },
        );

        return {
          id: discovery.id,
          discovery_function_id: discovery.discovery_function.id,
          archaeological_culture_id: discovery.archaeological_culture.id,
          chronology_id: discovery.chronology.id,
          researches: researchesWithoutLocalId,
        };
      },
    );

    return discoveriesWithoutLocalId;
  }

  return [];
};

export default parsedDiscoveries;
