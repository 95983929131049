// @flow
import * as React from 'react';
import styled from 'styled-components';

type PropsType = {
  children: React.Element<typeof React.Component>,
  className: string,
  tabIndexValue: number,
};

const ContentForScreenReaderOnly = (props: PropsType): React.Node => {
  const { tabIndexValue, children, className } = props;

  return (
    <div className={className} role="textbox" tabIndex={tabIndexValue}>
      {children}
    </div>
  );
};

const StyledContentForScreenReaders: any = styled(ContentForScreenReaderOnly)`
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
`;

export default StyledContentForScreenReaders;
