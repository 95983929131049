// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { injectIntl, defineMessages } from 'react-intl';
import { Menu } from 'antd';
import { Icon } from '@ant-design/compatible';
import styled from 'styled-components';
import CHANGE_LANGUAGE_MUTATION from '../../lib/mutations/changeLanguage';
import { darkBlueColor, lightGreyColor, smallerFontSize } from '../../lib/style/themes';

type PropsType = {|
  client: any,
  intl: any,
|};

const MenuItem = Menu.Item;
const { SubMenu } = Menu;

class LanguageSwitch extends React.PureComponent<PropsType, void> {
  handleChange = (event: { key: string }) => {
    const { key } = event;
    const { client } = this.props;

    client.mutate({
      mutation: CHANGE_LANGUAGE_MUTATION,
      variables: { language: key },
    });
  };

  static translations = defineMessages({
    changeLanguage: {
      defaultMessage: 'Zmiana języków',
      id: 'LanguageSwitch.changeLanguage',
    },
  });

  render(): React.Node {
    const { intl } = this.props;

    const changeLanguage = intl.formatMessage(LanguageSwitch.translations.changeLanguage);

    return (
      <SubMenuStyled
        data-cy="flag"
        title={<StyledIcon type="flag" />}
        tabIndex="0"
        aria-label={changeLanguage}
        {...this.props}
      >
        <MenuItem key="pl" onClick={this.handleChange}>
          <StyledOption value="pl">Polski</StyledOption>
        </MenuItem>
        <MenuItem key="en" onClick={this.handleChange}>
          <StyledOption value="en">English</StyledOption>
        </MenuItem>
        <MenuItem key="de" onClick={this.handleChange}>
          <StyledOption value="de">Deutsch</StyledOption>
        </MenuItem>
      </SubMenuStyled>
    );
  }
}

const SubMenuStyled = styled(SubMenu)`
  border-color: ${darkBlueColor} !important;

  .ant-menu-submenu-title {
    padding: 0 5px;
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 17px;
  color: ${lightGreyColor} !important;
`;

const StyledOption = styled.span`
  font-size: ${smallerFontSize};
  font-weight: 600;
`;

export default (injectIntl(withApollo(LanguageSwitch)): any);
