// @flow
import { merge } from 'lodash';
import changeLanguage from './changeLanguage';
import changeTheme from './changeTheme';
import currentUser from './currentUser';
import sitesListColumns from './sitesListColumns';

const resolvers: any = merge(changeLanguage, changeTheme, currentUser, sitesListColumns);

export default resolvers;
