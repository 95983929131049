// @flow
import bbox from '@turf/bbox';
import turfFlatten from '@turf/flatten';
import type { GeoJsonType } from '../../types';

export default (_geojson: ?GeoJsonType): ?Array<[number[], number[]]> => {
  let geojson = _geojson;
  if (!geojson) return undefined;
  if (!geojson.features && !!geojson.geometries) geojson = turfFlatten(geojson);
  if (geojson.features.length === 0) return undefined;

  const box = bbox(geojson);

  if (box[1] === box[3]) {
    box[3] += 0.00001;
    box[1] -= 0.00001;
  }

  if (box[0] === box[2]) {
    box[0] += 0.00001;
    box[2] -= 0.00001;
  }

  return [[box[3], box[2]], [box[1], box[0]]];
};
