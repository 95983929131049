import { defineMessages } from 'react-intl';
import siteDataTranslationsMessages from './siteDataTranslationsMessages';

const changeLogTranslationsMessages = defineMessages({
  empty: {
    defaultMessage: 'brak',
    id: 'changeLog.empty',
  },
  year: {
    defaultMessage: 'Rok',
    id: 'changeLog.year',
  },
  discoveries: {
    defaultMessage: 'Badania i znaleziska',
    id: 'changeLog.discoveries',
  },
  researches: {
    defaultMessage: 'Badania',
    id: 'changeLog.researches',
  },
  description: {
    defaultMessage: 'Opis',
    id: 'changeLog.description',
  },
  museum_collections: {
    defaultMessage: 'Zbiory',
    id: 'changeLog.museum_collections',
  },
  site_area: {
    defaultMessage: 'Powierzchnia',
    id: 'changeLog.site_area',
  },
  inventory_number: {
    defaultMessage: 'Numer inwentarza',
    id: 'changeLog.inventory_number',
  },
  notes: {
    defaultMessage: 'Notatki',
    id: 'changeLog.notes',
  },
  content: {
    defaultMessage: 'Zawartość',
    id: 'changeLog.content',
  },
  created_at: {
    defaultMessage: 'Data utworzenia',
    id: 'changeLog.created_at',
  },
  site_id: {
    defaultMessage: 'Id stanowiska',
    id: 'changeLog.site_id',
  },
  literature: {
    defaultMessage: 'Literatura',
    id: 'changeLog.literature',
  },
  institution: {
    defaultMessage: 'Instytucja',
    id: 'changeLog.institution',
  },
  discovery_id: {
    defaultMessage: 'Id znaleziska',
    id: 'changeLog.discovery_id',
  },
  documentation: {
    defaultMessage: 'Dokumentacja',
    id: 'changeLog.documentation',
  },
  other_information: {
    defaultMessage: 'Inne informacje',
    id: 'changeLog.other_information',
  },
  research_id: {
    defaultMessage: 'Id badania',
    id: 'changeLog.research_id',
  },
  research_supervisor: {
    defaultMessage: 'Kierownik badań',
    id: 'changeLog.research_supervisor',
  },
  yes: {
    defaultMessage: 'Tak',
    id: 'changeLog.yes',
  },
  no: {
    defaultMessage: 'Nie',
    id: 'changeLog.no',
  },
});

export default { ...siteDataTranslationsMessages, ...changeLogTranslationsMessages };
