// @flow
import * as React from 'react';
import { Query, withApollo } from 'react-apollo';
import { message } from 'antd';
import { injectIntl, defineMessages } from 'react-intl';
import type {
  MutationUpdaterFn, DataProxy, FetchResult, QueryRenderProps,
} from 'react-apollo';
import parseNotes from '../../../lib/utils/parseNotes';
import parseDiscoveries from '../../../lib/utils/parseDiscoveries';
import parseDictionaryValue from '../../../lib/utils/parseDictionaryValue';
// Queries and mutation
import UPDATE_SITE_MUTATION from '../../../lib/mutations/editSite';
import SUBMIT_SITE_TO_REVIEW_MUTATION from '../../../lib/mutations/submitSiteToReview';
import GET_SITE from '../../../lib/queries/getSite';
import REMOVE_SITE from '../../../lib/mutations/removeSite';
// Components
import SiteForm from '../../../components/SiteForm';
import LoadingSpin from '../../../components/LoadingSpin';
import BraedCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
import NoDataBecauseError from '../../../components/NoDataBecauseError';

// Utils
import removePropertiesFromGeoJson from '../../../lib/utils/removePropertiesFromGeoJson';
// Own Types
import type { SiteType, UserType } from '../../../lib/types';
import withRouter from '../../../lib/utils/withRouter';

type ResponseType = {
  allSites: {
    concat: Function,
    data: SiteType[],
  },
};

type ResultType = {
  updateSite: SiteType,
};

type PropsType = {
  client: any,
  intl: any,
  location: {
    state: { prevPath?: string },
  },
  navigate: any,
  params: {
    siteId: string,
  },
  user: UserType,
};

type StateType = {
  updating: boolean,
};
export class SitesEdit extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    updating: false,
  };

  mutationUpdater: MutationUpdaterFn<ResultType> = (cache: DataProxy, mutationResult: FetchResult<ResultType>) => {
    const {
      params: { siteId },
    } = this.props;

    try {
      const response: ?ResponseType = cache.readQuery({ query: GET_SITE, variables: { id: siteId } });
      const { data } = mutationResult;
      if (response && data) {
        cache.writeQuery({
          query: GET_SITE,
          variables: { id: siteId },
          data: { site: data.updateSite },
        });
      }
    } catch (e) {
      // https://github.com/apollographql/apollo-client/issues/1701
    }
  };

  editSite: (formValues: SiteType, afterSave: string) => Promise<void> = async (
    formValues: SiteType,
    afterSave: string,
  ): Promise<void> => {
    const {
      client,
      intl,
      navigate,
      params: { siteId },
    } = this.props;

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: UPDATE_SITE_MUTATION,
      variables: {
        id: siteId,
        ...formValues,
        geojson: removePropertiesFromGeoJson(formValues.geojson),
        monuments_register_addition_date:
          formValues.monuments_register_addition_date
          && formValues.monuments_register_addition_date.format('YYYY-MM-DD'),
        keza_created_at: formValues.keza_created_at && formValues.keza_created_at.format('YYYY-MM-DD'),
        notes: parseNotes(formValues.notes),
        discoveries: parseDiscoveries(formValues.discoveries),
        voivodeship_id: parseDictionaryValue(formValues.voivodeship_id),
        county_id: parseDictionaryValue(formValues.county_id),
        community_id: parseDictionaryValue(formValues.community_id),
        azp_id: parseDictionaryValue(formValues.azp_id),
        city_id: parseDictionaryValue(formValues.city_id),
        physicalgeographical_unit_id: parseDictionaryValue(formValues.physicalgeographical_unit_id),
      },
      update: this.mutationUpdater,
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    if (afterSave === 'list') {
      navigate(-1);
    }

    const translatedSuccessMessage = intl.formatMessage(SitesEdit.translations.submitSuccessMessage);
    message.success(translatedSuccessMessage);
  };

  submitSiteToReview: (() => Promise<void>) = async (): Promise<void> => {
    const {
      intl,
      client,
      params: { siteId },
    } = this.props;

    this.setState({ updating: true });
    const response = await client.mutate({
      mutation: SUBMIT_SITE_TO_REVIEW_MUTATION,
      variables: {
        id: siteId,
      },
      update: this.mutationUpdater,
    });
    this.setState({ updating: false });

    if ('errors' in response) {
      return;
    }

    const translatedSuccessMessage = intl.formatMessage(SitesEdit.translations.sendSiteToReviewSuccessMessage);
    message.success(translatedSuccessMessage);
  };

  handleRemoveSite: ((siteId: string) => Promise<void>) = async (siteId: string): Promise<void> => {
    const { client, location, navigate } = this.props;

    await client.mutate({
      mutation: REMOVE_SITE,
      variables: {
        id: siteId,
      },
    });

    if (location.state && location.state.prevPath) {
      navigate('/sites/list');
    } else {
      navigate(-1);
    }
  };

  static translations: any = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'Stanowisko zostało zaktualizowane',
      id: 'SitesEdit.submitSuccessMessage',
    },
    submitErrorMessage: {
      defaultMessage: 'Błąd walidacji formularza, sprawdź wszystkie pola.',
      id: 'SitesEdit.errorMessage',
    },
    sendSiteToReviewSuccessMessage: {
      defaultMessage: 'Stanowisko zostało wysłane do akceptacji',
      id: 'Site.Edit.sendSiteToReviewSuccessMessage',
    },
  });

  render(): React.Node {
    const {
      params: { siteId },
      intl,
      user,
    } = this.props;

    const { updating } = this.state;
    const submitErrorMessage = intl.formatMessage(SitesEdit.translations.submitErrorMessage);

    return (
      <React.Fragment>
        <BraedCrumb breadcrumbs={['sitesList', 'editSite']} />
        <Query query={GET_SITE} variables={{ id: siteId, isAdmin: !!user.email }}>
          {({ loading, error, data }: QueryRenderProps<{ site: SiteType }>): React.Node => {
            if (loading || updating) return <LoadingSpin tip="Loading..." />;
            if (error) return <NoDataBecauseError />;
            if (!data || !data.site) return 'Brak danych';

            return (
              <SiteForm
                site={data.site}
                onSubmit={this.editSite}
                submitErrorMessage={submitErrorMessage}
                submitSiteToReview={this.submitSiteToReview}
                handleRemoveSite={this.handleRemoveSite}
              />
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export default (withRouter(withUser(withApollo(injectIntl(SitesEdit)))): any);
