// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import type { QueryRenderProps } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Spin } from 'antd';

import GET_SITE_CHANGE_QUERY from '../../../../lib/queries/getSiteChange';
import ChangeLogDiff from '../../../../components/ChangeLogDiff';

import type { SiteChangeType } from '../../../../lib/types';
import NoDataBecauseError from '../../../../components/NoDataBecauseError';

type PropsType = {
  id: string,
};

type StateType = {};

class SitesChangeLogDisplayer extends React.PureComponent<PropsType, StateType> {
  render(): React.Node {
    const { id } = this.props;

    return (
      <Query query={GET_SITE_CHANGE_QUERY} variables={{ filter: { id } }}>
        {({ loading, error, data }: QueryRenderProps<{ allSiteChanges: { data: SiteChangeType[] } }>): React.Node => {
          if (error) return <NoDataBecauseError />;

          const change: ?SiteChangeType = data && data.allSiteChanges && data.allSiteChanges.data[0];

          return (
            <React.Fragment>
              {loading && <Spin style={{ position: 'absolute', right: '20px' }} />}
              {!change
                && !loading && <FormattedMessage id="SitesChangeLogDisplayer.noData" defaultMessage="Brak danych" />}
              {change && (
                <React.Fragment>
                  <StyledProp>
                    <FormattedMessage id="SitesChangeLogDisplayer.author" defaultMessage="Autor zmiany" />
                    :&nbsp;
                    {change.changed_by_email}
                  </StyledProp>
                  <StyledProp>
                    <FormattedMessage id="SitesChangeLogDisplayer.data" defaultMessage="Data zmiany" />
                    :&nbsp;
                    {change.created_at}
                  </StyledProp>
                  <ChangeLogDiff left={change.old} right={change.new} />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

const StyledProp = styled.div`
  display: flex;
  span {
    font-weight: bold;
  }
`;

export default SitesChangeLogDisplayer;
