// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { injectIntl, defineMessages } from 'react-intl';
import { message } from 'antd';
import AcceptRejectBar from './AcceptRejectBar';
import REJECT_SITE_DRAFT_MUTATION from '../../../../lib/mutations/rejectSiteDraft';
import ACCEPT_SITE_DRAFT_MUTATION from '../../../../lib/mutations/acceptSiteDraft';
import withRouter from '../../../../lib/utils/withRouter';

type PropsType = {
  client: any,
  intl: any,
  navigate: any,
  siteId: string,
};

type StateType = {};

class AcceptRejectBarContainer extends React.PureComponent<PropsType, StateType> {
  accept = async (): Promise<void> => {
    const {
      client, intl, navigate, siteId,
    } = this.props;

    await client.mutate({
      mutation: ACCEPT_SITE_DRAFT_MUTATION,
      variables: { id: siteId },
    });

    const translatedMessage = intl.formatMessage(AcceptRejectBarContainer.translations.draftAccepted);
    message.success(translatedMessage);
    navigate(-1);
  };

  reject = async (values: {}): Promise<void> => {
    const {
      client, intl, navigate, siteId,
    } = this.props;


    await client.mutate({
      mutation: REJECT_SITE_DRAFT_MUTATION,
      variables: { ...values, id: siteId },
    });

    const translatedMessage = intl.formatMessage(AcceptRejectBarContainer.translations.draftRejected);
    message.success(translatedMessage);
    navigate(-1);
  };

  static translations = defineMessages({
    draftRejected: {
      defaultMessage: 'Stanowisko zostało odrzucone',
      id: 'AcceptRejectBarContainer.draftRejected',
    },
    draftAccepted: {
      defaultMessage: 'Stanowisko zostało opublikowane i jest widoczne dla wszystkich',
      id: 'AcceptRejectBarContainer.draftAccepted',
    },
  });

  render(): React.Node {
    return <AcceptRejectBar accept={this.accept} reject={this.reject} />;
  }
}

export default (withApollo(withRouter(injectIntl(AcceptRejectBarContainer))): React.ComponentType<mixed>);
