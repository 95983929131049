// @flow

export type filesSubGroupType = {
  [string]: {
    prj: Object,
    shp: Object,
  }
};

export type filesGroupType = Array<filesSubGroupType>;

const filesGroupValid = (filesGroup: filesGroupType) => {
  Object.entries(filesGroup).every((group: filesSubGroupType) => {
    const expectedArray = ['prj', 'shp'];
    const filesArrayKeys = Object.keys(group[1]).sort();

    return filesArrayKeys.every((val: string, index: number) => val === expectedArray[index]);
  });
};

const createFilesGroup = (filesArray: FileList): filesGroupType => {
  const filesGroup = {};
  const extensionReg = /(?:\.([^.]+))?$/;

  if (!filesArray || filesArray.length < 2 || filesArray.length % 2 !== 0) {
    throw new Error('Files array not defined or missing file');
  }

  for (let i = 0; i < filesArray.length; i += 1) {
    const file = filesArray[i];
    const extension = extensionReg.exec(file.name);
    const fileName = file.name.split('.')[0];
    if (!(fileName in filesGroup)) filesGroup[fileName] = {};
    if (extension && fileName) filesGroup[fileName][extension[1]] = file;
  }

  if (!filesGroup.size && filesGroupValid(filesGroup)) {
    throw new Error('.prj or .shp file is invalid');
  }

  return filesGroup;
};

// eslint-disable-next-line import/prefer-default-export
export { createFilesGroup };
