// @flow

export default {
  '/': {
    pl: '5',
    en: '20',
    de: '22',
  },
  '/about/experts': {
    pl: '24',
    en: '26',
    de: '29',
  },
  '/about/finders': {
    pl: '31',
    en: '33',
    de: '35',
  },
  '/about/developers': {
    pl: '37',
    en: '39',
    de: '41',
  },
  '/about/conservator': {
    pl: '43',
    en: '45',
    de: '47',
  },
  '/about/faq': {
    pl: '49',
    en: '51',
    de: '53',
  },
  '/about/privacy': {
    pl: '212',
    en: '214',
    de: '216',
  },
  '/about/rules': {
    pl: '218',
    en: '220',
    de: '222',
  },
};
