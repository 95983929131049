// @flow
import * as React from 'react';
// Components
import MuseumCollectionsField from './MuseumCollectionsField';
// Own Types
import type { MuseumCollectionType } from '../../../../../../lib/types';

type PropTypes = {
  getFieldDecorator: Function,
  handleRemoveMuseumCollection: (museumCollection: MuseumCollectionType) => Promise<void>,
  museumCollections: MuseumCollectionType[],
  name: string,
  setFieldsValue: Function,
  validateFields: Function,
};

const MuseumCollections = (props: PropTypes): React.Node => {
  const {
    museumCollections,
    getFieldDecorator,
    setFieldsValue,
    validateFields,
    handleRemoveMuseumCollection,
    name,
  } = props;
  const zielonaGoraId = '2159';

  const museumCollectionsFields = museumCollections.map(
    (museumCollection: MuseumCollectionType, museumCollectionIndex: number): React.Node => {
      return (
        <MuseumCollectionsField
          museumCollection={museumCollection}
          getFieldDecorator={getFieldDecorator}
          handleRemoveMuseumCollection={handleRemoveMuseumCollection}
          key={`${museumCollection.localId}`}
          name={`${name}[${museumCollection.localId}]`}
          setFieldsValue={setFieldsValue}
          validateFields={validateFields}
          zielonaGoraId={zielonaGoraId}
          museumCollectionIndex={museumCollectionIndex}
        />
      );
    },
  );

  return museumCollectionsFields;
};

export default MuseumCollections;
