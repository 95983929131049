// @flow
import * as React from 'react';
import { Button } from 'antd';
import { Icon } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import { withApollo } from 'react-apollo';
import styled, { css, withTheme } from 'styled-components';
// Queries
import ALL_SITES_EXPORT from '../../../../../lib/queries/allSitesExport';
import { accessibility } from '../../../../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize } from '../../../../../lib/style/themes';

type PropTypes = {
  client: any,
  columnSortOrder: string,
  filter: string,
  selectedColumns: string[],
};

const CSVButton = (props: PropTypes): React.Node => {
  const downloadCSV = async (): Promise<void> => {
    const {
      client, columnSortOrder, selectedColumns, filter,
    } = props;

    const response = await client.query({
      query: ALL_SITES_EXPORT,
      variables: { columns: selectedColumns, order: columnSortOrder, filter },
      fetchPolicy: 'network-only',
    });

    if (response && response.data && response.data.allSitesExport) {
      const csvUrl = response.data.allSitesExport.csv_url;
      window.open(csvUrl, 'Download');
    }
  };

  return (
    <StyledButtonCSV type="primary" onClick={downloadCSV}>
      <Icon type="download" />
      <FormattedMessage id="CSVButton.donwload" defaultMessage="Pobierz CSV" />
    </StyledButtonCSV>
  );
};

const StyledButtonCSV = styled(Button)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow !important;
      background-color: ${darkBlueColor} !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;
      &:focus {
        color: black !important;
        background-color: white !important;
        border: 2px solid red !important;
      }
    `};
`;

export default (withApollo(withTheme(CSVButton)): any);
