// @flow
import * as React from 'react';
import {
  Input, Popconfirm, Button, Row,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';
import {
  FormattedMessage, injectIntl, defineMessages,
} from 'react-intl';
import { Query } from 'react-apollo';
import type { QueryRenderProps } from 'react-apollo';
// import formItemLayout from '../../lib/constants/formItemLayout';

import type { getFieldDecoratorType, setFieldsValueType } from '../SiteForm/SiteForm';
// Own types
import type { NotesType, UserType } from '../../lib/types';
// Form Validation
import { required } from '../../lib/formValidation';
// Query
import CURRENT_USER_QUERY from '../../lib/queries/CurrentUser';
import NoDataBecauseError from '../NoDataBecauseError';

const { TextArea } = Input;
const FormItem = Form.Item;

type PropsType = {
  autoSize: {
    maxRows: number,
    minRows: number,
  },
  className: string,
  form: {
    getFieldDecorator: getFieldDecoratorType,
    setFieldsValue: setFieldsValueType,
  },
  handleRemoveNote: (numberIndex: number) => Promise<void>,
  initialData: NotesType,
  intl: any,
  name: string,
  noteIndex: number,
};

type StateType = {
  contentError: boolean,
};

class SiteNoteFiled extends React.PureComponent<PropsType, StateType> {
  static translations = defineMessages({
    removeQuestion: {
      defaultMessage: 'Czy chcesz usunąć notatkę?',
      id: 'SiteNoteField.removeQuestion',
    },
    yes: {
      defaultMessage: 'Tak',
      id: 'general.yes',
    },
    no: {
      defaultMessage: 'Nie',
      id: 'general.no',
    },
  });

  render(): React.Node {
    const {
      autoSize,
      className,
      name,
      initialData,
      form: { getFieldDecorator },
      handleRemoveNote,
      intl,
      noteIndex,
    } = this.props;

    return (
      <Query query={CURRENT_USER_QUERY} fetchPolicy="cache-first">
        {({ loading, error, data }: QueryRenderProps<{ current_user: UserType }>): React.Node => {
          if (loading) return 'Loading...';
          if (error) return <NoDataBecauseError />;

          if (data && data.current_user && data.current_user.name) {
            const userEmail = data.current_user.email;
            const superAdmin = data.current_user.level === 1;
            const noteOwner = initialData.created_by_email === userEmail;
            const newNote = !initialData.id;

            const canUserEdit = superAdmin || noteOwner || newNote;

            return (
              <Row type="flex" justify="space-between" align="middle" className={className}>
                <HiddenFormItem>
                  {getFieldDecorator(`${name}.id`, { initialValue: initialData ? initialData.id : null })(
                    <Input name="name.id" disabled />,
                  )}
                </HiddenFormItem>
                <HiddenFormItem>
                  {getFieldDecorator(`${name}.localId`, { initialValue: initialData ? initialData.localId : null })(
                    <Input name="name.localId" disabled />,
                  )}
                </HiddenFormItem>
                <HiddenFormItem>
                  {getFieldDecorator(`${name}.created_by_email`, {
                    initialValue: initialData ? initialData.created_by_email : null,
                  })(<Input name="name.created_by_email" disabled />)}
                </HiddenFormItem>
                <HiddenFormItem>
                  {getFieldDecorator(`${name}.created_at`, {
                    initialValue: initialData ? initialData.created_at : null,
                  })(<Input name="name.created_at" disabled />)}
                </HiddenFormItem>
                <StyledFormItem>
                  {getFieldDecorator(`${name}.content`, {
                    rules: [required],
                    initialValue: initialData ? initialData.content : null,
                  })(
                    <TextArea
                      name="name.content"
                      disabled={!canUserEdit}
                      autoSize={autoSize}
                      data-cy="site-note-field"
                    />,
                  )}
                </StyledFormItem>
                {canUserEdit && (
                  <FormattedMessage id="general.remove" defaultMessage="Usuń">
                    {(buttonText: string): React.Node => (
                      <Popconfirm
                        title={intl.formatMessage(SiteNoteFiled.translations.removeQuestion)}
                        onConfirm={(): Promise<void> => handleRemoveNote(noteIndex)}
                        okText={intl.formatMessage(SiteNoteFiled.translations.yes)}
                        cancelText={intl.formatMessage(SiteNoteFiled.translations.no)}
                      >
                        <Button>{buttonText}</Button>
                      </Popconfirm>
                    )}
                  </FormattedMessage>
                )}
              </Row>
            );
          }

          return null;
        }}
      </Query>
    );
  }
}

const StyledSiteNoteFiled = styled(SiteNoteFiled)`
  margin-top: 5px;
  margin-bottom: 15px;
`;

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

const StyledFormItem = styled(FormItem)`
  flex: 1;
  margin-right: 15px !important;
  margin-bottom: 0 !important;
`;

export default (injectIntl(StyledSiteNoteFiled): any);
