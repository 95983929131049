// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import { Menu } from 'antd';
import { Icon } from '@ant-design/compatible';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';
import CHANGE_THEME_MUTATION from '../../lib/mutations/changeTheme';
import { accessibility } from '../../lib/constants/themeModes';
import { darkBlueColor, lightGreyColor, biggerFontSize } from '../../lib/style/themes';

const MenuItem = Menu.Item;

type PropsType = {
  client: any,
  intl: any,
};

type StateType = {
  badgeStatus: string,
};

class AccessibilitySwitch extends React.PureComponent<PropsType, StateType> {
  toogleTheme = async (): Promise<void> => {
    const { client } = this.props;

    await client.mutate({
      mutation: CHANGE_THEME_MUTATION,
      variables: { theme: { mode: accessibility } },
    });
  };

  static translations = defineMessages({
    turnOnDisabledFriendlyMode: {
      defaultMessage: 'Włącz tryb przyjazny osobom niedowidzącym',
      id: 'AccessibilitySwitch.turnOnDisabledFriendlyMode',
    },
  });

  render(): React.Node {
    const { intl } = this.props;
    const turnOnDisabledFriendlyMode = intl.formatMessage(AccessibilitySwitch.translations.turnOnDisabledFriendlyMode);

    return (
      <MenuItemStyled
        {...this.props}
        tabIndex="0"
        onClick={this.toogleTheme}
        onKeyPress={this.toogleTheme}
        aria-label={turnOnDisabledFriendlyMode}
      >
        <StyledIcon type="eye" className="logo-icon" />
      </MenuItemStyled>
    );
  }
}

const MenuItemStyled = styled(MenuItem)`
  background-color: ${darkBlueColor} !important;
  border-bottom: none !important;
`;

const StyledIcon = styled(Icon)`
  font-size: ${biggerFontSize} !important;
  color: ${lightGreyColor} !important;
`;

export default (injectIntl(withApollo(AccessibilitySwitch)): any);
