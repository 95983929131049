// @flow
import * as React from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'lodash';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Col, Row } from 'antd';
// Leaflet
import {
  Map, TileLayer, FeatureGroup, LayersControl, ScaleControl,
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

// Leaflet Marker Icon bug
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L, { FeatureGroup as LeafletFeatureGroup } from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

import { Geocoder, Coordinates, Control } from '../LeafletComponents';
import getScaleFactor from '../../lib/utils/getScaleFactor';
import prepareWMSLayers from '../../lib/utils/prepareWMSLayers';
import WMSLayers from '../../lib/constants/WMSLayers';
import ShapefileUploader from './ShapefileUploader';

import { zielonaGoraBounds } from '../../lib/constants/coordinate';
import boundsToArray from '../../lib/utils/boundsToArray';
import { accessibility } from '../../lib/constants/themeModes';
import {
  smallerFontSize, biggerFontSize, generalFontSize, darkGreyColor,
} from '../../lib/style/themes';
import Breakpoints from '../../lib/constants/breakpoints';

// Types
import type {
  ViewportType, MapElementType, GeoJsonType, LeafletBoundsType,
} from '../../lib/types';

import { prepareCoordinatesForGeodesicArea, countArea, coordinatesToString } from './EditableMapUtils';
// Components
import ContentForScreenReaderOnly from '../ContentForScreenReaderOnly';

// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

type PropsType = {
  geojsonData: GeoJsonType,
  hideEditControl: ?boolean,
  hideGeocoder: ?boolean,
  onChange: ({ area: number, geojson: GeoJsonType }) => void,
  prepareMapForPrint: boolean,
};

type StateType = {
  area: number,
  coordinatesString: ?string,
  leafletBounds: LeafletBoundsType | null,
  scale: ?number,
};

type LeafletElement = typeof LeafletFeatureGroup;

const countAreaAndStringCoordinates: (
  geojson: GeoJsonType
) => { area: number, coordinatesString: ?string } = (
  geojson: GeoJsonType,
): { area: number, coordinatesString: ?string } => {
  const mapElements = geojson.features.map(
    ({ geometry }: typeof L.GeometryUtil): MapElementType => {
      const { type, coordinates } = geometry;
      const coords = prepareCoordinatesForGeodesicArea({
        geometryCoordinates: coordinates,
      });
      const area = L.GeometryUtil.geodesicArea(coords);

      return {
        type,
        coords,
        area,
      };
    },
  );

  return {
    area: countArea(mapElements),
    coordinatesString: coordinatesToString(mapElements),
  };
};

class EditableMap extends React.Component<PropsType, StateType> {
  editableFG: ?LeafletElement;

  timeout: TimeoutID;

  mapRef: any;

  constructor() {
    super();

    this.state = {
      area: 0,
      coordinatesString: null,
      leafletBounds: null,
      scale: null,
    };

    this.editableFG = null;
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    const { prepareMapForPrint } = this.props;
    if (prepareMapForPrint) {
      this.prepareMapForPrint();
      setTimeout(window.print, 800);

      window.addEventListener('afterprint', () => {
        window.close();
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('afterprint', () => {
      window.close();
    });
    clearTimeout(this.timeout);
  }

  prepareMapForPrint: (() => void) = () => {
    this.resizeMap({ height: '10cm', maxWidth: '20cm' });
  };

  resizeMap: ({ height: string, maxWidth: string }) => void = ({
    height,
    maxWidth,
  }: {
    height: string,
    maxWidth: string,
  }) => {
    const { leafletBounds } = this.state;

    const selector = document.querySelector('.map-wrapper');
    if (selector && this.mapRef.current) {
      selector.style.height = height;
      selector.style.maxWidth = maxWidth;

      this.mapRef.current.leafletElement.invalidateSize(false);
      if (!leafletBounds || !leafletBounds._northEast) return;
      this.mapRef.current.leafletElement.fitBounds(boundsToArray(leafletBounds), { animate: false });
    }
  };

  onViewportChanged: ((viewport: ViewportType) => void) = (viewport: ViewportType) => {
    const { zoom } = viewport;
    const scale = getScaleFactor(zoom);
    this.setState({ scale });
  };

  onFeatureGroupReady: ((reactFGref: LeafletElement) => void) = (reactFGref: LeafletElement) => {
    if (!reactFGref) return;
    // store the ref for future access to content
    this.editableFG = reactFGref;
    this.loadGeojson();
  };

  loadGeojson: ((_geojson: ?GeoJsonType) => void) = (_geojson: ?GeoJsonType) => {
    if (!this.editableFG) return;
    const { geojsonData } = this.props;
    const geojson = _geojson || geojsonData;

    const leafletGeoJSON = new L.GeoJSON(geojson);

    // $FlowExpectedError[incompatible-use]
    const leafletFG = this.editableFG.leafletElement;

    leafletGeoJSON.eachLayer((layer: any) => {
      leafletFG.addLayer(layer);
    });

    const leafletBounds = leafletFG.getBounds();
    this.setState({ leafletBounds });

    if (geojsonData) {
      const { area, coordinatesString } = countAreaAndStringCoordinates(geojsonData);
      this.setState({
        area,
        coordinatesString,
      });
    }
  };

  onLoadGeojsonFromFile: ((geojson: GeoJsonType) => void) = (geojson: GeoJsonType) => {
    this.loadGeojson(geojson);
    this.onChange();
  };

  onChange: ((change: ?{ type: string }) => void) = (change: ?{ type: string }) => {
    // this.editableFG contains the edited geometry, which can be manipulated through the leaflet API

    const { onChange } = this.props;

    if (!this.editableFG || !onChange) {
      return;
    }

    if (change && change.type === 'draw:deleted') {
      const leafletBounds = this.editableFG.leafletElement.getBounds();
      this.setState({ leafletBounds });
    }

    // $FlowExpectedError[incompatible-use]
    const geojson = this.editableFG.leafletElement.toGeoJSON();
    const { area, coordinatesString } = countAreaAndStringCoordinates(geojson);
    onChange({ geojson, area });

    this.setState({
      area,
      coordinatesString,
    });
  };

  onMapReady: (({ target: any }) => void) = ({ target }: { target: any }) => {
    this.timeout = setTimeout(() => {
      const scale = getScaleFactor(target.getZoom());
      this.setState({
        scale,
      });
    }, 0);
  };

  render(): React.Node {
    const {
      leafletBounds, scale, coordinatesString, area,
    } = this.state;
    const { hideEditControl, hideGeocoder, prepareMapForPrint } = this.props;
    const { BaseLayer } = LayersControl;
    const layers = prepareWMSLayers(WMSLayers);

    const bounds = !isEmpty(leafletBounds) ? leafletBounds : zielonaGoraBounds;

    return (
      <React.Fragment>
        <ContentForScreenReaderOnly tabIndexValue={-1}>
          <FormattedMessage
            id="EditableMap.contentInfo"
            defaultMessage="Mapa przedstawiająca stanowisko archeologiczne"
          />
        </ContentForScreenReaderOnly>
        <MapWrapper className="map-wrapper">
          <Map
            ref={this.mapRef}
            whenReady={this.onMapReady}
            onViewportChanged={this.onViewportChanged}
            bounds={boundsToArray(bounds)}
            style={{ height: '100%' }}
            zoomControl={!prepareMapForPrint}
          >
            <div className="scale-factor">1: {scale}</div>
            <ScaleControl position="bottomright" />
            {!hideGeocoder && <Geocoder position="topleft" />}
            {!prepareMapForPrint && <Coordinates position="bottomleft" />}
            <LayersControl position="topright">
              <BaseLayer checked name="OpenStreetMap">
                <TileLayer
                  attribution="&copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                  url={process.env.REACT_APP_TILE_URL}
                  maxZoom={20}
                />
              </BaseLayer>
              {layers}
            </LayersControl>
            {!hideEditControl && (
              <Control position="topright">
                <ShapefileUploader onLoad={this.onLoadGeojsonFromFile} />
              </Control>
            )}
            <FeatureGroup ref={this.onFeatureGroupReady}>
              {!hideEditControl && (
                <EditControl
                  position="topright"
                  onCreated={this.onChange}
                  onEdited={this.onChange}
                  onDeleted={this.onChange}
                  draw={{
                    rectangle: false,
                    polyline: false,
                    polygon: {
                      allowIntersection: false,
                      showArea: true,
                    },
                    circle: false,
                    circlemarker: false,
                  }}
                />
              )}
            </FeatureGroup>
          </Map>
        </MapWrapper>
        <StyledRow gutter={12}>
          {!prepareMapForPrint && (
            <Col lg={12}>
              <SelectedAreaTitle>
                <FormattedMessage id="EditableMap.coordinates" defaultMessage="Współrzędne geograficzne" />
              </SelectedAreaTitle>
              <SelectedAreaValue>{coordinatesString}</SelectedAreaValue>
            </Col>
          )}
          <Col lg={12}>
            <SelectedAreaTitle>
              <FormattedMessage id="EditableMap.area" defaultMessage="Pole powierzchni zaznaczonego obszaru" />
            </SelectedAreaTitle>
            <SelectedAreaValue style={{ textAlign: 'right', fontSize: '16px' }}>
              <FormattedNumber value={area} />
              &nbsp;m
              <sup>2</sup>
            </SelectedAreaValue>
          </Col>
        </StyledRow>
      </React.Fragment>
    );
  }
}

const MapWrapper = styled.div`
  max-width: 100%;
  height: 80vh;

  @media print {
    max-width: 20cm;
    height: 10cm;
  }

  .leaflet-control-container {
    .leaflet-left,
    .leaflet-right {
      max-width: 50%;
    }
  }

  @media (max-width: ${Breakpoints.mobile}) {
    .leaflet-control-coordinates {
      display: none;
    }
  }

  .leaflet-control-coordinates {
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-clip: padding-box;
    background-color: #fff;

    .inputX,
    .inputY {
      max-width: 90px;
      margin: 0 5px;
      padding: 4px 11px;
      height: 32px;
      font-size: ${smallerFontSize};
      line-height: 1.5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      transition: all 0.3s;

      &:focus {
        border-color: #40a9ff;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
      }
    }

    .uiElement.label {
      display: none;
    }
  }

  .scale-factor {
    position: absolute;
    top: 10px;
    left: 55px;
    z-index: 1000;
    padding: 0 5px;
    color: #333;
    font-size: ${smallerFontSize};
    background: #fff;
    border: 2px solid #ccc;
  }

  .leaflet-control-scale-line {
    font-size: ${smallerFontSize};
    border-left: 2px solid #888;
    border-right: 2px solid #888;
    background: #fff;
  }

  @media print {
    .leaflet-control-layers-toggle {
      display: none;
    }

    .scale-factor {
      left: 5px;
    }
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      display: none;
    `};
`;

const StyledRow = styled(Row)`
  margin-top: 10px;
`;

const SelectedAreaTitle = styled.div`
  color: ${darkGreyColor};
  margin-bottom: 5px;

  @media print {
    font-size: ${smallerFontSize};
    font-weight: 500;
    color: ${darkGreyColor};
    border-bottom: 1px solid #e8e8e8;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
    `};
`;

const SelectedAreaValue = styled.div`
  color: gray;
  min-height: 36px;
  max-height: 100px;
  border: 1px dashed #ccc;
  border-radius: 5px;
  padding: 5px 8px;
  overflow-y: auto;
  white-space: pre-line;

  @media print {
    border: 0;
    max-height: none;
    color: ${darkGreyColor};
    padding: 0;
    font-size: 12px !important;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${generalFontSize};
    `};
`;

export default EditableMap;
