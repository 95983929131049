import gql from 'graphql-tag';

export default gql`
  query AllSiteChanges($filter: SiteChangeFilter, $paginator: Paginator) {
    allSiteChanges(filter: $filter, paginator: $paginator) {
      total
      data {
        id
        changed_by_email
        created_at
      }
    }
  }
`;
