// @flow
import * as React from 'react';
import { Input } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import formItemLayout from '../../lib/constants/formItemLayout';
import type { getFieldDecoratorType, setFieldsValueType } from '../SiteForm/SiteForm';
// Own types
import type { ValidationRulesType } from '../../lib/types';

type PropsType = {
  autoSize: {
    maxRows: number,
    minRows: number,
  },
  disabled: boolean,
  getFieldDecorator: getFieldDecoratorType,
  initialFieldValue: string,
  label: string,
  name: string,
  rowsNumber: number,
  setFieldsValue: setFieldsValueType,
  validationRules: ValidationRulesType,
};

class InputTextareaField extends React.Component<PropsType, void> {
  componentDidMount() {
    const { setFieldsValue, initialFieldValue, name } = this.props;

    if (initialFieldValue) {
      setFieldsValue({
        [name]: initialFieldValue,
      });
    }
  }

  handleOnChange: ((value: string) => void) = (value: string) => {
    const { name, setFieldsValue } = this.props;

    setFieldsValue({
      [name]: value,
    });
  };

  render(): React.Node {
    const FormItem = Form.Item;
    const { TextArea } = Input;

    const {
      autoSize, label, name, getFieldDecorator, disabled, validationRules, rowsNumber,
    } = this.props;

    return (
      <FormItem {...formItemLayout} label={label}>
        {getFieldDecorator(name, {
          rules: validationRules,
        })(
          <TextArea
            name={name}
            onChange={this.handleOnChange}
            disabled={disabled}
            autoSize={autoSize}
            rows={rowsNumber}
          />,
        )}
      </FormItem>
    );
  }
}

export default InputTextareaField;
