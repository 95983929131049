// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { Breadcrumb, Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import { pathToRegexp } from 'path-to-regexp';
import { accessibility } from '../../lib/constants/themeModes';
import { biggerFontSize, generalFontSize } from '../../lib/style/themes';
import Breakpoints from '../../lib/constants/breakpoints';
import PageConfig from '../../config/pageConfig';
import withRouter from '../../lib/utils/withRouter';

const breadcrumbsTitleMap = {
  about: <FormattedMessage id="Navigation.about.subMenu" defaultMessage="O platformie" />,
  contact: <FormattedMessage id="Navigation.about.contactForm" defaultMessage="Formularz kontaktowy" />,
  sitesList: <FormattedMessage id="Navigation.listView" defaultMessage="Widok tabelaryczny" />,
  admin: <FormattedMessage id="Navigation.admin.subMenu" defaultMessage="Administrator" />,
  addSite: <FormattedMessage id="Navigation.admin.addSite" defaultMessage="Dodaj stanowisko" />,
  dictionaries: <FormattedMessage id="Navigation.admin.dictionaries" defaultMessage="Słowniki" />,
  dictionary: <FormattedMessage id="BreadCrumb.dictionary" defaultMessage="Słownik" />,
  editSite: <FormattedMessage id="BreadCrumb.editSite" defaultMessage="Edycja stanowiska" />,
  siteDetails: <FormattedMessage id="BreadCrumb.siteDetails" defaultMessage="Szczegóły stanowiska" />,
  conservator: <FormattedMessage id="BreadCrumb.conservator" defaultMessage="Dane kontaktowe konserwatora" />,
  experts: <FormattedMessage id="BreadCrumb.experts" defaultMessage="Dla specjalistów" />,
  finders: <FormattedMessage id="BreadCrumb.finder" defaultMessage="Dla poszukiwaczy" />,
  developers: <FormattedMessage id="BreadCrumb.developers" defaultMessage="Dla deweloperów" />,
  faq: <FormattedMessage id="BreadCrumb.faq" defaultMessage="Jak korzystać" />,
  privacy: <FormattedMessage id="StaticPage.privacyTitle" defaultMessage="Polityka Prywatności" />,
  rules: <FormattedMessage id="StaticPage.rulesTitle" defaultMessage="Regulamin" />,
};

const breadcrumbsMap = {
  about: breadcrumbsTitleMap.about,
  contact: <Link to="/about/contact">{breadcrumbsTitleMap.contact}</Link>,
  sitesList: <Link to="/sites/list">{breadcrumbsTitleMap.sitesList}</Link>,
  admin: breadcrumbsTitleMap.admin,
  addSite: <Link to="/admin/sites/new">{breadcrumbsTitleMap.addSite}</Link>,
  dictionaries: <Link to="/admin/dictionaries">{breadcrumbsTitleMap.dictionaries}</Link>,
  dictionary: breadcrumbsTitleMap.dictionary,
  editSite: breadcrumbsTitleMap.editSite,
  siteDetails: breadcrumbsTitleMap.siteDetails,
  conservator: breadcrumbsTitleMap.conservator,
  experts: breadcrumbsTitleMap.experts,
  finders: breadcrumbsTitleMap.finders,
  developers: breadcrumbsTitleMap.developers,
  faq: breadcrumbsTitleMap.faq,
  privacy: breadcrumbsTitleMap.privacy,
  rules: breadcrumbsTitleMap.rules,
};

type PathNameDataType = {
  isDictionaryPath: boolean,
  isSiteDetailPath: boolean,
  isSiteEditPath: boolean,
  pathname: string,
};

type PropsType = {
  breadcrumbs: string[],
  intl: any,
  location: { pathname: string },
  params: {
    dictionaryId?: string,
    siteId?: string,
  },
  title: string,
};

const parsePathName = (pathNameData: PathNameDataType): string => {
  const {
    pathname, isDictionaryPath, isSiteEditPath, isSiteDetailPath,
  } = pathNameData;

  if (isDictionaryPath) {
    return '/admin/dictionary';
  }

  if (isSiteEditPath) {
    return '/admin/sites';
  }

  if (isSiteDetailPath) {
    return '/sites';
  }

  return pathname;
};

const BreadCrumb = (props: PropsType): React.Node => {
  const {
    breadcrumbs = [],
    intl: { formatMessage },
    params: { dictionaryId, siteId },
    title,
    location: { pathname },
  } = props;

  const dictionaryRegExp = pathToRegexp('/admin/dictionary/:dictionaryId(\\d+)');
  const siteDetailRegExp = pathToRegexp('/sites/:siteId(\\d+)');
  const siteDetailsAzpRegExp = pathToRegexp('/sites/:azp_valuePl/:ordinalNumberInAzp');
  const siteEditRegExp = pathToRegexp('/admin/sites/:siteId(\\d+)/edit');

  const isSiteEditPath = siteEditRegExp.exec(pathname);
  const isSiteDetailPath = siteDetailRegExp.exec(pathname) || siteDetailsAzpRegExp.exec(pathname);
  const isDictionaryPath = dictionaryRegExp.exec(pathname);
  const isDefaultTitle = !isDictionaryPath && !isSiteEditPath && !isSiteDetailPath;

  const parsedPathName = parsePathName({
    pathname,
    isDictionaryPath,
    isSiteEditPath,
    isSiteDetailPath,
  });

  const { showBreadcrumbs, helmetPageTitle } = PageConfig[parsedPathName];

  const breadcrumbItems = breadcrumbs.map(
    (breadcrumb: string): React.Node => (
      <StyledBreadCrumbItem key={breadcrumb} tabIndex="0">
        {breadcrumbsMap[breadcrumb]}
      </StyledBreadCrumbItem>
    ),
  );
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
  const pageTitle = title || breadcrumbsTitleMap[lastBreadcrumb];

  return (
    <React.Fragment>
      <Helmet>
        {isDictionaryPath && (
          <title>
            {formatMessage(helmetPageTitle)} id: {dictionaryId}
          </title>
        )}
        {(isSiteEditPath || isSiteDetailPath) && (
          <title>
            {formatMessage(helmetPageTitle)} id: {siteId || ''}
          </title>
        )}
        {isDefaultTitle && <title>{formatMessage(helmetPageTitle)}</title>}
      </Helmet>
      {showBreadcrumbs && (
        <BreadCrumbWrapper>
          <StyledRow align="middle" type="flex">
            <StyledCol span={12}>
              <StyledBreadCrumb>{breadcrumbItems}</StyledBreadCrumb>
              <StyledPageTitle>{pageTitle}</StyledPageTitle>
            </StyledCol>
            <StyledCol span={12}>
              <StyledBreadcrumbButton id="breadcrumb-button" />
            </StyledCol>
          </StyledRow>
        </BreadCrumbWrapper>
      )}
    </React.Fragment>
  );
};

const StyledCol = styled(Col)`
  @media (max-width: ${Breakpoints.mobile}) {
    width: 100% !important;
  }
`;

const BreadCrumbWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 10px 0 !important;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: yellow;
      font-size: ${biggerFontSize};
    `};

  @media print {
    display: none;
  }
`;

const StyledRow = styled(Row)`
  max-width: ${Breakpoints.desktop};
  margin: 0 auto !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  @media all and (min-width: 1250px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
`;

const StyledBreadcrumbButton = styled.div`
  float: right;
  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }
`;

const StyledBreadCrumbItem = styled(Breadcrumb.Item)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      font-size: ${generalFontSize};
      color: black;
      &:focus {
        border: 2px solid red;
      }
    `};
`;

const StyledBreadCrumb = styled(Breadcrumb)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      a span {
        color: black;
      }
    `};
`;

const StyledPageTitle = styled.h1`
  font-size: 21px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      font-size: 26px;
    `};
`;

export default (withRouter(injectIntl(BreadCrumb)): any);
