// @flow
import * as React from 'react';
import { Menu, Drawer } from 'antd';
import { Icon } from '@ant-design/compatible';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import ContentForLoggedInUsers from '../ContentForLoggedInUsers';
import withUser from '../withUser';
import type { UserType } from '../../lib/types';
import SITE_STATUS from '../../lib/constants/siteStatus';
import USER_LEVEL from '../../lib/constants/userLevel';
import Breakpoints from '../../lib/constants/breakpoints';
import { darkBlueColor, biggerFontSize, smallerFontSize } from '../../lib/style/themes';
import withRouter from '../../lib/utils/withRouter';

const MenuItem = Menu.Item;
const { SubMenu } = Menu;

type PropsType = {
  location: {
    pathname: string,
  },
  user: UserType,
};

type StateType = {
  showDrawer: boolean,
};

class NavigationDrawer extends React.PureComponent<PropsType, StateType> {
  state = {
    showDrawer: false,
  };

  showDrawer = () => {
    this.setState({
      showDrawer: true,
    });
  };

  onClose = () => {
    this.setState({
      showDrawer: false,
    });
  };

  render(): React.Node {
    const {
      location: { pathname },
      user: { email, level },
    } = this.props;

    const siteEditRegExp = pathToRegexp('/admin/sites/:siteId(\\d+)/edit');
    const isSiteEditPath = siteEditRegExp.exec(pathname);

    const siteDetailRegExp = pathToRegexp('sites/:siteId(\\d+)');
    const isSiteDetailPath = siteDetailRegExp.exec(pathname);

    const dictionaryRegExp = pathToRegexp('/admin/dictionary/:dictionaryId(\\d+)');
    const isDictionaryPath = dictionaryRegExp.exec(pathname);

    let selectedKeys: [string | null] = [pathname];

    const { showDrawer } = this.state;

    if (isSiteEditPath || isSiteDetailPath || isDictionaryPath) {
      selectedKeys = [null];
    }

    return (
      <React.Fragment>
        <StyledHamburgerIcon
          type={showDrawer ? 'menu-unfold' : 'menu-fold'}
          onClick={showDrawer ? this.onClose : this.showDrawer}
        />
        <StyledDrawer
          title={<FormattedMessage id="Navigation.menu" defaultMessage="Menu" />}
          placement="right"
          closable={false}
          onClose={this.onClose}
          open={showDrawer}
        >
          <Menu mode="inline" theme="dark" defaultSelectedKeys={selectedKeys} selectedKeys={selectedKeys}>
            <StyledSubMenu
              title={(
                <span>
                  <StyledIcon type="eye" />
                  <FormattedMessage id="Navigation.about.subMenu" defaultMessage="O platformie" />
                </span>
)}
              tabIndex="0"
              key="/about"
            >
              <MenuItem key="/about/experts">
                <Link to="/about/experts" onClick={this.onClose}>
                  <FormattedMessage id="Navigation.about.experts" defaultMessage="Dla specjalistów" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/finders">
                <Link to="/about/finders" onClick={this.onClose}>
                  <FormattedMessage id="Navigation.about.finders" defaultMessage="Dla poszukiwaczy" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/developers">
                <Link to="/about/developers" onClick={this.onClose}>
                  <FormattedMessage id="Navigation.about.developers" defaultMessage="Dla deweloperów" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/conservator">
                <Link to="/about/conservator" onClick={this.onClose}>
                  <FormattedMessage id="Navigation.about.conservator" defaultMessage="Dane kontaktowe konserwatora" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/faq">
                <Link to="/about/faq" onClick={this.onClose}>
                  <FormattedMessage id="Navigation.about.faq" defaultMessage="Jak korzystać" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/contact">
                <Link to="/about/contact" onClick={this.onClose}>
                  <FormattedMessage id="Navigation.about.contactForm" defaultMessage="Formularz kontaktowy" />
                </Link>
              </MenuItem>
            </StyledSubMenu>
            <StyledMenuItem key="/sites/map" tabIndex="0">
              <Link to="/sites/map" tabIndex="-1" onClick={this.onClose}>
                <Icon type="global" style={{ fontSize: '14px' }} />
                <FormattedMessage id="Navigation.mapView" defaultMessage="Widok mapy" />
              </Link>
            </StyledMenuItem>
            <StyledMenuItem key="/sites/list" tabIndex="0">
              <Link to="/sites/list" tabIndex="-1" onClick={this.onClose}>
                <Icon type="table" style={{ fontSize: '14px' }} />
                <FormattedMessage id="Navigation.listView" defaultMessage="Widok tabelaryczny" />
              </Link>
            </StyledMenuItem>
            <ContentForLoggedInUsers subMenu>
              <StyledSubMenu
                title={(
                  <span>
                    <StyledIcon type="unlock" />
                    <FormattedMessage id="Navigation.admin.subMenu" defaultMessage="Administrator" />
                  </span>
)}
                tabIndex="0"
              >
                <MenuItem key="/admin/sites/new">
                  <Link to="/admin/sites/new" onClick={this.onClose}>
                    <FormattedMessage id="Navigation.admin.addSite" defaultMessage="Dodaj stanowisko" />
                  </Link>
                </MenuItem>
                <MenuItem key="/admin/dictionaries">
                  <Link to="/admin/dictionaries" onClick={this.onClose}>
                    <FormattedMessage id="Navigation.admin.dictionaries" defaultMessage="Słowniki" />
                  </Link>
                </MenuItem>
                <MenuItem key="/sites/list/administration">
                  <Link to="/sites/list/administration" onClick={this.onClose}>
                    <FormattedMessage id="Navigation.admin.administration" defaultMessage="Widok administracji" />
                  </Link>
                </MenuItem>
                <MenuItem key="/sites/list/moderation" disabled={level !== USER_LEVEL.superAdmin}>
                  <Link to={`/sites/list/moderation?status=${String(SITE_STATUS.ready)}`} onClick={this.onClose}>
                    <FormattedMessage id="Navigation.admin.moderation" defaultMessage="Widok moderacji" />
                  </Link>
                </MenuItem>
                <MenuItem key="/sites/list/my-work">
                  <Link
                    to={
                      `/sites/list/my-work?createdByEmail=${String(email)}`
                      + `&status=${String(SITE_STATUS.draft)}`
                      + `&status=${String(SITE_STATUS.ready)}`
                      + `&status=${String(SITE_STATUS.rejected)}`
                    }
                  >
                    <FormattedMessage id="Navigation.admin.work" defaultMessage="Moja praca" />
                  </Link>
                </MenuItem>
                <MenuItem key="details">
                  <a href="http://dev.ala.e-archeologia.pl/" target="_blank" rel="noreferrer noopener">
                    <FormattedMessage id="Navigation.admin.details" defaultMessage="Szczegóły konta" />
                  </a>
                </MenuItem>
              </StyledSubMenu>
            </ContentForLoggedInUsers>
          </Menu>
        </StyledDrawer>
      </React.Fragment>
    );
  }
}

const StyledDrawer = styled(Drawer)`
  margin-top: 70px;
  .ant-drawer-body {
    padding: 0;
  }

  @media (max-width: ${Breakpoints.tablet}) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }

  .ant-drawer-wrapper-body {
    height: calc(100% - 70px) !important;
  }
  .ant-drawer-content {
    background-color: ${darkBlueColor};
  }
  .ant-menu-item {
    height: auto !important;
  }
`;

const StyledSubMenu = styled(SubMenu)`
  font-weight: 600;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 14px 25px 4px !important;
  font-weight: 600;
`;

const StyledIcon = styled(Icon)`
  font-size: ${smallerFontSize} !important;
`;

const StyledHamburgerIcon = styled(Icon)`
  font-size: ${biggerFontSize} !important;
  position: absolute;
  color: white !important;
  top: 0;
  margin: 28px 0;
  right: 20px;
  display: none !important;
  @media (max-width: ${Breakpoints.tablet}) {
    display: flex !important;
  }
`;

export default (withRouter(withUser(injectIntl(NavigationDrawer))): any);
