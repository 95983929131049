// @flow
import * as React from 'react';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
// Components
import SiteDiscoveriesHeader from './SiteDiscoveriesHeader';
import SiteDiscoveriesReasearches from './SiteDiscoveriesReasearches';
// Own types
import type { DicoveryType } from '../../../lib/types';

const { Panel } = Collapse;

type PropTypes = {
  discoveryData: DicoveryType,
  discoveryFormIndex: number,
  discoveryIndex: number,
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  handleRemoveDiscovery: (discoveryLocalId: string) => Promise<void>,
};

class SiteDiscoveries extends React.Component<PropTypes, void> {
  check1lvlValues: (() => boolean) = (): boolean => {
    const { discoveryIndex, form } = this.props;

    const fieldName = `discoveries[${discoveryIndex}]`;
    const discovery = form.getFieldValue(fieldName);

    // check if discovery object contains any of discovery_function, archaeological_culture or chronology value
    if (
      discovery
      && (!!discovery.discovery_function.id || !!discovery.archaeological_culture.id || !!discovery.chronology.id)
    ) {
      return true;
    }

    return false;
  };

  checkIf1lvlUnique: (() => boolean) = (): boolean => {
    const { discoveryIndex, form } = this.props;

    const discoveries = form.getFieldValue('discoveries');
    const checkedDiscovery = discoveries[discoveryIndex];
    const filteredDiscoveries = discoveries.filter(
      (discovery: DicoveryType): boolean => discovery !== checkedDiscovery,
    );

    for (let i = 0; i < filteredDiscoveries.length; i += 1) {
      if (
        checkedDiscovery.discovery_function.id === filteredDiscoveries[i].discovery_function.id
        && checkedDiscovery.archaeological_culture.id === filteredDiscoveries[i].archaeological_culture.id
        && checkedDiscovery.chronology.id === filteredDiscoveries[i].chronology.id
      ) {
        return false;
      }
    }

    return true;
  };

  handle1lvlValidate: (rule: any, value: string, callback: any) => void = (
    rule: any,
    value: string,
    callback: Function,
  ) => {
    const has1lvlValues = this.check1lvlValues();
    const is1lvlUnique = this.checkIf1lvlUnique();

    if (!has1lvlValues) {
      callback(<FormattedMessage id="validation.oneRequired" defaultMessage="Jedno z pól wymagane" />);
    }

    if (!is1lvlUnique) {
      callback(<FormattedMessage id="validation.duplicated" defaultMessage="Takie pole już istnieje" />);
    }

    callback();
  };

  render(): React.Node {
    const {
      discoveryData, form, discoveryIndex, handleRemoveDiscovery, discoveryFormIndex,
    } = this.props;

    return (
      <StyledCollapse>
        <Panel
          header={(
            <SiteDiscoveriesHeader
              data={discoveryData}
              discoveryIndex={discoveryIndex}
              form={form}
              name={`discoveries[${discoveryIndex}]`}
              handleValidate={this.handle1lvlValidate}
              handleRemoveDiscovery={handleRemoveDiscovery}
            />
)}
          forceRender
        >
          <SiteDiscoveriesReasearches
            researches={discoveryData ? discoveryData.researches : []}
            name={`discoveries[${discoveryIndex}]`}
            form={form}
            discoveryIndex={discoveryIndex}
            discoveryFormIndex={discoveryFormIndex}
          />
        </Panel>
      </StyledCollapse>
    );
  }
}

const StyledCollapse = styled(Collapse)`
  margin-bottom: 20px !important;
`;

export default SiteDiscoveries;
