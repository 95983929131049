// @flow
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';
import { intl } from '../IntlGlobalProvider';
import changeLogTranslationsMessages from '../../lib/constants/changeLogTranslationsMessages';

// ///////////////////////////////////////////////
// Here is a video description of this module  //
// https://youtu.be/iAc6Qr_sAXw?t=63          //
// ////////////////////////////////////////////

function mapKeysDeep(obj: Object, cb: Function, isRecursive: boolean = false): Object {
  if (!obj && !isRecursive) {
    return {};
  }

  if (!isRecursive) {
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      return {};
    }
  }

  if (Array.isArray(obj)) {
    return obj.map((item: Object): Object => mapKeysDeep(item, cb, true));
  }

  if (!isPlainObject(obj)) {
    return obj;
  }

  const result = mapKeys(obj, cb);

  return mapValues(result, (value: Object): Object => mapKeysDeep(value, cb, true));
}

const emptyPattern = /null|\[\]|\{\}/;

function translateValue(value: string): string {
  if (emptyPattern.test(JSON.stringify(value))) {
    return intl.formatMessage(changeLogTranslationsMessages.empty);
  }

  if (JSON.stringify(value) === 'true') {
    return intl.formatMessage(changeLogTranslationsMessages.yes);
  }

  if (JSON.stringify(value) === 'false') {
    return intl.formatMessage(changeLogTranslationsMessages.no);
  }

  return value;
}

function translateKey(key: string): string {
  if (key in changeLogTranslationsMessages) {
    return intl.formatMessage(changeLogTranslationsMessages[key]);
  }

  return key;
}

export { mapKeysDeep, translateValue, translateKey };
