// @flow
import gql from 'graphql-tag';

export default (gql`
  query AllSitesForMap($filter: SiteFilter) {
    allSites(filter: $filter) {
      data {
        id
        local_name
        geojson
        ordinal_number_in_azp
        azp {
          value_pl
        }
      }
    }
  }
`: any);
