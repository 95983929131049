// @flow
import gql from 'graphql-tag';

export default (gql`
  query AllSitesExport($columns: [String!]!, $filter: SiteFilter, $order: String) {
    allSitesExport(columns: $columns, filter: $filter, order: $order) {
      csv_url
    }
  }
`: any);
