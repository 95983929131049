// @flow
import * as React from 'react';
import {
  Button, Drawer, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import { required } from '../../../../../lib/formValidation';
import type { SiteType } from '../../../../../lib/types';

type PropsType = {
  form: {
    getFieldDecorator: Function,
    validateFields: Function,
  },
  onClose: Function,
  reject: Function,
  visible: boolean,
};

type StateType = {
  showReject: boolean,
};

class Reject extends React.PureComponent<PropsType, StateType> {
  submit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      form: { validateFields },
      reject,
      onClose,
    } = this.props;
    event.preventDefault();

    validateFields((errors: string, values: SiteType) => {
      if (!errors) {
        reject(values);
        onClose();
      }
    });
  };

  render(): React.Node {
    const {
      onClose,
      form: { getFieldDecorator },
      visible,
    } = this.props;

    return (
      <Drawer
        open={visible}
        zIndex={1002}
        onClose={onClose}
        width={450}
        title={<FormattedMessage id="Reject.title" defaultMessage="Odrzuć szkic" />}
      >
        <Form layout="vertical" onSubmit={this.submit}>
          <Form.Item label={<FormattedMessage id="Reject.reject_reason" defaultMessage="Uzasadnienie" />}>
            {getFieldDecorator('reject_reason', {
              rules: [required],
            })(<Input.TextArea autoSize={{ minRows: 6, maxRows: 30 }} />)}
          </Form.Item>
          <Button block htmlType="submit" type="primary">
            <FormattedMessage id="Reject.reject" defaultMessage="Odrzuć" />
          </Button>
          <Button block onClick={onClose} style={{ marginTop: '5px' }}>
            <FormattedMessage id="general.cancel" defaultMessage="Anuluj" />
          </Button>
        </Form>
      </Drawer>
    );
  }
}

export default (Form.create()(Reject): any);
