// @flow
import * as React from 'react';
import { Menu } from 'antd';
import {
  EyeOutlined, GlobalOutlined, TableOutlined, UnlockOutlined,
} from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import AccessibilitySwitch from '../AccessibilitySwitch';
import NavigationDrawer from './NavigationDrawer';
import LanguageSwitch from '../LanguageSwitch';
import LoginButton from '../LoginButton';
import ContentForLoggedInUsers from '../ContentForLoggedInUsers';
import Logo from '../Logo';
import withUser from '../withUser';
import type { UserType } from '../../lib/types';
import SITE_STATUS from '../../lib/constants/siteStatus';
import USER_LEVEL from '../../lib/constants/userLevel';
import Breakpoints from '../../lib/constants/breakpoints';
import { darkBlueColor } from '../../lib/style/themes';
import withRouter from '../../lib/utils/withRouter';

const MenuItem = Menu.Item;
const { SubMenu } = Menu;

type PropsType = {
  intl: any,
  location: {
    pathname: string,
  },
  user: UserType,
};

const Navigation = (props: PropsType): React.Node => {
  const {
    intl: { locale },
    location: { pathname },
    user: { email, level },
  } = props;

  const siteEditRegExp = pathToRegexp('/admin/sites/:siteId(\\d+)/edit');
  const isSiteEditPath = siteEditRegExp.exec(pathname);

  const siteDetailRegExp = pathToRegexp('sites/:siteId(\\d+)');
  const isSiteDetailPath = siteDetailRegExp.exec(pathname);

  const dictionaryRegExp = pathToRegexp('/admin/dictionary/:dictionaryId(\\d+)');
  const isDictionaryPath = dictionaryRegExp.exec(pathname);

  let selectedKeys: [string | null] = [pathname];

  if (isSiteEditPath || isSiteDetailPath || isDictionaryPath) {
    selectedKeys = [null];
  }

  return (
    <React.Fragment>
      <NavigationWrapper>
        <NavigationContainer>
          <StyledMenuItemLogoMobile to="/" tabIndex="-1">
            <Logo />
          </StyledMenuItemLogoMobile>
          <StyledMainMenu mode="horizontal" defaultSelectedKeys={selectedKeys} selectedKeys={selectedKeys}>
            <StyledMenuItemLogo key="/" tabIndex="0">
              <Link to="/" tabIndex="-1">
                <Logo />
              </Link>
            </StyledMenuItemLogo>
            <StyledSubMenu
              title={(
                <span>
                  <EyeOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                  <FormattedMessage id="Navigation.about.subMenu" defaultMessage="O platformie" />
                </span>
)}
              tabIndex="0"
              key="/about"
            >
              <MenuItem key="/about/experts">
                <Link to="/about/experts">
                  <FormattedMessage id="Navigation.about.experts" defaultMessage="Dla specjalistów" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/finders">
                <Link to="/about/finders">
                  <FormattedMessage id="Navigation.about.finders" defaultMessage="Dla poszukiwaczy" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/developers">
                <Link to="/about/developers">
                  <FormattedMessage id="Navigation.about.developers" defaultMessage="Dla deweloperów" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/conservator">
                <Link to="/about/conservator">
                  <FormattedMessage id="Navigation.about.conservator" defaultMessage="Dane kontaktowe konserwatora" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/faq">
                <Link to="/about/faq">
                  <FormattedMessage id="Navigation.about.faq" defaultMessage="Jak korzystać" />
                </Link>
              </MenuItem>
              <MenuItem key="/about/contact">
                <a
                  href="https://e-archeologialubuska.pl/kontakt/?site=E-Platforma%20Archeologiczna"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FormattedMessage id="Navigation.admin.contact" defaultMessage="Kontakt" />
                </a>
              </MenuItem>
            </StyledSubMenu>
            <StyledMenuItem key="/sites/map" tabIndex="0">
              <Link to="/sites/map" tabIndex="-1">
                <GlobalOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                <FormattedMessage id="Navigation.mapView" defaultMessage="Widok mapy" />
              </Link>
            </StyledMenuItem>
            <StyledMenuItem key="/sites/list" tabIndex="0">
              <Link to="/sites/list" tabIndex="-1">
                <TableOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                <FormattedMessage id="Navigation.listView" defaultMessage="Widok tabelaryczny" />
              </Link>
            </StyledMenuItem>
            <StyledContentForLoggedInUsers subMenu>
              <StyledSubMenu
                title={(
                  <span>
                    <UnlockOutlined style={{ fontSize: '14px', marginRight: '4px' }} />
                    <FormattedMessage id="Navigation.admin.subMenu" defaultMessage="Administrator" />
                  </span>
)}
                tabIndex="0"
              >
                <MenuItem key="/admin/sites/new">
                  <Link to="/admin/sites/new">
                    <FormattedMessage id="Navigation.admin.addSite" defaultMessage="Dodaj stanowisko" />
                  </Link>
                </MenuItem>
                <MenuItem key="/admin/dictionaries">
                  <Link to="/admin/dictionaries">
                    <FormattedMessage id="Navigation.admin.dictionaries" defaultMessage="Słowniki" />
                  </Link>
                </MenuItem>
                <MenuItem key="/sites/list/administration">
                  <Link to="/sites/list/administration">
                    <FormattedMessage id="Navigation.admin.administration" defaultMessage="Widok administracji" />
                  </Link>
                </MenuItem>
                <MenuItem key="/sites/list/moderation" disabled={level !== USER_LEVEL.superAdmin}>
                  <Link to={`/sites/list/moderation?status=${String(SITE_STATUS.ready)}`}>
                    <FormattedMessage id="Navigation.admin.moderation" defaultMessage="Widok moderacji" />
                  </Link>
                </MenuItem>
                <MenuItem key="/sites/list/my-work">
                  <Link
                    to={
                      `/sites/list/my-work?createdByEmail=${String(email)}`
                      + `&status=${String(SITE_STATUS.draft)}`
                      + `&status=${String(SITE_STATUS.ready)}`
                      + `&status=${String(SITE_STATUS.rejected)}`
                    }
                  >
                    <FormattedMessage id="Navigation.admin.work" defaultMessage="Moja praca" />
                  </Link>
                </MenuItem>
                <MenuItem key="details">
                  <a href="http://dev.ala.e-archeologia.pl/" target="_blank" rel="noreferrer noopener">
                    <FormattedMessage id="Navigation.admin.details" defaultMessage="Szczegóły konta" />
                  </a>
                </MenuItem>
              </StyledSubMenu>
            </StyledContentForLoggedInUsers>
          </StyledMainMenu>
          <StyledUserMenu
            mode="horizontal"
            theme="light"
            defaultSelectedKeys={[locale]}
            style={{ backgroundColor: `${darkBlueColor}` }}
          >
            <AccessibilitySwitch />
            <LanguageSwitch />
            <StyledMenuItemLoginButton tabIndex="0" aria-label="Logowanie">
              <LoginButton tabIndex="-1" />
            </StyledMenuItemLoginButton>
          </StyledUserMenu>
        </NavigationContainer>
        <NavigationDrawer />
      </NavigationWrapper>
    </React.Fragment>
  );
};

const NavigationWrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1001;
  background-color: ${darkBlueColor};
  padding: 0 15px !important;

  @media print {
    display: none;
  }
`;

const StyledMenuItemLogoMobile = styled(Link)`
  display: none;
  @media (max-width: ${Breakpoints.tablet}) {
    display: flex;
  }
`;

const StyledContentForLoggedInUsers = styled(ContentForLoggedInUsers)`
  @media (max-width: ${Breakpoints.mobile}) {
    display: none;
  }
}`;

const NavigationContainer = styled.div`
  position: relative;
  max-width: ${Breakpoints.desktop};
  margin: 0 auto;
   @media (max-width: ${Breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledMainMenu = styled((props: any) => <Menu theme="dark" {...props} />)`
  width: calc(100% - 260px);

  li {
    min-height: 74px;
     a, .ant-menu-submenu-title {
      min-height: 74px;
      display: flex !important;
      align-items: center !important;
    }
  }

  @media (max-width: ${Breakpoints.tablet}) {
    display: none !important;
  }
`;

const StyledUserMenu = styled(Menu)`
  position: absolute;
  width: 260px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${darkBlueColor} !important;

  .ant-menu-item, .ant-menu-submenu-title {
    padding-right: 10px !important;
  }

  @media (max-width: ${Breakpoints.tablet}) {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const StyledMenuItemLogo = styled(MenuItem)`
  padding-left: 0 !important;
  background-color: ${darkBlueColor} !important;
`;

const StyledSubMenu = styled(SubMenu)`
  font-weight: 600;
`;

const StyledMenuItem = styled(MenuItem)`
  font-weight: 600;
  @media (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

const StyledMenuItemLoginButton = styled(MenuItem)`
  padding: 13px 0 !important;
  background-color: ${darkBlueColor} !important;
  border-color: ${darkBlueColor} !important;
  @media (max-width: ${Breakpoints.tablet}) {
    display: none !important;
  }
`;

export default (withRouter(withUser(injectIntl(Navigation))): any);
