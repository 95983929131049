// @flow
import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (WrappedComponent: React.ComponentType<any>): Function => (
  props: Object,
): React.Node => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const routerProps = { location, navigate, params };

  return <WrappedComponent {...routerProps} {...props} />;
};

export default withRouter;
