// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import type { QueryRenderProps } from 'react-apollo';
import CURRENT_USER_QUERY from '../../lib/queries/CurrentUser';
import type { UserType } from '../../lib/types';

const withUser = (WrappedComponent: React.ComponentType<any>): Function => (props: Object): React.Node => {
  return (
    <Query query={CURRENT_USER_QUERY} fetchPolicy="cache-first">
      {({ loading, error, data }: QueryRenderProps<{ current_user: UserType }>): React.Node => {
        if (loading || error) return null;

        return <WrappedComponent user={(data && data.current_user) || null} {...props} />;
      }}
    </Query>
  );
};

export default withUser;
