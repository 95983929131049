// @flow
import * as React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const LoadingSpin = ({ tip }: { tip: string }): React.Node => <StyledSpin size="large" tip={tip} />;

const StyledSpin = styled(Spin)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute !important;
  z-index: 99999;
`;

export default LoadingSpin;
