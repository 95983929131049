// @flow
export default function getScaleFactor(zoom: number): number {
  switch (zoom) {
    case 20:
      return 250;
    case 19:
      return 500;
    case 18:
      return 1000;
    case 17:
      return 2000;
    case 16:
      return 5000;
    case 15:
      return 10000;
    case 14:
      return 20000;
    case 13:
      return 40000;
    case 12:
      return 80000;
    case 11:
      return 150000;
    case 10:
      return 300000;
    case 9:
      return 600000;
    case 8:
      return 1200000;
    case 7:
      return 2500000;
    case 6:
      return 5000000;
    case 5:
      return 10000000;
    case 4:
      return 20000000;
    case 3:
      return 40000000;
    case 2:
      return 80000000;
    case 1:
      return 150000000;
    case 0:
      return 300000000;
    default:
      return 5000;
  }
}
