// @flow
import * as React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { accessibility } from '../../../../../lib/constants/themeModes';
import type { ResearchType } from '../../../../../lib/types';
import { biggerFontSize } from '../../../../../lib/style/themes';

type PropTypes = {
  record: ResearchType,
  showDrawer: ResearchType => void,
};

const Buttons = (props: PropTypes): React.Node => {
  const { showDrawer, record } = props;

  return (
    <StyledButtonDetails
      type="primary"
      onClick={(): void => showDrawer(record)}
      onKeyPress={(): void => showDrawer(record)}
    >
      <FormattedMessage id="general.details" defaultMessage="Szczegóły" />
    </StyledButtonDetails>
  );
};

const StyledButtonDetails = styled(Button)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: black !important;
      background-color: yellow !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: white !important;
        background-color: black !important;
        border: 2px solid red !important;
      }
    `};
`;

export default Buttons;
