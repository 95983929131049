// @flow
import * as React from 'react';
import {
  Divider, Row, Col, Card,
} from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import siteDataTranslationsMessages from '../../../../lib/constants/siteDataTranslationsMessages';
import SitesDetailsPrintDiscoveriesReasearches from './SitesDetailsPrintDiscoveriesReasearches';
// Own types
import type { DicoveryType } from '../../../../lib/types';

const SitesDetailsPrintDiscoveries = (props: { discoveries: DicoveryType[], intl: any }): React.Node => {
  const {
    discoveries,
    intl: { locale },
  } = props;

  return discoveries.map(
    (discovery: DicoveryType): React.Node => {
      return (
        <StyledCard key={discovery.id}>
          <StyledRow gutter={16}>
            <Col span={8}>
              <StyledDivider orientation="left" style={{ fontSize: '14px' }}>
                <FormattedMessage {...siteDataTranslationsMessages.discovery_function} />
              </StyledDivider>
              <Row type="flex" justify="start">
                {discovery.discovery_function ? (
                  discovery.discovery_function[`value_${locale}`]
                ) : (
                  <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                )}
              </Row>
            </Col>
            <Col span={8}>
              <StyledDivider orientation="left" style={{ fontSize: '14px' }}>
                <FormattedMessage {...siteDataTranslationsMessages.culture} />
              </StyledDivider>
              <Row type="flex" justify="start">
                {discovery.archaeological_culture ? (
                  discovery.archaeological_culture[`value_${locale}`]
                ) : (
                  <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                )}
              </Row>
            </Col>
            <Col span={8}>
              <StyledDivider orientation="left" style={{ fontSize: '14px' }}>
                <FormattedMessage {...siteDataTranslationsMessages.chronology} />
              </StyledDivider>
              <Row type="flex" justify="start">
                {discovery.chronology ? (
                  discovery.chronology[`value_${locale}`]
                ) : (
                  <FormattedMessage id="general.noData" defaultMessage="Brak danych" />
                )}
              </Row>
            </Col>
          </StyledRow>
          <SitesDetailsPrintDiscoveriesReasearches researches={discovery.researches || []} />
        </StyledCard>
      );
    },
  );
};

const StyledDivider = styled(Divider)`
  &:before {
    width: 0 !important;
  }

  .ant-divider-inner-text {
    padding-left: 0 !important;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 15px !important;
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
`;

export default (injectIntl(SitesDetailsPrintDiscoveries): any);
