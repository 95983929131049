// @flow
import * as React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { accessibility } from '../../../../lib/constants/themeModes';
import type { UserType } from '../../../../lib/types';
import { biggerFontSize } from '../../../../lib/style/themes';

const Buttons = ({ record, user }: { record: Object, user: UserType }): React.Node => {
  const linkToDetails = record.azp !== '-' && record.ordinal_number_in_azp !== '-'
    ? `/sites/${record.azp}/${record.ordinal_number_in_azp}`
    : `/sites/${record.id}`;

  return (
    <React.Fragment>
      <Link to={linkToDetails} tabIndex="-1">
        <StyledButtonDetails type="primary">
          <FormattedMessage id="general.details" defaultMessage="Szczegóły" />
        </StyledButtonDetails>
      </Link>
      {user
        && user.email && (
          <Link to={`/admin/sites/${record.id}/edit`}>
            <StyledButtonEdit>
              <FormattedMessage id="general.edit" defaultMessage="Edytuj" />
            </StyledButtonEdit>
          </Link>
      )}
    </React.Fragment>
  );
};

const StyledButtonEdit = styled(Button)`
  margin-left: 10px;
`;

const StyledButtonDetails = styled(Button)`
  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: black !important;
      background-color: yellow !important;
      border: 2px solid black !important;
      font-size: ${biggerFontSize} !important;

      &:focus {
        color: white !important;
        background-color: black !important;
        border: 2px solid red !important;
      }
    `};
`;

export default Buttons;
