// @flow
import * as React from 'react';
import { withApollo } from 'react-apollo';
import {
  Button, Row, Col, message, Input,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  FormattedMessage, defineMessages, injectIntl,
} from 'react-intl';
import styled, { css, withTheme } from 'styled-components';
// Component
import InputTextareaField from '../../components/InputTextareaField.js';
import BreadCrumb from '../../components/BreadCrumb';
// Mutation
import SEND_CONTACT_MESSAGE from '../../lib/mutations/sendContactMessage';
// Form Validation
import {
  required, max64Characters, max128Characters, max2048Characters, emailFormat,
} from '../../lib/formValidation';
// constans style
import { pageContainerStyle } from '../../lib/style/pageStyle';
import { accessibility, standard } from '../../lib/constants/themeModes';
import { darkBlueColor, biggerFontSize } from '../../lib/style/themes';

const FormItem = Form.Item;

type PropsType = {
  className: string,
  client: any,
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    resetFields: Function,
    setFieldsValue: Function,
    validateFields: Function,
    validateFieldsAndScroll: Function,
  },
  intl: any,
  theme: {
    mode: string,
  },
};

type ContactFormType = {
  email: string,
  message: string,
  name: string,
  subject: string,
};

class ContactForm extends React.PureComponent<PropsType, null> {
  sendEmail = async (values: ContactFormType): Promise<void> => {
    const { client } = this.props;

    await client.mutate({
      mutation: SEND_CONTACT_MESSAGE,
      variables: {
        ...values,
      },
    });
  };

  handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();

    const {
      form: { validateFields, resetFields },
      intl,
    } = this.props;

    const submitSuccessMessage = intl.formatMessage(ContactForm.translations.submitSuccessMessage);
    validateFields((errors: string, values: ContactFormType) => {
      if (!errors) {
        this.sendEmail(values);

        message.success(submitSuccessMessage).then(() => {
          resetFields();
        });
      }
    });
  };

  handleSubmitAccessibilityForm = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();

    const {
      form: { validateFieldsAndScroll, resetFields },
      intl,
    } = this.props;

    const submitSuccessMessage = intl.formatMessage(ContactForm.translations.submitSuccessMessage);

    validateFieldsAndScroll(
      ['name', 'email', 'subject', 'message'],
      {
        first: true,
        force: true,
      },
      (errors: Object, values: ContactFormType) => {
        const errorFieldName = errors && Object.keys(errors)[0];
        const formFieldWithError = document.getElementsByName(errorFieldName)[0];
        if (formFieldWithError) {
          formFieldWithError.focus();
        }

        if (!errors) {
          this.sendEmail(values);

          message.success(submitSuccessMessage).then(() => {
            resetFields();
          });
        }
      },
    );
  };

  static translations = defineMessages({
    submitSuccessMessage: {
      defaultMessage: 'E-mail został prawidłowo wysłany',
      id: 'ContactForm.successMessage',
    },
  });

  render(): React.Node {
    const {
      className,
      form: { getFieldDecorator, setFieldsValue, getFieldValue },
      theme: { mode },
    } = this.props;

    const onSubmitMethodWithTheme = mode === standard ? this.handleSubmit : this.handleSubmitAccessibilityForm;

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['about', 'contact']} />
        <StyledPageContainer>
          <Form onSubmit={onSubmitMethodWithTheme} className={className}>
            <StyledFormFields>
              <Row gutter={24}>
                <Col lg={12}>
                  <FormItem label={<FormattedMessage id="ContactForm.nameLabel" defaultMessage="Imię i Nazwisko" />}>
                    {getFieldDecorator('name', {
                      rules: [required, max64Characters],
                    })(<Input name="name" />)}
                  </FormItem>
                </Col>
                <Col lg={12}>
                  <FormItem label={<FormattedMessage id="ContactForm.emailLabel" defaultMessage="E-mail" />}>
                    {getFieldDecorator('email', {
                      rules: [required, emailFormat],
                    })(<Input name="email" />)}
                  </FormItem>
                </Col>
                <Col lg={24}>
                  <FormItem label={<FormattedMessage id="ContactForm.subjectLabel" defaultMessage="Temat" />}>
                    {getFieldDecorator('subject', {
                      rules: [required, max128Characters],
                    })(<Input name="subject" />)}
                  </FormItem>
                </Col>
                <Col lg={24}>
                  <InputTextareaField
                    label={<FormattedMessage id="ContactForm.messageLabel" defaultMessage="Wiadomość" />}
                    name="message"
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    autoSize={{ minRows: 6 }}
                    validationRules={[required, max2048Characters]}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="end">
                <Button type="primary" size="large" htmlType="submit">
                  <FormattedMessage id="general.send" defaultMessage="Wyślij" />
                </Button>
              </Row>
            </StyledFormFields>
          </Form>
        </StyledPageContainer>
      </React.Fragment>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};
`;

const StyledFormFields = styled.div`
  background-color: #fff;
  padding: 24px 32px !important;
  margin-bottom: 20px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor};
    `};
`;

const StyledContactForm = styled(ContactForm)`
  flex: 1;

  .ant-legacy-form-item-label {
    text-align: left;
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      .ant-input {
        height: 46px !important;
        font-size: ${biggerFontSize};
        color: black;
        background-color: white !important;
        border: 2px solid yellow;
      }

      .ant-input:focus {
        border-color: red !important;
        box-shadow: 0 0 0 2px red !important;
      }

      .ant-legacy-form-item-label label {
        color: yellow;
        font-size: ${biggerFontSize};
      }

      .ant-btn-primary {
        color: black;
        background-color: yellow;
        border: 2px solid black;
        font-size: ${biggerFontSize};
      }

      .ant-btn-primary:focus {
        color: white;
        background-color: black;
        border: 2px solid yellow;
      }
    `};
`;

export default (withApollo(withTheme(injectIntl(Form.create()(StyledContactForm)))): React.ComponentType<mixed>);
