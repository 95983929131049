// @flow
import * as React from 'react';
import { Query } from 'react-apollo';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import type { QueryRenderProps } from 'react-apollo';
import turfFlatten from '@turf/flatten';
import {
  Card, Col, Row, Empty,
} from 'antd';
import { Helmet } from 'react-helmet';
import GET_SITE from '../../../lib/queries/getSite';
import { pageContainerStyle } from '../../../lib/style/pageStyle';
import type { SiteType, NotesType, UserType } from '../../../lib/types';
import siteDataTranslationsMessages from '../../../lib/constants/siteDataTranslationsMessages';
import { accessibility } from '../../../lib/constants/themeModes';
import {
  darkBlueColor,
  smallerFontSize,
  biggerFontSize,
  generalFontSize,
  darkGreyColor,
} from '../../../lib/style/themes';
import withRouter from '../../../lib/utils/withRouter';

import LoadingSpin from '../../../components/LoadingSpin';
import EditableMap from '../../../components/EditableMap';
import SitesDetailsCtaSection from './SitesDetailsCtaSection';
import ContentForLoggedInUsers from '../../../components/ContentForLoggedInUsers';
import RenderButtonInBredcrumb from '../../../components/RenderButtonInBredcrumb';
import BreadCrumb from '../../../components/BreadCrumb';
import withUser from '../../../components/withUser';
import SitesDetailsDiscoveries from './SitesDetailsDiscoveries';
import USER_LEVEL from '../../../lib/constants/userLevel';
import SITE_STATUS from '../../../lib/constants/siteStatus';
import AcceptRejectBar from './AcceptRejectBar';
import logo from '../../../lib/images/logo.svg';
import TranslatedValue from '../../../components/TranslatedValue/TranslatedValue';
import LinkToExternal from '../../../components/LinkToExternal';
import NoDataBecauseError from '../../../components/NoDataBecauseError';

type PropsType = {
  params: {
    azp_valuePl?: string,
    ordinalNumberInAzp?: number,
    siteId?: number,
  },
  user: ?UserType,
};

class SitesDetails extends React.PureComponent<PropsType, void> {
  static prepareNotes = (notes: NotesType[] | []): React.Node => {
    if (!notes || notes.length < 1) return <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;

    return notes.map(
      (note: NotesType): React.Node => {
        const {
          created_at: createdAt, content, id, created_by_email: author,
        } = note;

        const title = (
          <StyledNoteTitle>
            <span>{author}</span>
            <span>{createdAt.toString()}</span>
          </StyledNoteTitle>
        );

        return (
          <StyledCard title={title} key={id}>
            {content}
          </StyledCard>
        );
      },
    );
  };

  static setChecbkoxValue = (isChecked: boolean): React.Node => {
    return isChecked ? (
      <FormattedMessage id="general.yes" defaultMessage="Tak" />
    ) : (
      <FormattedMessage id="general.no" defaultMessage="Nie" />
    );
  };

  mapRef: any;

  constructor(props: PropsType) {
    super(props);
    this.mapRef = React.createRef();
  }

  print = () => {
    this.mapRef.current.prepareMapForPrint();
    setTimeout(window.print, 500);
  };

  render(): React.Node {
    const {
      params: { siteId, azp_valuePl, ordinalNumberInAzp: ordinalNumberInAzpUrl },
      user,
    } = this.props;

    return (
      <React.Fragment>
        <BreadCrumb breadcrumbs={['sitesList', 'siteDetails']} />
        <StyledPageContainer>
          <Query
            query={GET_SITE}
            variables={{
              id: siteId,
              azp_valuePl,
              ordinalNumberInAzp: ordinalNumberInAzpUrl,
              isAdmin: user && !!user.email,
            }}
          >
            {({ loading, error, data }: QueryRenderProps<{ site: SiteType }>): React.Node => {
              if (loading) return <LoadingSpin tip="Loading..." />;
              if (error) return <NoDataBecauseError />;
              if (!data || !data.site) return <Empty />;

              const {
                id,
                local_name: localName,
                voivodeship,
                county,
                community,
                city,
                azp,
                geojson,
                ordinal_number_in_azp: ordinalNumberInAzp,
                ordinal_number_in_city: ordinalNumberInCity,
                keza_author: kezaAuthor,
                keza_created_at: kezaCreatedAt,
                physicalgeographical_unit: physicalgeographicalUnit,
                conservator_notes: conservatorNotes,
                monuments_register_number: monumentsRegisterNumber,
                culture_park: culturePark,
                monuments_register_addition_date: monumentsRegisterAdditionDate,
                zoning_plan: zoningPlan,
                hazards,
                conservator_detailed_inventory: conservatorDetailedInventory,
                conservator_excavation_research: conservatorExcavationResearch,
                conservator_administrative_inventory: conservatorAdministrativeInventory,
                notes,
                discoveries,
              } = data.site;

              const geojsonData = geojson ? turfFlatten(geojson) : null;
              const preparedNotes = notes && SitesDetails.prepareNotes(notes);
              const emptySiteValue = <FormattedMessage id="general.emptyValue" defaultMessage="brak" />;

              const showAcceptRejectBar = user
                && user.level === USER_LEVEL.superAdmin
                && (data.site && data.site.status === SITE_STATUS.ready);

              return (
                <React.Fragment>
                  <Helmet>
                    <title>
                      {(azp && azp.value_pl) || '-'} / {ordinalNumberInAzp || '-'} - {String(localName)}
                    </title>
                  </Helmet>
                  <RenderButtonInBredcrumb>
                    <SitesDetailsCtaSection siteId={id} />
                  </RenderButtonInBredcrumb>
                  <StyledHeader tabIndex="0">
                    <img src={logo} alt="Logo" />
                    <span>{localName}</span>
                  </StyledHeader>
                  <StyledCard
                    bordered={false}
                    title={(
                      <FormattedMessage
                        id="SitesDetails.sectionLocationOnMapTitle"
                        defaultMessage="Lokalizacja na mapie"
                      />
)}
                    tabIndex="0"
                  >
                    <EditableMap ref={this.mapRef} geojsonData={geojsonData} hideEditControl hideGeocoder />
                  </StyledCard>
                  <Row gutter={12}>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={<FormattedMessage id="SitesDetails.sectionLocationTitle" defaultMessage="Lokalizacja" />}
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                        tabIndex="0"
                      >
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.voivodeship} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(voivodeship && <TranslatedValue value={voivodeship} />) || emptySiteValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.county} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(county && <TranslatedValue value={county} />) || emptySiteValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.community} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {(community && <TranslatedValue value={community} />) || emptySiteValue}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.azp} />
                        </StyledDetailsName>
                        <StyledDetail>: {(azp && azp.value_pl) || emptySiteValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.ordinal_number_in_azp} />
                        </StyledDetailsName>
                        <StyledDetail>: {ordinalNumberInAzp || emptySiteValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.city} />
                        </StyledDetailsName>
                        <StyledDetail>: {(city && <TranslatedValue value={city} />) || emptySiteValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.ordinal_number_in_city} />
                        </StyledDetailsName>
                        <StyledDetail>: {ordinalNumberInCity || emptySiteValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.physicalgeographical_unit} />
                        </StyledDetailsName>
                        <StyledDetail>
                          :{' '}
                          {(physicalgeographicalUnit && <TranslatedValue value={physicalgeographicalUnit} />)
                            || emptySiteValue}
                        </StyledDetail>
                        <br />
                        <StyledDetail>
                          { (azp && azp.value_pl && ordinalNumberInAzp) && (
                            <LinkToExternal
                              serviceUrl={process.env.REACT_APP_ARCHIVE_ADRESS}
                              linkPattern="artifacts/list/archaeological"
                              searchParams={{
                                // eslint-disable-next-line camelcase
                                'azp,valuePl': azp_valuePl,
                                ordinalNumberInAzp,
                              }}
                            >
                              <FormattedMessage
                                id="SitesDetails.showArchiveFromThisSite"
                                defaultMessage="Zobacz skatalogowane zabytki z tego wykopaliska"
                              />
                            </LinkToExternal>
                          )}
                        </StyledDetail>
                      </StyledCard>
                    </Col>
                    <Col lg={12}>
                      <StyledCard
                        bordered={false}
                        title={(
                          <FormattedMessage
                            id="SitesDetails.sectionKEZATitle"
                            defaultMessage="Wprowadzono na podstawie karty KEZA"
                          />
)}
                        headStyle={{ padding: '0 32px' }}
                        bodyStyle={{ padding: '24px 32px' }}
                        tabIndex="0"
                      >
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.keza_author} />
                        </StyledDetailsName>
                        <StyledDetail>: {kezaAuthor || emptySiteValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.keza_created_at} />
                        </StyledDetailsName>
                        <StyledDetail>: {kezaCreatedAt || emptySiteValue}</StyledDetail>
                      </StyledCard>
                    </Col>
                  </Row>

                  <StyledCard
                    bordered={false}
                    title={(
                      <FormattedMessage
                        id="SitesDetails.sectionProtectionThreatsTitle"
                        defaultMessage="Ochrona i zagrożenia"
                      />
)}
                    tabIndex="0"
                  >
                    <Row gutter={52}>
                      <Col lg={12} style={{ marginBottom: '24px' }}>
                        <StyledParagraph>
                          <FormattedMessage
                            id="SitesDetails.sectionProtectionThreats.conservatorConclusions"
                            defaultMessage="Wnioski konserwatora zabytków"
                          />
                        </StyledParagraph>
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.conservator_detailed_inventory} />
                        </StyledDetailsName>
                        <StyledDetail>: {SitesDetails.setChecbkoxValue(!!conservatorDetailedInventory)}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.conservator_excavation_research} />
                        </StyledDetailsName>
                        <StyledDetail>: {SitesDetails.setChecbkoxValue(!!conservatorExcavationResearch)}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.conservator_administrative_inventory} />
                        </StyledDetailsName>
                        <StyledDetail>
                          : {SitesDetails.setChecbkoxValue(!!conservatorAdministrativeInventory)}
                        </StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.hazards} />
                        </StyledDetailsName>
                        <StyledDetail>: {hazards || emptySiteValue}</StyledDetail>
                      </Col>
                      <Col lg={12} style={{ marginBottom: '24px' }}>
                        <StyledParagraph>
                          <FormattedMessage
                            id="SitesDetails.sectionProtectionThreats.currentProtection"
                            defaultMessage="Aktualna ochrona"
                          />
                        </StyledParagraph>
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.monuments_register_number} />
                        </StyledDetailsName>
                        <StyledDetail>: {monumentsRegisterNumber || emptySiteValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.monuments_register_addition_date} />
                        </StyledDetailsName>
                        <StyledDetail>: {monumentsRegisterAdditionDate || emptySiteValue}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.culture_park} />
                        </StyledDetailsName>
                        <StyledDetail>: {SitesDetails.setChecbkoxValue(!!culturePark)}</StyledDetail>
                        <br />
                        <StyledDetailsName>
                          <FormattedMessage {...siteDataTranslationsMessages.zoning_plan} />
                        </StyledDetailsName>
                        <StyledDetail>: {SitesDetails.setChecbkoxValue(!!zoningPlan)}</StyledDetail>
                      </Col>
                    </Row>
                  </StyledCard>
                  <StyledCard
                    bordered={false}
                    title={<FormattedMessage {...siteDataTranslationsMessages.conservator_notes} />}
                    tabIndex="0"
                  >
                    <StyledDetail>{conservatorNotes || emptySiteValue}</StyledDetail>
                  </StyledCard>
                  <StyledCard
                    bordered={false}
                    title={<FormattedMessage id="SitesDetails.discoveries" defaultMessage="Badania i znaleziska" />}
                    tabIndex="0"
                  >
                    <SitesDetailsDiscoveries discoveries={discoveries} />
                  </StyledCard>
                  <ContentForLoggedInUsers>
                    <StyledCard
                      bordered={false}
                      title={<FormattedMessage id="SitesDetails.notes" defaultMessage="Notatki" />}
                    >
                      {preparedNotes}
                    </StyledCard>
                    {showAcceptRejectBar && (
                      <StyledCard>
                        <AcceptRejectBar siteId={id} />
                      </StyledCard>
                    )}
                  </ContentForLoggedInUsers>
                </React.Fragment>
              );
            }}
          </Query>
        </StyledPageContainer>
      </React.Fragment>
    );
  }
}

const StyledPageContainer = styled.div`
  ${pageContainerStyle};

  @media print {
    .ant-col-lg-12 {
      width: 50% !important;
      float: left;
    }
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px !important;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor} !important;

      .ant-card-head-title {
        color: yellow !important;
        font-size: 20px;
      }

      &:focus {
        border: 3px solid red !important;
      }
    `};

  @media print {
    padding: 0 !important;
    page-break-inside: avoid;

    .ant-card-bordered {
      border: 0;
    }

    .ant-card-head,
    .ant-card-wider-padding,
    .ant-card-body {
      padding: 0 !important;
    }

    .ant-card-head {
      min-height: 0;
      margin-bottom: 10px;
      page-break-after: avoid;
      font-size: ${smallerFontSize} !important;
    }

    .ant-card-body {
      font-size: 12px !important;
    }

    .ant-card-head-title {
      padding: 0;
    }

    .ant-card-body > .ant-card > .ant-card-head {
      border: 0;
    }
  }
`;

const StyledNoteTitle = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-size: ${smallerFontSize};
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
`;

const StyledParagraph = styled.p`
  color: ${darkGreyColor};
  font-size: ${generalFontSize};
  font-weight: 500;
  text-decoration: underline;

  @media print {
    page-break-after: avoid;
    page-break-before: avoid;
    font-size: ${smallerFontSize};
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
    `};
`;

const StyledHeader = styled.h2`
  background-color: #fff;
  line-height: 50px;
  margin-bottom: 20px;
  text-align: center;

  img {
    display: none;
  }

  @media print {
    margin: 0;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    background: ${darkBlueColor};
    color: white;
    margin-bottom: 10px;

    img {
      display: block;
      float: left;
      width: 2cm;
      padding: 10px;
    }

    span {
      display: block;
      text-align: center;
      margin: 0 auto;
      padding: 10px;
    }
  }

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      background-color: ${darkBlueColor} !important;
      color: yellow;

      &:focus {
        border: 3px solid red;
      }
    `};
`;

const StyledDetail = styled.span`
  line-height: 30px;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
    `};
`;

const StyledDetailsName = styled.span`
  font-weight: 600;

  ${({ theme }: any): any => theme.mode === accessibility
    && css`
      color: yellow;
      font-size: ${biggerFontSize};
    `};
`;

export default (withRouter(withUser(SitesDetails)): any);
