// @flow
import { defineMessages } from 'react-intl';

const siteDataTranslationsMessages: any = defineMessages({
  id: {
    defaultMessage: 'Id',
    id: 'site.id',
  },
  local_name: {
    defaultMessage: 'Nazwa stanowiska',
    id: 'site.localName',
  },
  voivodeship: {
    defaultMessage: 'Województwo',
    id: 'site.voivodeship',
  },
  county: {
    defaultMessage: 'Powiat',
    id: 'site.county',
  },
  community: {
    defaultMessage: 'Gmina',
    id: 'site.community',
  },
  city: {
    defaultMessage: 'Miejscowość',
    id: 'site.city',
  },
  azp: {
    defaultMessage: 'AZP',
    id: 'site.azp',
  },
  physicalgeographical_unit: {
    defaultMessage: 'Jednostka fizycznogeograficzna',
    id: 'site.physicalgeographical_unit',
  },
  ordinal_number_in_city: {
    defaultMessage: 'Nr w miejscowości',
    id: 'site.ordinalNumberInCity',
  },
  ordinal_number_in_azp: {
    defaultMessage: 'Nr na AZP',
    id: 'site.ordinalNumberInAzp',
  },
  cta: {
    defaultMessage: 'Przyciski',
    id: 'site.cta',
  },
  conservator_notes: {
    defaultMessage: 'Oznaczenia Konserwatora Zabytków',
    id: 'site.conservator_notes',
  },
  hazards: {
    defaultMessage: 'Zagrożenia',
    id: 'site.hazards',
  },
  conservator_detailed_inventory: {
    defaultMessage: 'Niezbędna szczegółowa inwentaryzacja',
    id: 'site.conservator_detailed_inventory',
  },
  conservator_excavation_research: {
    defaultMessage: 'Niezbędne badania wykopaliskowe',
    id: 'site.conservator_excavation_research',
  },
  conservator_administrative_inventory: {
    defaultMessage: 'Niezbędna inwentaryzacja administracyjna',
    id: 'site.conservator_administrative_inventory',
  },
  monuments_register_number: {
    defaultMessage: 'Nr rejestru zabytków',
    id: 'site.monuments_register_number',
  },
  monuments_register_addition_date: {
    defaultMessage: 'Data wpisu do rejestru zabytków',
    id: 'site.monuments_register_addition_date',
  },
  culture_park: {
    defaultMessage: 'Park kulturowy',
    id: 'site.culture_park',
  },
  zoning_plan: {
    defaultMessage: 'Plan zagospodarowania przestrzennego',
    id: 'site.zoning_plan',
  },
  keza_author: {
    defaultMessage: 'Wykonanej przez',
    id: 'site.keza_author',
  },
  keza_created_at: {
    defaultMessage: 'W dniu',
    id: 'site.keza_created_at',
  },
  status: {
    defaultMessage: 'Status',
    id: 'site.status',
  },
  created_at: {
    defaultMessage: 'Data utworzenia szkicu',
    id: 'site.created_at',
  },
  updated_at: {
    defaultMessage: 'Data aktualizacji',
    id: 'site.updated_at',
  },
  published_at: {
    defaultMessage: 'Data publikacji',
    id: 'site.published_at',
  },
  created_by_email: {
    defaultMessage: 'Autor szkicu',
    id: 'site.created_by_email',
  },
  chronology: {
    defaultMessage: 'Chronologia',
    id: 'site.chronology',
  },
  discovery_function: {
    defaultMessage: 'Funkcja',
    id: 'site.discovery_function',
  },
  culture: {
    defaultMessage: 'Kultura',
    id: 'site.culture',
  },
  archaeological_culture: {
    defaultMessage: 'Kultura archeologiczna',
    id: 'site.archaeological_culture',
  },
  research_type: {
    defaultMessage: 'Typ badania',
    id: 'site.research_type',
  },
  storage_location: {
    defaultMessage: 'Miejsce przechowywania',
    id: 'site.storage_location',
  },
});

export default siteDataTranslationsMessages;
