// @flow
import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import queryString from 'query-string';
import SitesFilters from './SitesFilters';
import { splitDatesInFilterObject } from './SitesFiltersUtils';
import type { SiteFilterUrlType } from '../../../../lib/types';
import withRouter from '../../../../lib/utils/withRouter';

type PropsType = {
  form: Object,
  location: Location,
  navigate: any,
  toggleFullWidthTable: () => void,
};

type StateType = {
  filter: SiteFilterUrlType,
};

class SitesFiltersContainer extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super();

    const {
      location: { search },
    } = props;
    const filter = queryString.parse(search);

    this.state = { filter };
  }

  filterSites = (_filter: SiteFilterUrlType) => {
    const { navigate } = this.props;
    // dataRangeField return [moment, moment], to store in URL and for backen we need two fields
    // as something_date_gte and something_date_lte.
    const filter = splitDatesInFilterObject(_filter);

    this.setState({ filter });
    const search = queryString.stringify(filter, { encode: false });
    navigate({ search });
  };

  render(): React$Node {
    const { filter } = this.state;
    const { toggleFullWidthTable } = this.props;

    return (
      <SitesFilters
        {...this.props}
        toggleFullWidthTable={toggleFullWidthTable}
        filterSites={this.filterSites}
        filter={filter}
      />
    );
  }
}

export default (Form.create()(withRouter(SitesFiltersContainer)): any);
