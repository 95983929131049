// @flow
import type { MuseumCollectionType } from '../../types';

const parseMuseumCollections = (museumCollections: MuseumCollectionType[]): MuseumCollectionType[] => {
  return museumCollections.map(
    (museumCollection: MuseumCollectionType): MuseumCollectionType => {
      return { ...museumCollection, key: museumCollection.id };
    },
  );
};

export default parseMuseumCollections;
