// @flow
import * as React from 'react';
import { Button as ButtonAnt, Collapse, Badge } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
// Components
import MuseumCollections from './MuseumCollections';
// Mutation
import REMOVE_MUSEUM_COLLECTION from '../../../../../../lib/mutations/removeMuseumCollection';
// Own Types
import type { MuseumCollectionType } from '../../../../../../lib/types';

const { Panel } = Collapse;

type StateTypes = {
  lastMuseumCollectionLocalId: number,
};

type PropTypes = {
  client: any,
  discoveryFormIndex: number,
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  museumCollections: MuseumCollectionType[],
  name: string,
  researchFormIndex: number,
};

class MuseumCollectionsContainer extends React.Component<PropTypes, StateTypes> {
  state = {
    lastMuseumCollectionLocalId: -1,
  };

  componentDidMount() {
    const { museumCollections } = this.props;

    if (museumCollections && museumCollections.length > 0) {
      const lastMuseumCollectionLocalId = museumCollections[museumCollections.length - 1].localId;

      this.setState({
        lastMuseumCollectionLocalId,
      });
    }
  }

  handleAddNewMuseumCollection = () => {
    const {
      form, museumCollections, discoveryFormIndex, researchFormIndex,
    } = this.props;

    const { lastMuseumCollectionLocalId } = this.state;

    const discoveriesForm = form.getFieldValue('discoveriesForm');

    const newMuseumCollectionLocalId = lastMuseumCollectionLocalId + 1;

    discoveriesForm[discoveryFormIndex].researches[researchFormIndex].museum_collections = [
      ...(museumCollections || []),
      { localId: newMuseumCollectionLocalId, dictionary_id: 10 },
    ];

    form.setFieldsValue({
      discoveriesForm,
    });
    this.setState({ lastMuseumCollectionLocalId: newMuseumCollectionLocalId });
  };

  handleRemoveMuseumCollection = async (museumCollectionToRemove: MuseumCollectionType): Promise<void> => {
    const {
      form, client, museumCollections, discoveryFormIndex, researchFormIndex,
    } = this.props;

    const filteredMuseumCollection = museumCollections.filter(
      (museumCollection: MuseumCollectionType): boolean => {
        return museumCollection.localId !== museumCollectionToRemove.localId;
      },
    );

    const discoveriesForm = form.getFieldValue('discoveriesForm');
    discoveriesForm[discoveryFormIndex].researches[researchFormIndex].museum_collections = filteredMuseumCollection;

    if (museumCollectionToRemove.id) {
      await client.mutate({
        mutation: REMOVE_MUSEUM_COLLECTION,
        variables: {
          id: museumCollectionToRemove.id,
        },
      });
    }

    form.setFieldsValue({
      discoveriesForm,
    });
  };

  checkIfZielonaGora = (fieldName: string): boolean => {
    const { form } = this.props;
    const zielonaGoraId = '2159';

    const museumCollectionField = form.getFieldValue(fieldName);

    if (museumCollectionField && museumCollectionField.storage_location.id === zielonaGoraId) {
      return true;
    }

    return false;
  };

  render(): React.Node {
    const {
      name,
      museumCollections,
      form: { getFieldDecorator, setFieldsValue, validateFields },
    } = this.props;

    const numbeOfMuseumCollections = museumCollections ? museumCollections.length : null;

    return (
      <StyledCollapse>
        <Panel
          header={(
            <FormattedMessage id="MuseumCollectionsContainer.title" defaultMessage="Zbiory">
              {(formatedText: string): React.Node => (
                <span>
                  {formatedText} <StyledBadge count={numbeOfMuseumCollections} style={{ backgroundColor: '#1890ff' }} />
                </span>
              )}
            </FormattedMessage>
)}
          forceRender
        >
          {museumCollections && (
            <MuseumCollections
              museumCollections={museumCollections}
              checkIfZielonaGora={this.checkIfZielonaGora}
              getFieldDecorator={getFieldDecorator}
              setFieldsValue={setFieldsValue}
              validateFields={validateFields}
              handleRemoveMuseumCollection={this.handleRemoveMuseumCollection}
              name={name}
            />
          )}
          <StyledButtonAnt type="primary" onClick={(): void => this.handleAddNewMuseumCollection()}>
            <FormattedMessage
              id="MuseumCollectionsContainer.addNewMuseumCollection"
              defaultMessage="Dodaj nowy zbiór"
            />
          </StyledButtonAnt>
        </Panel>
      </StyledCollapse>
    );
  }
}

const StyledButtonAnt = styled(ButtonAnt)`
  width: 100%;
`;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 15px !important;
`;

const StyledBadge = styled(Badge)`
  padding: 0 0 4px 2px !important;
`;

export default (withApollo(MuseumCollectionsContainer): any);
