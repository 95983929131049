// @flow
import * as React from 'react';
import {
  Input, Row, Col, Popconfirm, Button as ButtonAnt,
} from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';
import {
  FormattedMessage, injectIntl, defineMessages,
} from 'react-intl';
// Components
import DictionarySelect from '../../../DictionarySelect';
// Own types
import type { DicoveryType } from '../../../../lib/types';

import dictionariesId from '../../../../lib/constants/dictionariesId';

const FormItem = Form.Item;

type PropTypes = {
  data: DicoveryType,
  form: {
    getFieldDecorator: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  handleRemoveDiscovery: (discoveryIndex: number) => Promise<void>,
  handleValidate: () => Promise<void>,
  intl: any,
  name: string,
};

class SiteDiscoveriesHeader extends React.Component<PropTypes, void> {
  static translations = defineMessages({
    removeQuestion: {
      defaultMessage: 'Czy chcesz usunąć badanie?',
      id: 'SiteDiscoveriesHeader.removeQuestion',
    },
    yes: {
      defaultMessage: 'Tak',
      id: 'general.yes',
    },
    no: {
      defaultMessage: 'Nie',
      id: 'general.no',
    },
  });

  render(): React.Node {
    const { functionId, archeoCulturesId, chronologyId } = dictionariesId;

    const {
      data,
      name,
      handleRemoveDiscovery,
      handleValidate,
      intl,
      form: { getFieldDecorator, validateFields, setFieldsValue },
    } = this.props;

    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={6} onClick={(event: SyntheticEvent<HTMLButtonElement>): void => event.stopPropagation()}>
            <DictionarySelect
              label={<FormattedMessage id="SiteDiscoveriesHeader.function" defaultMessage="Funkcja" />}
              dictionaryId={functionId}
              name={`${name}[discovery_function][id]`}
              getFieldDecorator={getFieldDecorator}
              validateFields={validateFields}
              setFieldsValue={setFieldsValue}
              initialFieldValue={data ? data.discovery_function : null}
              validationRules={[{ validator: handleValidate }]}
              relatedFielsdName={[`${name}[archaeological_culture][id]`, `${name}[chronology][id]`]}
            />
          </Col>
          <Col span={6} onClick={(event: SyntheticEvent<HTMLButtonElement>): void => event.stopPropagation()}>
            <DictionarySelect
              label={<FormattedMessage id="SiteDiscoveriesHeader.culture" defaultMessage="Kultura" />}
              dictionaryId={archeoCulturesId}
              name={`${name}[archaeological_culture][id]`}
              getFieldDecorator={getFieldDecorator}
              validateFields={validateFields}
              setFieldsValue={setFieldsValue}
              initialFieldValue={data ? data.archaeological_culture : null}
              validationRules={[{ validator: handleValidate }]}
              relatedFielsdName={[`${name}[discovery_function][id]`, `${name}[chronology][id]`]}
            />
          </Col>
          <Col span={6} onClick={(event: SyntheticEvent<HTMLButtonElement>): void => event.stopPropagation()}>
            <DictionarySelect
              label={<FormattedMessage id="SiteDiscoveriesHeader.chronology" defaultMessage="Chronologia" />}
              dictionaryId={chronologyId}
              name={`${name}[chronology][id]`}
              getFieldDecorator={getFieldDecorator}
              validateFields={validateFields}
              setFieldsValue={setFieldsValue}
              initialFieldValue={data ? data.chronology : null}
              validationRules={[{ validator: handleValidate }]}
              relatedFielsdName={[`${name}[archaeological_culture][id]`, `${name}[discovery_function][id]`]}
            />
          </Col>
          {data.id && (
            <HiddenFormItem label="id">
              {getFieldDecorator(`${name}[id]`, {
                initialValue: data.id,
              })(<Input disabled />)}
            </HiddenFormItem>
          )}
          <HiddenFormItem label="localId">
            {getFieldDecorator(`${name}[localId]`, {
              initialValue: data.localId,
            })(<Input disabled />)}
          </HiddenFormItem>
          <Col span={2} onClick={(event: SyntheticEvent<HTMLButtonElement>): void => event.stopPropagation()}>
            <FormattedMessage id="general.remove" defaultMessage="Usuń">
              {(buttonText: string): React.Node => (
                <Popconfirm
                  title={intl.formatMessage(SiteDiscoveriesHeader.translations.removeQuestion)}
                  onConfirm={(): Promise<void> => handleRemoveDiscovery(data.localId)}
                  okText={intl.formatMessage(SiteDiscoveriesHeader.translations.yes)}
                  cancelText={intl.formatMessage(SiteDiscoveriesHeader.translations.no)}
                >
                  <ButtonAnt>{buttonText}</ButtonAnt>
                </Popconfirm>
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const HiddenFormItem = styled(FormItem)`
  display: none !important;
`;

export default (injectIntl(SiteDiscoveriesHeader): any);
