// @flow
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { Query, withApollo } from 'react-apollo';
import type { QueryRenderProps } from 'react-apollo';
import GET_THEME_QUERY from '../../lib/queries/getTheme';

type PropsType = {
  children: React.Node,
};

const ConfiguredThemeProvider = (props: PropsType): React.Node => {
  const { children } = props;

  return (
    <Query query={GET_THEME_QUERY} fetchPolicy="cache-first">
      {({ error, data }: QueryRenderProps<{ theme: Object }>): React.Node => {
        if (error) return 'Unknow error on them provider';
        if (!data || (!data.theme || !data.theme.mode)) return null;

        return <ThemeProvider theme={data.theme}>{children}</ThemeProvider>;
      }}
    </Query>
  );
};

export default (withApollo(ConfiguredThemeProvider): any);
