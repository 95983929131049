// @flow
import { featureCollection, feature } from '@turf/helpers';
import type { LeafletBoundsType, GeoJsonType, SiteType } from '../../../lib/types';

function makeGeojsonFilterFromBounds(bounds: LeafletBoundsType): mixed {
  return {
    type: 'GeometryCollection',
    geometries: [
      {
        type: 'Polygon',
        coordinates: [
          [
            [bounds._southWest.lng, bounds._northEast.lat],
            [bounds._northEast.lng, bounds._northEast.lat],
            [bounds._northEast.lng, bounds._southWest.lat],
            [bounds._southWest.lng, bounds._southWest.lat],
            [bounds._southWest.lng, bounds._northEast.lat],
          ],
        ],
      },
    ],
  };
}

function areaInArea(bounds: LeafletBoundsType, currentArea: LeafletBoundsType): boolean {
  // Check if area is not too much to the right
  if (bounds._northEast.lng > currentArea._northEast.lng) return false;

  // Check if area is not too much to the top
  if (bounds._northEast.lat > currentArea._northEast.lat) return false;

  // Check if area is not too much to the left
  if (bounds._southWest.lng < currentArea._southWest.lng) return false;

  // Check if area is not too much to the bottom
  if (bounds._southWest.lat < currentArea._southWest.lat) return false;

  return true;
}

function makeBoundsBigger(bounds: LeafletBoundsType, multiplier: number): LeafletBoundsType {
  const xDistance = bounds._northEast.lng - bounds._southWest.lng;
  const yDistance = bounds._northEast.lat - bounds._southWest.lat;

  return {
    _northEast: {
      lng: bounds._northEast.lng + xDistance * multiplier,
      lat: bounds._northEast.lat + yDistance * multiplier,
    },
    _southWest: {
      lng: bounds._southWest.lng - xDistance * multiplier,
      lat: bounds._southWest.lat - yDistance * multiplier,
    },
  };
}
// Convert from array od sites to feature group with individual shape in each feature.
// We need to get rid of GeometryCollection in the features, beacause leaflet
// can't show tooltip on point which belongs to GeometryCollection
// todo: tests
function mergeGeometriesToFeatureGroup(sites: ?(SiteType[])): ?GeoJsonType {
  if (!sites) return featureCollection([]);

  const geoJsonArray = [];

  for (let i = 0; i < sites.length; i += 1) {
    if (sites[i].geojson) {
      const { geometries } = sites[i].geojson;

      if (Array.isArray(geometries)) {
        for (let j = 0; j < geometries.length; j += 1) {
          const newFeature = feature(geometries[j]);

          newFeature.properties = {
            ...sites[i],
          };

          geoJsonArray.push(newFeature);
        }
      }
    }
  }

  return featureCollection(geoJsonArray);
}

export {
  makeGeojsonFilterFromBounds, areaInArea, makeBoundsBigger, mergeGeometriesToFeatureGroup,
};
