// @flow
import * as React from 'react';
import { Col, Button as ButtonAnt } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
// Components
import SiteNotes from './SiteNotes';
// Mutation
import REMOVE_NOTE from '../../lib/mutations/removeSiteNote';
// Own Types
import type { NotesType } from '../../lib/types';

type propsType = {
  client: any,
  form: {
    getFieldDecorator: Function,
    getFieldValue: Function,
    setFieldsValue: Function,
    validateFields: Function,
  },
  notes: [NotesType] | [],
  setLoading: (loading: boolean) => Promise<void>,
};

class SiteNotesContainer extends React.PureComponent<propsType, null> {
  handleAddNote = () => {
    const { form } = this.props;

    const notesForm = form.getFieldValue('notesForm');

    form.setFieldsValue({
      notesForm: [...notesForm, { content: '' }],
    });
  };

  handleRemoveNote = async (noteIndex: number): Promise<void> => {
    const { client, form, setLoading } = this.props;

    const notes = form.getFieldValue('notes');
    const noteToRemove = notes[noteIndex];

    if (noteToRemove.id) {
      setLoading(true);
      await client.mutate({
        mutation: REMOVE_NOTE,
        variables: {
          id: noteToRemove.id,
        },
      });
    }
    setLoading(false);

    const filteredNotes = notes.filter((item: NotesType): boolean => item !== noteToRemove);

    form.setFieldsValue({
      notes: filteredNotes,
      notesForm: filteredNotes,
    });
  };

  render(): React.Node {
    const { form, notes } = this.props;

    form.getFieldDecorator('notesForm', { initialValue: notes });
    const notesForm = form.getFieldValue('notesForm');

    return (
      <React.Fragment>
        {notesForm && <SiteNotes form={form} notes={notesForm} handleRemoveNote={this.handleRemoveNote} />}
        <Col lg={24}>
          <StyledButtonAnt onClick={this.handleAddNote}>
            <FormattedMessage id="SiteNotes.addNewNote" defaultMessage="Dodaj notatkę" />
          </StyledButtonAnt>
        </Col>
      </React.Fragment>
    );
  }
}

const StyledButtonAnt = styled(ButtonAnt)`
  width: 100%;
`;

export default (withApollo(SiteNotesContainer): any);
