// @flow
const translations = {
  homeTitle: {
    defaultMessage: 'Eplatforma archeologiczna',
    id: 'StaticPage.homeTitle',
  },
  expertsTitle: {
    defaultMessage: 'Dla ekspertów',
    id: 'StaticPage.expertsTitle',
  },
  findersTitle: {
    defaultMessage: 'Dla poszukiwaczy',
    id: 'StaticPage.findersTitle',
  },
  developersTitle: {
    defaultMessage: 'Dla deweloperów',
    id: 'StaticPage.developersTitle',
  },
  conservatorTitle: {
    defaultMessage: 'Dane konserwatora',
    id: 'StaticPage.conservatorTitle',
  },
  faqTitle: {
    defaultMessage: 'Jak korzystać',
    id: 'StaticPage.faqTitle',
  },
  privacyTitle: {
    defaultMessage: 'Polityka prywatności',
    id: 'StaticPage.privacyTitle',
  },
  rulesTitle: {
    defaultMessage: 'Regulamin',
    id: 'StaticPage.rulesTitle',
  },
  contactFormTitle: {
    defaultMessage: 'Kontakt',
    id: 'ContactForm.contact',
  },
  dictionariesListTitle: {
    defaultMessage: 'Słowniki',
    id: 'DictionariesList.dictionariesListTitle',
  },
  dictionaryTitle: {
    defaultMessage: 'Słownik',
    id: 'Dictionary.dictionaryTitle',
  },
  siteSListTitle: {
    defaultMessage: 'Lista stanowisk',
    id: 'SitesList.siteSListTitle',
  },
  sitesListAdministrationTitle: {
    defaultMessage: 'Widok administracji',
    id: 'SitesList.sitesListAdministrationTitle',
  },
  sitesListModeration: {
    defaultMessage: 'Widok moderacji',
    id: 'SitesList.sitesListModeration',
  },
  sitesListMyWork: {
    defaultMessage: 'Moja praca',
    id: 'SitesList.sitesListMyWork',
  },
  siteMapTitle: {
    defaultMessage: 'Mapa stanowisk',
    id: 'SitesMap.siteMapTitle',
  },
  sitesNewTitle: {
    defaultMessage: 'Dodaj stanowisko',
    id: 'ContactForm.sitesNewTitle',
  },
  editSitesTitle: {
    defaultMessage: 'Edycja stanowiska',
    id: 'SiteDetails.sitesNewTitle',
  },
  sitesDetailTitle: {
    defaultMessage: 'Szczegóły stanowiska',
    id: 'SiteDetails.SitesDetailTitle',
  },
};

export default {
  '/': {
    showBreadcrumbs: false,
    helmetPageTitle: translations.homeTitle,
  },
  '/about/experts': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.expertsTitle,
  },
  '/about/finders': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.findersTitle,
  },
  '/about/developers': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.developersTitle,
  },
  '/about/conservator': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.conservatorTitle,
  },
  '/about/faq': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.faqTitle,
  },
  '/about/contact': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.contactFormTitle,
  },
  '/about/privacy': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.privacyTitle,
  },
  '/about/rules': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.rulesTitle,
  },
  '/admin/dictionaries': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.dictionariesListTitle,
  },
  '/admin/dictionary': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.dictionaryTitle,
  },
  '/sites/list': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.siteSListTitle,
  },
  '/sites/list/administration': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.sitesListAdministrationTitle,
  },
  '/sites/list/moderation': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.sitesListModeration,
  },
  '/sites/list/my-work': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.sitesListMyWork,
  },
  '/sites/map': {
    showBreadcrumbs: false,
    helmetPageTitle: translations.siteMapTitle,
  },
  '/admin/sites/new': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.sitesNewTitle,
  },
  '/admin/sites': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.editSitesTitle,
  },
  '/sites': {
    showBreadcrumbs: true,
    helmetPageTitle: translations.sitesDetailTitle,
  },
};
