// @flow
import GET_SITES_LIST_COLUMNS_QUERY from '../queries/getSitesListColumns';
import { sitesListFloatingColumns, sitesListDefaultColumns } from '../constants/sitesListFloatingColumns';

const sitesListColumns = {
  defaults: {
    sitesListColumns: {
      selectedColumns: sitesListDefaultColumns,
      orderedColumns: sitesListFloatingColumns,
      columnSortOrder: 'localName_ASC',
      __typename: 'sitesListColumns',
    },
  },
  resolvers: {
    Mutation: {
      setSitesListColumns: (
        _: any,
        {
          columnSortOrder,
          orderedColumns,
          selectedColumns,
        }: { columnSortOrder: string, orderedColumns: string[], selectedColumns: string[] },
        { cache }: { cache: Object },
      ): null => {
        const readedSitesListColumnsData = cache.readQuery({
          query: GET_SITES_LIST_COLUMNS_QUERY,
        });

        cache.writeQuery({
          query: GET_SITES_LIST_COLUMNS_QUERY,
          data: {
            sitesListColumns: {
              selectedColumns: selectedColumns || readedSitesListColumnsData.sitesListColumns.selectedColumns,
              orderedColumns: orderedColumns || readedSitesListColumnsData.sitesListColumns.orderedColumns,
              columnSortOrder: columnSortOrder || readedSitesListColumnsData.sitesListColumns.columnSortOrder,
              __typename: 'sitesListColumns',
            },
          },
        });

        return null;
      },
    },
  },
};

export default sitesListColumns;
