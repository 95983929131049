// @flow
import * as React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { Alert } from 'antd';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import CURRENT_USER_MUTATION from '../../lib/mutations/CurrentUser';
import withRouter from '../../lib/utils/withRouter';

type Props = {
  client: any,
  // I don't know what is going on here
  // eslint-disable-next-line
  intl: any,
  // eslint-disable-next-line
  location: {
    pathname: string,
  },
};

type State = {
  code: ?number,
  description: ?string,
  message: ?string,
};

export class ErrorPage extends React.PureComponent<Props, State> {
  state: State = {
    code: null,
    message: null,
    description: null,
  };

  componentDidMount() {
    const { client } = this.props;
    const { code } = this.state;

    if (code === 401) {
      client.mutate({
        mutation: CURRENT_USER_MUTATION,
        variables: {
          token: null,
        },
      });
    }
  }

  static getDerivedStateFromProps(props: Props): State {
    const {
      intl,
      location: { pathname },
    } = props;

    const errorCode = pathname.split('/')[1];

    if (!Number.isInteger(+errorCode)) {
      return {
        code: null,
        message: intl.formatMessage(ErrorPage.translations.error404),
        description: intl.formatMessage(ErrorPage.translations.error404description),
      };
    }

    const message = intl.formatMessage(ErrorPage.translations[`error${errorCode}`]);
    const description = intl.formatMessage(ErrorPage.translations[`error${errorCode}description`]);

    return { message, description, code: +errorCode };
  }

  static translations: any = defineMessages({
    error401: {
      defaultMessage: '401 Wymagana autoryzacja',
      id: 'error.401unauthorized.text',
    },
    error401description: {
      defaultMessage:
        'Jesteś wylogowany lub twój token wygasł. Musisz się ponownie zalogować aby przeprowadzić wymaganą akcję.',
      id: 'error.401unauthorized.description',
    },
    error404: {
      defaultMessage: '404 Nie znaleziono',
      id: 'error.404notfound.text',
    },
    error404description: {
      defaultMessage:
        'Serwer nie odnalazł zasobu według podanego URL ani niczego co by wskazywało na'
        + ' istnienie takiego zasobu w przeszłości',
      id: 'error.404notfound.description',
    },
    error500: {
      defaultMessage: '500 Wewnętrzny błąd serwera',
      id: 'error.500internalServerError',
    },
    error500description: {
      defaultMessage: ' serwer napotkał niespodziewane trudności, które uniemożliwiły zrealizowanie żądania',
      id: 'error.500internalServerError.description',
    },
  });

  render(): React.Node {
    const { message, description } = this.state;

    return (
      <Wrapper>
        <StyledAlert message={message} description={description} type="error" showIcon />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const StyledAlert = styled(Alert)`
  max-width: 600px;
  .ant-alert-message {
    font-size: 24px !important;
  }
`;

export default (withApollo(injectIntl(withRouter(ErrorPage))): React.ComponentType<mixed>);
